import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import mmph from 'myanmar-phonenumber';
import SimpleReactValidator from "simple-react-validator";
// import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';

class AddBank extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = {
                show : "false",
                step: "list",
                message : "",
                form : {
                    user_id     : 1,
                    provider_id : 5,
                    bank_type    : 1,
                    holdername   : "",
                    card_number     : "",
                },
                permit : {
                    bank_types : [
                        { id : 1, shortcode : "AYA"},
                        { id : 2, shortcode : "CB"},
                        { id : 3, shortcode : "KBZ"},
                        { id : 4, shortcode : "K Pay"},
                        { id : 5, shortcode : "Wave Moneny"}
                    ],
                    customer_banks : [
                         { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                    ],
                }
            };
        this.submitForm         = this.submitForm.bind(this);
        this.userLogout         = this.userLogout.bind(this);
        this.redirectLink       = this.redirectLink.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: "This is a required field.",
                email: "Please enter a valid email.",
                regex: "Please enter a phone number in this format: 09123456789",
            },
        });
    }

    filterBankType(bank_type) {
        let bank_type_group = this.state.permit.bank_types.filter(function (el, id) {
              return el.id == bank_type;
            });

        // console.log(bank_type_group[0])

        return bank_type_group[0].shortcode;
        //console.log(customer_bank)
    }

    changeSubMenu(step) {
        // console.log("sdf");

        if(step == "show") {
            this.setState({ show : "true"});
        } else {
            this.setState({ show : "false"});
        }

        this.setState({ step : step});

        // this.fetchData();
        this.fetchUserData();


    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "form" });
            }else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        if(localStorage.getItem('username')) {
                this.setState({
                    form: {
                        username : localStorage.getItem('username')
                    }
                });
        }

        this.fetchUserData();

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/wallet-store/add-bank');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;

        this.setState({
            form
        });

        console.log(form)
    }

    userLogout() {
        //console.log(this.state.step);
        let data = window.confirm("Are you sure want to logout?");
        if(data){
            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");
            this.setState({ step: "login" , message : "Successfully Log Out" });
            //this.profileBlock()
            this.viewHandler();
        }
    }

    fetchUserData() {
          //localStorage.getItem(window.BPdata.time)
          const url = window.BPdata.apiUrlCMS+'/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
          //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

          ///api/customer?transfer_type=2

          axios({
            method: 'post',
            url: url,
            data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
            headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  "X-Trident-Token" : window.BPdata.token,
                  "BP-Agent"         : window.BPdata.rand_compound,
                  "BP-Time"         : localStorage.getItem(window.BPdata.time)
              },
          })
        .then(resp => {

            this.setState(prevState => ({
                form : {
                    ...prevState.form,
                    user_id : resp.data.data.id,
                    provider_id : 5,
                    bank_type : 1
                    // bank_type : 1
                },
                permit : {
                    ...prevState.permit,
                    customer_banks : resp.data.data.customer_banks,

                }
            }))


            localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    redirectLink(to) {
        this.props.history.push(to);
    }

    submitForm() {

        let self = this;

        let dataurl = window.BPdata.apiUrlCMS+"/player/bank-create";
         let data = {
                'user_id'     : this.state.form.user_id,
                'provider_id' : this.state.form.provider_id,
                'bank_type'   : this.state.form.bank_type,
                'holdername'  : this.state.form.holdername,
                'card_number' : this.state.form.card_number,
         }

        axios({
            method: 'post',
            url: dataurl,
            data: this.state.form,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        }).then(response => {

                if(response.data.status_code == "200") {


                    // Swal.fire({
                    //   icon: 'success',
                    //   title: 'Success...',
                    //   html: 'Successfully submited'
                    // })

                    this.setState(prevState => ({
                        form : {
                            ...prevState.form,
                            bank_type : 1,
                            holdername : "",
                            card_number : "",
                            provider_id : 5
                        }
                    }))

                    // this.state.form.bank_type = 1;
                    // this.state.form.holdername = "";
                    // this.state.form.card_number = "";

                     swal("Successfully submited");

                     // self.props.history.push("/more");
                }


                if(response.data.status_code == "404") {

                    //this.isloading = "false";
                    // $(".display-none").css('display','flex');

                    //console.log(response.data.message);

                    let message = "";
                    if(response.data.message.length > 0) {
                        for (var i = 0; i < response.data.message.length; i++) {
                            message += response.data.message[i] + " | ";
                        }
                    }

                     swal(message);

                    // Swal.fire({
                    //   icon: 'error',
                    //   title: 'Oops...',
                    //   html: message
                    // })

                }

            })
            .catch(e => {
               //console.log(e)
              // this.errors.push(e)
            })

    }



    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "form": return this.profileBlock();
                case "list": return this.listBlock();
                default:        return "";
              }
        }

    }

    showDetail(id,bank_type,bank_acc_name,bank_num) {

        this.setState(prevState => ({
                show : "true",
                step : "form",
                form : {
                    ...prevState.form,
                    id              : id,
                    bank_type       : bank_type,
                    holdername      : bank_acc_name,
                    card_number     : bank_num,
                }
            }))


    }

    listBlock() {
        return <table class="table" >
                <thead>

                <tr>
                  <th scope="col">Bank Type</th>
                  <th scope="col">Bank Name / Phone Number</th>
                  <th scope="col">Bank Number</th>
                  <th scope="col">Action</th>
                </tr>
                { this.state.permit.customer_banks.map(({ id, bank_type, bank_acc_name, bank_num }, index) =>
                    <tr>
                      <td scope="col">{this.filterBankType(bank_type)}</td>
                      <td scope="col">{bank_acc_name}</td>
                      <td scope="col">{bank_num}</td>
                      <td scope="col">{<span onClick={() => this.showDetail(id,bank_type,bank_acc_name,bank_num)} className="btn btn-success">Show</span>}</td>
                    </tr>
                )}

              </thead>
              <tbody>

              </tbody>
        </table>
    }

    profileBlock() {
      return <div className="form-box">

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                         <label class="control-label">Bank</label>
                        <select class="form-control" name="bank_type" value={this.state.form.bank_type} onChange={(e) => this.inputChangeHandler.call(this, e)}>
                            {this.state.permit.bank_types.map(({ id, shortcode }, index) => <option value={id} >{shortcode}</option>)}
                        </select>
                    </div>
                </div>
                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.name}</label>
                        {this.validator.message("name", this.state.form.holdername, "required")}
                        <br />
                        <input required="required" type="text" name="holdername" class="form-control" value={this.state.form.holdername} onChange={(e) => this.inputChangeHandler.call(this, e)} />

                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">Account Number / Phone Number{window.lang.form.bank_num}</label>
                        {this.validator.message("name", this.state.form.card_number, "required")}
                        <br />
                        <input required="required" type="text" name="card_number"  class="form-control" value={this.state.form.card_number} onChange={(e) => this.inputChangeHandler.call(this, e)} />

                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left" >
                        { (this.state.show == "false") ?
                        <input type="submit" className="btn btn-success grid-100" value={window.lang.form.submit} onClick={this.submitForm} /> : "" }

                        <br />


                    </div>
                </div>



        </div>
   }


    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                    <br />
                    <br />
                    <div className="row">
                        <div className="grid-100"><h4 className="color-red">Transfer Bank Info</h4></div>
                        <div className="grid-60 left">

                        </div>

                        <div className="grid-40 right text-right">

                            <span className="text-primary btn btn-success" onClick={() => this.changeSubMenu('list')}>Bank List</span>
                            {/*<Link to="/more/wallet-store/bank-list" className="text-primary btn btn-success">Bank List</Link>*/}

                        </div>
                    </div>
                    <br />
                    <span>{this.state.message}</span>

                    { this.viewHandler() }
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBank;
