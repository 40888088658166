import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
// import { FacebookProvider, Like } from 'react-facebook';

// import logo from './logo.svg';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';
import 'react-day-picker/lib/style.css';

import './kitchen-sink.css';
import './App.css';
import './Reset.css';
import './Ant.css';
import './Color.css';
import './Component.css';


import { Grid } from 'antd-mobile';

import Chat from './components/component/Chat/Chat';
import HomePage from './components/pages/news/HomePage';
import ListPage from './components/pages/ListPage';
import FeaturePage from './components/pages/FeaturePage';
import ListDetailPage from './components/pages/news/ListDetailPage';
import LeaguePage from './components/pages/news/LeaguePage';
import HighlightsLeaguePage from './components/pages/Highlights/HighlightsLeaguePage';
import TwoGamePage from './components/pages/TwoGamePage';
import AboutPage from './components/pages/AboutPage';
import ContactUsPage from './components/pages/ContactUsPage';
import LiveScorePage from './components/pages/live/LiveScorePage';
import LiveScoreMatchDetailPage from './components/pages/live/LiveScoreMatchDetailPage';
import Channel1AppPage from './components/pages/live/Channel1AppPage';
import Channel1AppDetailPage from './components/pages/live/Channel1AppDetailPage';
import Channel2AppPage from './components/pages/live/Channel2AppPage';
import Channel2AppDetailPage from './components/pages/live/Channel2AppDetailPage';
import Channel3AppPage from './components/pages/live/Channel3AppPage';
import Channel3AppDetailPage from './components/pages/live/Channel3AppDetailPage';
import ChannelsSchedulePage from './components/pages/ChannelsSchedulePage';

import FAQPage from './components/pages/more/FAQPage';
import PromotionPage from './components/pages/more/PromotionPage';
import SharePage from './components/pages/more/SharePage';

import HighlightsPage from './components/pages/Highlights/HighlightsPage';
import HighlightDetailPage from './components/pages/Highlights/HighlightsDetailPage';
import HighlightsListPage from './components/pages/Highlights/HighlightsListPage';
import TipOddPage from './components/pages/TipOddPage';
import TipOddLeaguePage from './components/pages/TipOddLeaguePage';
import BillContestPage from './components/pages/BillContestPage';
import LeagueContestPage from './components/pages/league/LeagueContestPage';
import LeagueTablePage from './components/pages/league/LeagueTablePage';
// import SLeagueContestPage from './components/pages/sleague/SLeagueContestPage';
// import SLeagueTablePage from './components/pages/sleague/SLeagueTablePage';

import MorePage from './components/pages/MorePage';
import BetListPage from './components/pages/more/BetListPage';
import BetListDetailPage from './components/pages/more/BetListDetailPage';
import BetResultListPage from './components/pages/more/BetResultListPage';
import ContestPage from './components/pages/ContestPage';
import WalletWithdrawalStore from './components/pages/walletstore/WalletWithdrawalStore';
import WalletTopUpStore from './components/pages/walletstore/WalletTopUpStore';
import TransactionStatus from './components/pages/walletstore/TransactionStatus';

import AddBank from './components/pages/walletstore/AddBank';
import WalletStore from './components/pages/walletstore/WalletStore';



import MyEditAccPage from './components/pages/more/MyEditAccPage';
import MyAccPage from './components/pages/more/MyAccPage';
import AccPage from './components/pages/AccPage';

import InboxPage from './components/pages/more/InboxPage';
import TransactionPage from './components/pages/more/TransactionPage';
import OrderPage from './components/pages/more/OrderPage';
import LoginPage from './components/pages/LoginPage';

import LoginDgPage from './components/pages/LoginDgPage';
import LoginMdyPage from './components/pages/LoginMdyPage';
import LoginYgnPage from './components/pages/LoginYgnPage';
import LoginMyanPage from './components/pages/LoginMyanPage';

import WWBETLoginPage from './components/pages/WWBETLoginPage';
import AELoginPage from './components/pages/AELoginPage';
import HabaLoginHomePage from './components/pages/HabaLoginHomePage';
import HabaLoginPage from './components/pages/HabaLoginPage';
import JokerLoginPage from './components/pages/JokerLoginPage';
import SBOLoginPage from './components/pages/SBOLoginPage';


import SignUpPage from './components/pages/SignUpPage';
import FeedbackPage from './components/pages/FeedbackPage';
import PrizePoolPage from './components/pages/PrizePoolPage';
import TipstersPage from './components/pages/TipstersPage';
import TipstersListPage from './components/pages/TipstersListPage';
import TipstersListDetailPage from './components/pages/TipstersListDetailPage';

import ChangePasswordPage from './components/pages/more/ChangePasswordPage';
import PasswordRestPage from './components/pages/PasswordRestPage';
import AnnouncementsPage from './components/pages/more/AnnouncementsPage';
import AnnouncementsDetailPage from './components/pages/more/AnnouncementsDetailPage';


import HighlightsVideoSlider from './components/pages/HighlightsVideoSlider';

import BillHistoryPage from './components/pages/more/BillHistoryPage';
import LeagueHistoryPage from './components/pages/league/LeagueHistoryPage';
// import SLeagueHistoryPage from './components/pages/sleague/SLeagueHistoryPage';
import TermAndCondition from './components/pages/TermAndConditionPage';
import AccBillHistoryPage from './components/pages/AccBillHistoryPage';
import AccLeagueHistoryPage from './components/pages/AccLeagueHistoryPage';

import GemStorePage from './components/pages/GemStorePage';
import PackageStorePage from './components/pages/PackageStorePage';

import UserPredictPackage from './components/pages/UserPredictPackage';


import SkillvideoPage from './components/pages/SkillvideoPage';
import SkillvideoDetailPage from './components/pages/SkillvideoDetailPage';

import ParticipantPage from './components/pages/ParticipantPage';

import ForumPage from './components/pages/forum/ForumPage';
import ForumDetailPage from './components/pages/forum/ForumDetailPage';
import ForumCreatePage from './components/pages/forum/ForumCreatePage';
import ImagePreviewPage from './components/pages/ImagePreviewPage';

import IOSDownloadPage from './components/pages/IOSDownloadPage';
import AndroidDownloadPage from './components/pages/AndroidDownloadPage';
import DownloadPage from './components/pages/DownloadPage';

import StatementDetailPage from './components/pages/news/StatementDetailPage';
import StatementPage from './components/pages/news/StatementPage';
import ChoosePage from './components/pages/news/ChoosePage';
import JokerPage from './components/pages/news/JokerPage';
import SexyPage from './components/pages/news/SexyPage';
import HabaPage from './components/pages/news/HabaPage';
import SBOPage from './components/pages/news/SBOPage';
import WssPage from './components/pages/news/WssPage';

import Toolbar from "./components/Toolbar";
import SideDrawer from "./components/sidemenu/SideDrawer";
import BackDrop from "./components/backdrop/BackDrop";
// import CommentPage from './components/pages/CommentPage';


require('./BPdata.js')
// const data1 = Array.from(new Array(4)).map(() => ({
//   icon: 'https://gw.alipayobjects.com/zos/rmsportal/WXoqXTHrSnRcUwEaQgXJ.png',
// }));



// data1 = data1.reverse();
let url = '/';

let checklogin = () => {
    if(localStorage.getItem(window.BPdata.time)) {
        if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            return true;
        }
    }
    return false;
}

class App extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      url: '/',
      sideDrawerOpen: false
    }

    //this.goBack = this.goBack.bind(this);
  }

  checklogin() {
    if(localStorage.getItem(window.BPdata.time)) {
        if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            return true;
        }
    }
    return false;
  }

  getCount() {
    const clicked = this.state.clicked
    if(clicked){
      this.setState({count: this.state.count - 1, clicked: false});
    } else {
      this.setState({count: this.state.count + 1, clicked: true})
    }

  }

  getfooter() {
    let ui = [];
    ui.push(<Grid data={window.lang.footer}
        columnNum={3}
        renderItem={dataItem => (


          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                { this.getfooterList(dataItem) }
          </Link>

        )}
      />)
      return ui;

  }

  getfooterList(dataItem) {

      let ui = [];



      if(window.BPdata.footerMenu === dataItem.check ) {

        if(dataItem.text == window.lang.form.footer_login_check)  {
          ui = [];
          ui.push(<span className="footer-active-text">
            <img src={dataItem.icon_off}  height="30px"/><br />{(this.checklogin()) ? dataItem.text : window.lang.form.footer_login_check_lang }
          </span>);
        } else {
          ui = [];
          ui.push(<span className="text-white">
            <img src={dataItem.icon_off}  height="30px"/><br /><span className="footer-active-text">{dataItem.text}</span>
          </span>);
        }

      } else {

        if(dataItem.text == window.lang.form.footer_login_check) {
            ui = [];
            ui.push(<span className="color-dark-gold footer-text ">
                <img src={dataItem.icon}  height="30px"/><br />
                {(this.checklogin()) ? dataItem.text : window.lang.form.footer_login_check_lang } </span>
              )

        } else {
            ui = [];
            ui.push(<span className="text-white">
               <img src={dataItem.icon}  height="30px"/><br /><span className="color-dark-gold footer-text">{dataItem.text}</span>
             </span>)

        }



      }

      return ui;




  }

  getfooter1() {
    let ui = [];
    ui.push(<Grid data={window.lang.footer}
        columnNum={5}
        renderItem={dataItem => (


          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                { (window.BPdata.footerMenu === dataItem.check )  ?
                  (dataItem.text == window.lang.form.footer_login_check) ?
                    <span className="footer-active-text">
                      <img src={dataItem.icon_off}  height="30px"/><br />{(this.checklogin()) ? dataItem.text : window.lang.form.footer_login_check_lang }
                    </span>
                  :
                  <span className="text-white">
                    <img src={dataItem.icon_off}  height="30px"/><br /><span className="footer-active-text">{dataItem.text} 3</span>
                  </span>
                :
                  (dataItem.text == window.lang.form.footer_login_check) ?
                    <span className="color-dark-gold footer-text ">
                        <img src={dataItem.icon}  height="30px"/><br />2{(this.checklogin()) ? dataItem.text : window.lang.form.footer_login_check_lang } </span>
                     : <span className="text-white">
                        <img src={dataItem.icon}  height="30px"/><br /><span className="color-dark-gold footer-text">{dataItem.text}1</span>
                      </span>
                    }

          </Link>

        )}
      />)
      return ui;

  }
  UNSAFE_componentWillMount() {
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','mm');
    } else {
      if(localStorage.getItem('lang') === 'mm') {
        window.lang = window.mmlang;
      } else {
        window.lang = window.enlang;
      }
    }
  }
  componentDidMount() {

    // this.window.BPdata.route =JSON.stringify( "/" );
      //console.log(localStorage.getItem('route'))

  }

  UNSAFE_componentWillUpdate() {
    //this.goBack();


    // } else {
    //   this.setState({ url: "/" });
    // }
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {

    if (JSON.parse(localStorage.getItem("route")) !== null)  {
      let routes = JSON.parse(localStorage.getItem("route"));
      //console.log(routes.length)
        if(routes.length>1) {
          // if(window.BPdata.preurl ===  routes[routes.length-1]) {
          //   url = routes[routes.length-1] ;
          // } else {
            url = routes[routes.length-1] ;
          //}

        } else {
          url = "/";
        }

     }

    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;
    }

    return (
      <div className="App" >
        <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}

        <div className="App-intro">
          <div id="fb-root"></div>
          <Switch>
 
            <Route path="/more/statement/:id" component={StatementDetailPage} />
            <Route path="/more/statement" component={StatementPage} />
            <Route path="/more/choosestatement" component={ChoosePage} />
            <Route path="/more/joker" component={JokerPage} />
            <Route path="/more/wss" component={WssPage} />
            <Route path="/more/sbo" component={SBOPage} />
            <Route path="/more/haba" component={HabaPage} />
            <Route path="/more/sexy" component={SexyPage} />

            <Route path="/faq" component={FAQPage} />
            <Route path="/promotion" component={PromotionPage} />
            <Route path="/share-app" component={SharePage} />
            <Route path="/chat" component={Chat} />

            <Route path="/feature" component={FeaturePage} />
            <Route path="/latest" component={ListPage} />


            // <Route path="/two-game" component={TwoGamePage} />

            <Route path="/wwbet-login" component={WWBETLoginPage} />
            <Route path="/ae-login" component={AELoginPage} />

            <Route path="/haba-login/:id" component={HabaLoginPage} />
            <Route path="/haba-login" component={HabaLoginHomePage} />

            <Route path="/joker-login" component={JokerLoginPage} />
            <Route path="/sbo-login" component={SBOLoginPage} />



            <Route path="/about" component={AboutPage} />
            <Route path="/contact-us" component={ContactUsPage} />

            <Route path="/live-score" component={LiveScorePage} />
            <Route exact path="/live/match-detail/:id" component={LiveScoreMatchDetailPage} />

            <Route path="/channel1/detail" component={Channel1AppDetailPage} />
            <Route path="/channel1" component={Channel1AppPage} />

            <Route path="/channel2/detail" component={Channel2AppDetailPage} />
            <Route path="/channel2" component={Channel2AppPage} />

            <Route path="/channel3/detail" component={Channel3AppDetailPage} />
            <Route path="/channel3" component={Channel3AppPage} />


            <Route path="/channelsschedule" component={ChannelsSchedulePage} />

            <Route path="/news/league/:id/:name" component={LeaguePage} />
            <Route exact path="/news/:id" component={ListDetailPage} />
            <Route path="/tip-and-odd/league/:name" component={TipOddLeaguePage} />
            <Route path="/tip-and-odd" component={TipOddPage} />

            {/* <Route path="/comment" component={CommentPage} /> */}

            <Route path="/highlightsvideo" component={HighlightsVideoSlider} />
            <Route path="/highlight/latest" component={HighlightsListPage} />
            <Route path="/highlight/league/:id/:name" component={HighlightsLeaguePage} />
            <Route path="/highlight/:id/:name" component={HighlightDetailPage} />
            <Route path="/highlights" component={HighlightsPage} />

            <Route path="/image/:id" component={ImagePreviewPage} />

            <Route path="/forum/create" component={ForumCreatePage} />
            <Route path="/forum/:id" component={ForumDetailPage} />
            <Route path="/forum" component={ForumPage} />

            <Route path="/skillvideo/:id/:name" component={SkillvideoDetailPage} />
            <Route path="/skillvideo" component={SkillvideoPage} />

            <Route path="/participant/predict/order/:id" component={UserPredictPackage} />



            <Route path="/more/wallet-store/withdrawal" component={WalletWithdrawalStore} />
            <Route path="/more/wallet-store/top-up" component={WalletTopUpStore} />
            <Route path="/more/wallet-store/transaction-status" component={TransactionStatus} />

            <Route path="/more/wallet-store/add-bank" component={AddBank} />

            <Route path="/more/wallet-store" component={WalletStore} />


            <Route path="/more/announcements" component={AnnouncementsPage} />
            <Route path="/more/announcement/:id" component={AnnouncementsDetailPage} />

            <Route path="/more/myaccount/edit-user" component={MyEditAccPage} />
            <Route path="/more/myaccount" component={MyAccPage} />

            <Route path="/more/account/:id/bill-history" component={AccBillHistoryPage} />
            <Route path="/more/account/:id/league-history" component={AccLeagueHistoryPage} />
            <Route path="/more/account/:id" component={AccPage} />
            <Route path="/more/transaction" component={TransactionPage} />
            <Route path="/more/order" component={OrderPage} />
            <Route path="/more/inbox" component={InboxPage} />

            <Route path="/more/bill-contest" component={BillContestPage} />
            <Route path="/more/league-contest" component={LeagueContestPage} />
            {/* <Route path="/more/sleague-contest" component={SLeagueContestPage} /> */}

            <Route path="/more/league-table" component={LeagueTablePage} />
            {/* <Route path="/more/sleague-table" component={SLeagueTablePage} /> */}
            <Route path="/more/prize-pool" component={PrizePoolPage} />
            <Route path="/more/tipsters/:id/detail/:id2" component={TipstersListDetailPage} />
            <Route path="/more/tipsters/:id" component={TipstersListPage} />
            <Route path="/more/tipsters" component={TipstersPage} />


            <Route path="/more/gems/store" component={GemStorePage} />
            <Route path="/more/package/store" component={PackageStorePage} />


            <Route path="/more/bill-history" component={BillHistoryPage} />
            <Route path="/more/league-history" component={LeagueHistoryPage} />
            {/* <Route path="/more/sleague-history" component={SLeagueHistoryPage} /> */}


            <Route path="/more/login" component={LoginPage} />

            <Route path="/more/dg-login" component={LoginDgPage} />
            <Route path="/more/mdy-login" component={LoginMdyPage} />
            <Route path="/more/ygnbet-login" component={LoginYgnPage} />
            <Route path="/more/myanbet-login" component={LoginMyanPage} />


            <Route path="/more/feedback" component={FeedbackPage} />
            <Route path="/more/sign-up" component={SignUpPage} />

            <Route path="/more/changepassword" component={ChangePasswordPage} />
            <Route path="/more/password-reset" component={PasswordRestPage} />
            <Route path="/more/term-condition" component={TermAndCondition} />

            <Route path="/more/bet-list/:id" component={BetListDetailPage} />
            <Route path="/more/bet-list" component={BetListPage} />
            <Route path="/more/betresultlist" component={BetResultListPage} />
            <Route path="/more" component={MorePage} />


            <Route path="/contest" component={ContestPage} />
            <Route path="/participant" component={ParticipantPage} />





            {/* <Route path="/euro/euro-standing" component={EuroStandingPage} />
            <Route path="/euro/euro-team" component={EuroTeamPage} />

            <Route  path="/news"  component={EuroChampionPage} /> */}

            <Route path="/download/ios" component={IOSDownloadPage} />
            <Route path="/download/android" component={AndroidDownloadPage} />
            <Route path="/download" component={DownloadPage} />



            <Route exact path="/" component={HomePage} />




            {/* <Route path="/euro/team/:id" component={EuroTeamDetailPage} /> */}


            <Redirect to="/" />
          </Switch>

        </div>


        <div className="top-ellipse"></div>
        <div className="bottom-ellipse"></div>
        <div className="middle-ellipse"></div>

        <footer  className="App-footer" style={{background:"rgb(217 217 217 / 20%)"}}>

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}

            {this.getfooter()}
          </div>
        </footer>



      </div>
    );


  }

}

export default App;
