import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import { Modal }  from 'antd-mobile';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

var moment = require('moment');
require('./../../../BPdata.js')
const alert = Modal.alert;

class LeagueContestPage extends React.Component {
    constructor(props) {
        super(props)
        
        window.BPdata.footerMenu = "CONTEST";
        this.state = { 
            selectedDays: [new Date()],
            status: 1,
            step: "login", 
            
            phone : "",
            league: [],
            advertise: {},
            contests : 
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            isLoading       : true,
            message         : "", 
            alertId         : ''
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cancelBillContent = this.cancelBillContent.bind(this);
        this.submitBillContent = this.submitBillContent.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);

    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
    }

    fetchData(url,data) {
        
        //let phone = localStorage.getItem(window.BPdata.time)  

        let dataurl = url;
        
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {

            this.setState({
                contests    : resp.data.contests,
                league      : resp.data.league,
                status      : resp.data.status,
                advertise   : resp.data.advertise,
                isLoading   : false
            }); 
            //console.log(this.state);
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   


        let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        let phone = localStorage.getItem(window.BPdata.time) ;
        
        window.BPdata.liveurl =  setTimeout(() => this.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore'), 600000);
        //console.log(this.state.contests);
 
    }

    sendBillContestApi() {
        
        var self = this;
        let message = "";
        if(localStorage.getItem(window.BPdata.time) !== 0) {
            this.setState({
                phone:  localStorage.getItem(window.BPdata.time)  
            })
            let dataurl = window.BPdata.apiUrl+'/user/leaguecontest';
            let contests = this.state;
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(function (response) {
                if(response.data.success) {
                    message = "Successfully Submited";
                    self.setState({  
                        message : "Successfully Submited", 
                        status  : 1
                    });

                    //if(response.data.message){
                        
                   // }

                    let stateday = moment(self.state.selectedDays[0]).format("YYYY-MM-DD");
                    let phone = localStorage.getItem(window.BPdata.time) ;
                    self.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
                     
                    //self.setState({  message : "Successfully updated" });
                } else {
                    message = "Something was wrong";
                    self.setState({  
                        message : "Something was wrong",
                     });
                }
                self.showSuccessAlert(message)
                //this.showSuccessAlert(this.state.message )
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }
        
    }
    
    handleInputChange(event) {
        const target = event.target;
        //let value = target.type === 'radio' ? target.checked : target.value;
        let value = target.type === 'radio' ? target.value : target.value;
        // let rev_value;
        if(parseInt(value)) {
            value     = 1;
          //  rev_value = 0;
        } else {
            value     = 0;
          //  rev_value = 1;
        }
        const name = target.name;
        let str = this.state.league;
        
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            
            //console.log(word)

            let first   = word[0];
            let league  = word[1];
            let id      = word[2];
            // console.log(first)
            // console.log(id)
            if(first === "results") {
                str[league].data[id].results = value;
                //str[id].per_result2 = rev_value;
            }


            if(first === "over_under") {
                str[league].data[id].over_under = value;
                //str[id].over_under = value;
                //str[id].under = rev_value;
            }

            
        }

        this.setState({league:str,phone:  localStorage.getItem(window.BPdata.time) });
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        //console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.league
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) { 
                let each = data[i].group 
                //console.log(each)
                let childdata = data[i].data
                if(childdata) {
                    if(childdata.length > 0) {
                        ui.push(<div className="league-box" key={i}>
                                <div className="league-list-header">
                                    <img src={"/img/league/"+each.icon} className="list-img-circle" alt="Trending" />
                                        <span>{each.name}</span>
                                </div>
                                {this.childui(i,childdata)}
                        </div>)
                    } 
                }
                
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>
               ယှဉ်ပြိုင်ရန် ပြိုင်ပွဲမရှိသေးပါ။</h2></div>)
           }
            return ui
        }
    }


    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
            let status = 3;
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        let results = each.results
                        //console.log(per_result2)
                        let over_under = each.over_under
                        
                        //check handicap and change setting
                        
                        if(each.handicap === "") {
                            status = 1;
                        }
                        if(each.over_unders === "") {
                            status = 1;
                        }
                        if(each.h_a !== "") {
                            ui.push(
                                <div className="row" key={i}><table className="table  table-border" >
                                    <tbody>
                                        <tr>
                                            <td colSpan="7" className="text-center">
                                            
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colSpan="5" className="text-center">
                                                <b>{moment(each.event_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}</b>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colSpan="2" className="text-right grid-40">
                                                <b>Handicap</b>
                                            </td>
                                            <td className="text-center">|</td>
                                            <td colSpan="2" className="text-left  grid-40">
                                                <b>Over/Under</b>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colSpan="2" className="text-right">
                                                { (each.h_a === "H")? "(H) " : "" }
                                                <span className="color-blue">{each.handicap}</span>
                                            </td>
                                            <td className="text-center">|</td>
                                            <td colSpan="2" className="text-left">
                                                <span className="color-red">{each.over_unders} </span>
                                                { (each.h_a === "A")? " (A)" : "" }
                                            
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                            { ( parseInt(results) === 1) ?
                                                <input type="radio" name={`results-${id}-${i}`} value="1" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                                :  <input type="radio" name={`results-${id}-${i}`} value="1" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>}
                                            </td>
                                            <td colSpan="2" className="text-right">
                                                {each.team1}
                                            </td>
                                            <td className="text-center"> vs </td>
                                            <td colSpan="2" className="text-left">
                                                {each.team2}
                                            </td>
                                            <td className="text-center">
                                                { (parseInt(results) === 0) ?
                                                <input type="radio" name={`results-${id}-${i}`} value="0" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/> 
                                                : <input type="radio" name={`results-${id}-${i}`} value="0" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                { ( parseInt(over_under) === 1) ?
                                                    <input type="radio" name={`over_under-${id}-${i}`} value="1" checked="checked"  onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>  
                                                :  
                                                    <input type="radio" name={`over_under-${id}-${i}`} value="1"  onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                                }
                                                
                                            </td>
                                            <td colSpan="2" className="text-right">
                                                Over
                                            </td>
                                            <td className="text-center"> | </td>
                                            <td colSpan="2" className="text-left">
                                                Under
                                            </td>
                                            <td className="text-center">
                                                { (parseInt(over_under) === 0) ?
                                                    <input type="radio" name={`over_under-${id}-${i}`} value="0" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                                :
                                                    <input type="radio" name={`over_under-${id}-${i}`} value="0" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="7" className="text-center">
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="text-center"><button className="btn btn-success" onClick={() =>
                                        alert('Submit', window.lang.messages.que_submit, [
                                        { text: window.lang.form.cancel, onPress: () => console.log('cancel') },
                                        { text: window.lang.form.submit, onPress: () => this.sendBillContestApi()  },
                                        ])
                                    }>{window.lang.form.submit}</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>{window.lang.form.cancel}</button></div>
                                </div>
                                </div>) 
                        } else {
                            ui.push(<div className="text-center" key="0"><h2>Pending Tip/Odd</h2></div>)
                            return ui
                        }
                        
                        
                }

                if(ui.length === 0) {
                    //this.setState({status : 1});
                        ui.push(<div className="text-center" key="0"><h2>ယနေ့ပြိုင်ပွဲမရှိသေးပါ။</h2></div>)
                }
                    return ui
                }
        }

        // console.log(status+"-------")
        // if(status === 1) {
        //     console.log(status+"-------")
        //     this.setState({status : 0});
        // }
        
        return ui;
    }
    
    cancelBillContent() {
        let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
    }

    submitBillContent() {
        //console.log(this.state.data)
        // let data = window.confirm("Are you sure want to submit this data ?");
        // if(data){
            this.sendBillContestApi();
        //}
        
    }
    //childui

    datePickerPre() {
        // moment(this.state.selectedDays[0], moment.ISO_8601).add(1, 'day');
         //moment(this.state.selectedDays[0]).format("YYYY-MM-DD")
         let day = moment(this.state.selectedDays[0]).subtract(1, 'day')._d;
         this.setState({
             selectedDays : [day]
         })
 
         let stateday = moment(day).format("YYYY-MM-DD");
         //this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
         let phone = localStorage.getItem(window.BPdata.time) ;
         this.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
     }

     datePickerNex() {
        let day = moment(this.state.selectedDays[0]).add(1, 'day')._d;
        this.setState({
            selectedDays : [day]
        })

        let stateday = moment(day).format("YYYY-MM-DD");
        let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
        //console.log(this.state.selectedDays[0])
    }


    datePicker(hideResults) {
        let block = []
        //console.log(hideResults)
        if(this.state.hidediv === true) {
            this.setState({hidediv : false })
        } else {
            this.setState({hidediv : true })
        }
        
        return block
    }


    componentDidMount() {
        clearTimeout(window.BPdata.liveurl)

        this.showSuccessAlert("sdf asf" )

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
              this.setState({ step: "profile" }); 
            } else {
               this.props.history.push("/more/sign-up");
              }
          } else {
              this.props.history.push("/more/sign-up");
          }

        // if(localStorage.getItem(window.BPdata.time)) {
        //     if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //       this.setState({ 
        //           step: "profile"
        //         }); 
        //     }
        // } 
        
        this.hisUrl('/more/league-contest');
        let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
        //this.fetchData();
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))

        
        

    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        <div className="row text-left">
                            
                            <br/>
                            <div className="list-header">
                                <img src="/img/badge.png" className="list-img-circle" alt={window.lang.title.league_contest}     /><span>{window.lang.title.league_contest}    </span>
                            </div>
                            
                            <br />
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/league-contest" className="btn-success btn color-gold" >
                                        {window.lang.title.contest}    
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/league-table" className="btn-success btn" >
                                        {window.lang.title.leaderboard}  
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/league-history" className="btn-success btn" >
                                        {window.lang.title.history}  
                                    </Link> 
                                </li>
                            </ul>
                        </div>
                        <div className="row text-left">
                            {/* <span>{this.state.message}</span> */}
                            
                            {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                            {
                                (this.state.advertise) ? <div className="row">
                                <div className="grid">
                                    <br/>
                                    <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                                        <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                                    </a>
                                    <br/><br/>
                                </div>
                                </div>: ""
                            }
                            
                        </div>
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                                {(this.state.status===3)?<div className="text-center"><button className="btn btn-success" onClick={this.submitBillContent}>Submit</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>Cancel</button></div> :"" }
                        </div> */}
                    </div>
                </div>
               
            </div>
        );
    }
}

export default LeagueContestPage;