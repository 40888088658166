/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';
import "../../sidemenu/SideDrawer.css";
import {Grid } from 'antd-mobile';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class ChoosePage extends React.Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "home";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      betlist : 'betlist',
      bet_list    : [],
      result_list : [],
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;
      
    }

    this.changeSubMenu      = this.changeSubMenu.bind(this);

  }

  componentDidMount() {

    // if(localStorage.getItem('home_advertise')) {
        
    //   if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
    //     let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
    //     this.setState({advertise: home_advertise})
    //   }
    // } 

    // if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //   let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(video_slides.reverse()),
    //     isLoading: true,
    //   });
    // }

    // if(localStorage.getItem('homeData')) {
    //   if(JSON.parse(localStorage.getItem("homeData")) != null) {
    //     let home_data = JSON.parse(localStorage.getItem("homeData"));
    //     this.setState({homeData: home_data})
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(home_data.dataSource),
    //       isLoading: false,
    //       advertise: home_data.advertise
    //     });
    //   }
    // } 
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }


  changeSubMenu(step) {
      // if(step === 'betlist') {
        this.setState({ betlist : step});

        if(step === 'betlist') {
          this.data = this.bet_list;
        } else {
          this.data =   this.result_list;
        }

        this.fetchData();
          // if(this.state.betlist) {
          //     this.setState({ betlist : step});
          // } else {
          //     this.setState({ betlist : step});
          // }
      // }
      
  }

  fetchData() {
        
        let dataurl = window.BPdata.apiUrl+'/v1/cms/cus-statement?limit='+NUM_ROWS+'&game_type=1';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));

          console.log(resp.data.result_list)

          this.bet_list = resp.data.result_list.data;
          // this.result_list = resp.data.result_list;



          // if(this.state.betlist == "betlist") {

          this.data = resp.data.result_list.data;

          // } else {

          //   this.data = resp.data.result_list;

          // }
          
          
          // console.log("-----------------");
            
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            // localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )
            // localStorage.setItem("homeData", JSON.stringify({
            //   dataSource: this.data.reverse(),
            //   isLoading: false,
            //   advertise: resp.data.advertise
            // }) )

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
 
    return(
      <div className="row">
        
        <div className="grid-80 center " style={{ margin:"50px 50px 50px auto"}}>
        <div className={ (this.state.betlist == "betlist") ? 'grid-80 left text-center tab-holder active' : 'grid-80 left text-center tab-holder'}  onClick={this.changeSubMenu.bind(this,"betlist")}  >
                    Choose Statement
                   
                  </div>
        {/* <div className="grid-80 ">
         
        </div> */}
      <div >
      <div className="grid-30 left" style={{ marginTop:"" }}>
        </div>

        <div className="grid-30 left" style={{ marginTop:"20px" }}>
          <Link to="/more/sexy">
            <img src="http://shwebet.trident-crm.com/uploads/slidermk59b49696550c4ff0eea03b41c3dfe5bd.png" alt="" />  
          </Link> 
          
        </div>
       
        <div className="grid-30 left haba"  style={{ marginTop:"20px" }}>
          <Link to="/more/haba">
            <img src="http://shwebet.trident-crm.com/uploads/slidermkbcc6ff35f280ceef6511cadcfb0f8cae.png" alt="" />
          </Link>
        </div>
        <div className="grid-30 left">
        </div>
        <div className="grid-30 left wss " style={{ marginTop:"20px" }}>
          <Link to="/more/wss">
            <img src="http://shwebet.trident-crm.com/uploads/slidermkefdd6ca4025cab7fd350182f76361b9f.png" alt="" />  
          </Link> 
        </div>
        <div className="grid-30 left sbo" style={{ marginTop:"20px" }}>
          <Link to="/more/sbo">
            <img src="http://shwebet.trident-crm.com/uploads/slidermkaaf5796866cd468434e45e9002cfb059.png" width={"280px"} alt="" />
          </Link> 
        </div>
        <div className="grid-30 left joker" style={{ marginTop:"20px" }}>
          <Link to="/more/joker">
            <img src="http://shwebet.trident-crm.com/uploads/slidermk050797d597f918ce7cd10f89528764dc.png" width={"280px"} alt="" />
          </Link> 
        </div>
        <div className="grid-30 left all" style={{ marginTop:"20px" }}>
          <Link to="/more/statement">
            {/* <Link to="/more/statement" className='right'>
                        <button  className="hello">All</button>
            </Link> */}
            <img src="http://shwebet.trident-crm.com/uploads/all.jpg" style={{ borderRadius:'30px' }} width={"280px"} alt="" />
          </Link> 
        </div>
      </div>
      </div>
      
      </div>
      
      
      // <div className="row   bg-noise" style={{ margin:"10px auto",width:"80%" }}>
      //   <h1 className='text-center' style={{ margin:"50px" }}>Choose Statement</h1>
      //           <div className="adm-card-body grid-90">
                

      //                   {/* <div className="grid-30 right color-theme-gold" >
      //                     <Link to="/more/statement"><span className='grid-30 center  color-theme-gold ' style={{ textDecoration:"underline" ,borderRadius:"30px",padding:"20px",marginLeft:"50px" }}>ALL</span></Link> 
      //                   </div>
      //                   <div className="grid-30 right color-theme-gold" >

      //                   <Link to="/more/sexy"><span className='grid-30 center pl-20 color-theme-gold'  style={{ textDecoration:"underline" ,padding:"20px",marginLeft:"50px" }}>Sexy Statement</span></Link> 

                            
                            
      //                   </div>
      //                   <div className="grid-30 right color-theme-gold" >
      //                   <Link to="/more/joker"><span className='grid-30 center pl-20 color-theme-gold'  style={{textDecoration:"underline" ,padding:"20px",marginLeft:"50px" }}>Joker Statement</span></Link> 

      //                   </div> */}
            
                    
      //           </div>
      //           {/* <div className="adm-card-body row">

      //               <div className="grid-90  pl-20 pt-4 pb-4">
      //                 {/* <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} /> */}
      //                 {/* <hr />
      //               </div> */}
      //           {/* </div> */}

      //           <div className="adm-card-body row" style={{ }}>
      //                   <div className="col left color-theme-gold" style={{marginLeft:'16%' }}>
      //                   <Link to="/more/wss"><span className='grid-30 center color-theme-gold'  style={{ }}>WSS Statement</span></Link> 
          
      //                   </div>
      //                   <div className="cold left color-theme-gold" style={{marginLeft:'5%' }} >
      //                   <Link to="/more/sbo"><span className='grid-30 center color-theme-gold'  style={{ }}>SBO Statement</span></Link> 
        
                            
      //                   </div>
                        
                    
      //           </div>
      //         </div>
        //   ui =  <div className='dm-card row  bg-noise'>
        //     <div className="adm-card-body grid-90 mt-5">
        //         <div className="grid-30 left pl-20 color-theme-gold">
        //             <Link to="/more/statement"><span className='grid-30 left pl-20 color-theme-gold'>{window.lang.more.statement}</span></Link> 
        //         </div>
        //         <div className="grid-30 left pl-20 color-theme-gold">
        //             <Link to="/more/joker"><span className='grid-30 left pl-20 color-theme-gold'>{window.lang.more.joker}</span></Link> 
        //         </div>
        //         <div className="grid-30 left pl-20 color-theme-gold">
        //             <Link to="/more/joker"><span className='grid-30 left pl-20 color-theme-gold'>{window.lang.more.joker}</span></Link> 
        //         </div>
        //     </div>
        //   </div>

    );
          
      } 

      
}
export default ChoosePage;

