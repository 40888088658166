import React from 'react';
import axios from 'axios';
// import MetaTags from 'react-meta-tags';
import {
    Link
  } from 'react-router-dom';
// import renderHTML from 'react-render-html';
import Iframe from 'react-iframe';
import ScrollMenu from 'react-horizontal-scrolling-menu';
var moment = require('moment');
// import  { Redirect } from 'react-router-dom'
// import FacebookProvider, { CommentsCount,Comments,Like } from 'react-facebook';
require('./../../../BPdata.js')

// let lang = localStorage.getItem('lang');

// const list = [
//   { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  mmshortname: 'ဟိုက်လိုက်',  url : 'HIGHLIGHTS' },
//   { id: 3037, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီမီးယားလိဂ်',  url : 'premier-league' },
//   { id: 3232, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
//   { id: 3102, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'ဆီးရီးအေ', url : 'serie-a' },
//   { id: 3062, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
//   { id: 3054, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
//   { id: 2838, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
//   { id: 2840, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'europa-league' },
//   { id: 2882, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
//   { id: 2840, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
//   { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
// ];

const list = window.BPdata.mainmenu;
  
  // One item component
  // selected prop will be passed
  const MenuItem = ({ activeMenu, data, text, selected }) => {
    return (
      
        <div
        className="menu-item"
        >
          {data.id === 0 ? (
            <Link to={`/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
          ) : (
            <Link to={`/highlight/league/${data.id}/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
          )}
        </div>
    );
  };
  
  
  // All items component
  // Important! add unique key
  export const Menu = (list,selected,activeMenu) => list.map(el => {
    const { name,shortname } = el;
    // console.log(selected)
    return (
      <MenuItem
        activeMenu={activeMenu}
        text={shortname}
        key={name}
        data={el}
      />
    );
  });
  
  
  const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
  };
  
  
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

class HighlightDetailPage extends React.Component {
    constructor(props) {
      super(props);
      window.BPdata.footerMenu = "HIGHLIGHTS";
      
      this.state = {
        data : {
            highlights_name : '',
            highlights_link : '',
            highlights_embed: '',
            highlights_image: '',
            highlights_desc : '',
            created_at      : '',
            updated_at      : ''
       },
        isLoading: true,
        post_id : 1,
        leagueMenu: 'OTHERS'
      };
      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidMount() {
        this.fetchData();
        this.hisUrl('/highlight/'+ this.state.post_id +'/highlight');
    }

    UNSAFE_componentWillUpdate() {
      
      //localStorage.setItem('route', '/highlight/'+ this.state.post_id +'/'+ this.state.data.highlights_name);
      //console.log(localStorage.getItem('route'))
    }

    fetchData() {
        let dataurl = window.BPdata.apiUrl+'/highlight/detail/'+this.state.post_id;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
             this.setState({
                data: resp.data
            });

            this.setState({
                leagueMenu : resp.data.category_short
            })
            
        })
        .catch(function(error) {
          // console.log(error.tostring());
        });   
        
    }

    displayBlock() {
        let ui = []
        //let data
        if(this.state.data  != null){
            // if(typeof(this.state.data.highlights_embed) === 'object' ) {
            //   //console.log(highlight_videos)
            //    data = this.state.data.highlights_embed.reverse()
            // } else {
            //    data = this.state.data.highlights_embed
            // }
            ui.push(
                <div className="main-content" key="0">
                       
                        
                        <div className="row">
                            <br />
                            <h2 className="color-green">{this.state.data.highlights_name}</h2><br />
                            <small>{moment(this.state.data.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}</small> | <small><i>Author: BPdata</i></small><br/>
                            <br />
                            {/* { renderHTML(this.state.data.highlights_desc) } */}
                            
                            <br />
                            {this.displayVideoBlock(this.state.data.highlights_embed)}
                            {/* <FacebookProvider appId="1750258531872776">
                                <Comments href="http://localhost" />
                                <CommentsCount href="http://localhost" /> Comments
                            </FacebookProvider> */}
                        </div>    
                        
                 </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }

    displayVideoBlock(highlight_videos) {
      //console.log(highlight_videos)
      let ui = []
      //console.log(typeof(highlight_videos.length))
      if(typeof(highlight_videos) === 'object' ) {
        //console.log(highlight_videos)
        // let data = highlight_videos.reverse()
        // console.log(data)
        highlight_videos.map((item, key) =>
          ui.push(
            <div className="holds-the-iframe" key={key}>
              <Iframe url={item}
                  id="iframe-video"
                  className="iframe-video"
                  width="100%"
                  styles={{height: "327px"}}
                  position="relative"
                  allowFullScreen/>
              <br />
            </div>
          )
        );
      } else {
        ui.push(
          <div className="holds-the-iframe" key="0">
            <Iframe url={highlight_videos}
                id="myId"
                className="iframe-video"
                width="100%"
                styles={{height: "327px"}}
                position="relative"
                allowFullScreen/>
          </div>
        )
      }
      

      // ui.push(
      //     <div className="holds-the-iframe">
      //       <Iframe url={this.state.data.highlights_embed}
      //           id="myId"
      //           className="myClassname"
      //           width="100%"
      //           styles={{height: "327px"}}
      //           position="relative"
      //           allowFullScreen/>
      //     </div>
      // )
      return ui
    }

    render() {
        const { selected } = this.state;
        let leagueMenu = this.state.leagueMenu;
        // Create menu from items
        const menu = Menu(list, selected, leagueMenu);
        
        return (
            <div className="page">
                <div className="page-content">
                     <ScrollMenu
                            data={menu}
                            arrowLeft={ArrowLeft}
                            arrowRight={ArrowRight}
                            selected={selected}
                            onSelect={this.onSelect}
                            alignCenter={false}
                        />
                    {this.displayBlock()}

                    <div className="ant-back-footer">
                        <div class="ant-back-top">
                            <div class="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }
      
export default HighlightDetailPage;
 