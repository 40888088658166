/* eslint no-dupe-keys: 0 */
import React from 'react';

//import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
// import { FacebookProvider, CommentsCount } from 'react-facebook';
import axios from 'axios';
import { ListView } from 'antd-mobile';
//import HightlightsListPage from './HighlightsListPage';
//import HighlightsVideoSlider from './HighlightsVideoSlider';
// import ScrollMenu from 'react-horizontal-scrolling-menu';
require('./../../../BPdata.js')


const removeMd = require('remove-markdown');

// const list = [
//   { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  mmshortname: 'ဟိုက်လိုက်',  url : 'HIGHLIGHTS' },
//   { id: 3037, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
//   { id: 3232, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
//   { id: 3102, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'ဆီးရီးအေ', url : 'serie-a' },
//   { id: 3062, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
//   { id: 3054, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
//   { id: 2838, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
//   { id: 2840, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'europa-league' },
//   { id: 2882, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
//   { id: 2840, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
//   { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
// ];

// const list = window.BPdata.mainmenu;

var moment = require('moment');

// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/highlight/league/${data.id}/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { name } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={name}
      key={name}
      data={el}
    />
  );
});


// const Arrow = ({ text, className }) => {
//   return (
//     <div
//       className={className}
//     >{text}</div>
//   );
// };


// const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
// const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
let NUM_ROWS = 10;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;


class ForumPage extends React.Component {
  signal = axios.CancelToken.source();
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "FORUM";
    
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    TOTAL_INDEX = 100;
    this.data = [];
    this.state = {
      selected: 'HIGHLIGHTS',
      leagueMenu: 'HIGHLIGHTS',
      dataSource,
      isLoading: true,
      // dummy slides data
      slides: ['NEWS','HIGHLIGHTS','EPL', 'LALIGA','SERIES A','BUNDESLIGA','BUNDESLIGA','BUNDESLIGA'],
      // slides: (function () {
      //   var slides = [];
      //   for (var i = 0; i < 600; i += 1) {
      //     slides.push('Slide ' + (i + 1));
      //   }
      //   return slides;
      // }()),
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      virtualData: {
        slides: ['NEWS','HIGHLIGHTS','EPL', 'LALIGA','SERIES A','BUNDESLIGA'],
      },

      virtualVideoData: {
        slides: [
          {
            "id_highlights": "31",
            "highlights_name": "ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )",
            "highlights_link": "https://www.youtube.com/watch?v=JXZ3FmTk_eI",
            "highlights_embed": "http://www.youtube.com/embed/JXZ3FmTk_eI",
            "highlights_image": "https://img.youtube.com/vi/JXZ3FmTk_eI/mqdefault.jpg",
            "highlights_desc": "<p>ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )</p>",
            "created_at": "2018-12-19 09:39:07",
            "updated_at": "2018-12-19 15:12:36"
          }
        ]
      },
      ban : "0"
    };
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
          if(menu[menu.length - 2] === url ){
                menu.pop();
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                
            }
            //window.BPdata.preurl =  menu[menu.length-1];
            window.BPdata.preurl =  menu[menu.length-2];
        }
    }
  }

  componentDidMount() {
    localStorage.removeItem('popup');
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);
    
    if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //localStorage.setItem(window.BPdata.time, true);
        this.setState({ step: "list" }); 

        

        // simulate initial Ajax
        setTimeout(() => {
          this.fetchData();
        }, 600);
        
      } else {
         this.props.history.push("/more/sign-up");
        }
    } else {
        this.props.history.push("/more/sign-up");
    }

    
    this.hisUrl('/forum')
    //this.fetchData();
    clearTimeout(window.BPdata.liveurl);
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
    clearInterval(this.state)
 }

  UNSAFE_componentWillUpdate() {
    
    // localStorage.setItem('route', '/highlights' );
      //console.log(localStorage.getItem('route'))
  }

  fetchData() {
    let time = localStorage.getItem(window.BPdata.time);
    const url = window.BPdata.apiUrlCMS+'/forum?limit='+NUM_ROWS+'&start=10&time='+time;

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-Trident-Token" : window.BPdata.token,
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      this.data = resp.data.data.data;

      //let this.key = this.data.length;
      // console.log("-----------------");
      // console.log("----lenght---"+data.length)
      // console.log("-----------------");
        if(this.state.ban === 0) {
          setTimeout(() => this.lv.scrollTo(0, 120), 800);
        }
        
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
          isLoading: false,
          ban : resp.data.ban
        });

        
        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);
        //console.log(this.pageIndex)
        
      })
      .catch(function(error) {
        // console.log(error.tostring());
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {

    // let { selected } = this.state;
    // let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    // const menu = Menu(list, selected, leagueMenu);

    
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );

    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const each = this.data[index--];
      return (
        <Link to={"/forum/"+each.id}><div className="list-item  list-box-shadow"><div className="row"><div className="row"><b className="color-red">{each.topic}</b><br />
                {removeMd(each.message)}<br/><sub><i>{moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}</i></sub> <sub><i> , Posted by : {each.username}</i></sub></div></div></div></Link>
      );
    };
    return (
      <div className="page">
        <div className="page-content">
          <div className="main-content">
            {/* <ScrollMenu
                  data={menu}
                  arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                  selected={selected}
                  onSelect={this.onSelect}
                  alignCenter={false}
                /> */}
            {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}
          
          {(this.state.ban === "1") ? <div className="page">
                <div className="page-content"><div className='main-content text-center'><br /><b>You have been banned by Admin Team Temporarily</b></div></div></div> :
            <ListView
              ref={el => this.lv = el}
              dataSource={this.state.dataSource}
              renderHeader={() => <div><div className="list-header">
                  <img src="/img/badge.png" class="list-img-circle" alt={window.lang.title.forum} /><span>{window.lang.title.forum}</span>
              </div>
              <div className="row">
                  <div className="gird-30 text-left top-btn ">
                      <Link to="/forum/create" className="text-muted  btn btn-blue  box-shadow">
                              {window.lang.form.new_post} 
                      </Link>
                  </div>
              </div></div>}
              renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
              </div>)}
              renderRow={row}
              // renderSeparator={separator}
              className="am-list" 
              useBodyScroll
              onScroll={() => { //console.log('scroll');
            }}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={10}
            />
          }
          {/* <HightlightsListPage pageIndexTotal="2" perPage="10" isLoading="false" videoData={this.state.virtualVideoData.slides} /> */}
          <div className="ant-back-footer">
              <div className="ant-back-top">
                  <div className="ant-back-top-content">
                      <Link to={window.BPdata.preurl} className="color-green" >
                  { (JSON.parse(localStorage.getItem("route")) !== null) 
                  ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                  </Link> 
                  </div>
              </div>
          </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default ForumPage;