import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  
class FeedbackPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = { step: "feedback", message : "", form : {time: '',title: 'Shwebet Feedback', description: '' }};
        //this.state = { step: "register", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
    }

    validatePhone() {
        let data
        //console.log(this.state.form.phone.length)
        if( this.state.form.title !== "" &&  this.state.form.description !== "") {
            data = {
                title : this.state.form.title,
                description : this.state.form.description,
                time : this.state.form.time
            }
            this.setState({  message : "Sending Data" });
            this.sendFeedbackApi(data);
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : "Please fill up required field." });
        }
        
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendFeedbackApi(formdata) {
        var self = this;
        const dataurl = window.BPdata.apiUrlCMS+"/player/feedback";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8', "X-Trident-Token" : window.BPdata.token },
        })
        .then(resp => {

            if(resp.data.data.status != 403) {

                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        description: ''
                        // bank_type : 1
                    },
                    message : "အကြံပြုချက် အတွက်ကျေးဇူးတင်ပါသည်။"
                }))

                console.log(this.state.form)

                // self.setState({  form : {title: '', description: '' } , message : "အကြံပြုချက် အတွက်ကျေးဇူးတင်ပါသည်။"});
            } else {
                self.setState({  message : "တစ်ခုခု မှားယွင်းနေပါသည်။" });
            }

         })
          .catch(function(error) {
            // console.log(error);
          });   
    }


    feedbackBlock() {
        return <div className="container">
                    <div className="row">
                        <div className="grid-100 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>Please leave your feedback below:</label>
                            </div>
                        </div>
                        <div className="grid-100 grid-s-100 left text-right">
                            <div className="form-group text-center">
                            <input type="hidden" value={this.state.form.title} name="title" onChange={(e) => this.inputChangeHandler.call(this, e)} />


                            <textarea className="form-control text-left"  name="description" onChange={(e) => this.inputChangeHandler.call(this, e)}  rows="3" value={this.state.form.description} >
                              
                            </textarea>

                            <br/>
                            </div>
                            <input type="submit" className="btn btn-success grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>
                     {/*   <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div> */}
            </div>
        </div>
    }


    

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                //localStorage.setItem(window.BPdata.time, true);
                console.log("ok");
            } else {
                this.props.history.push("/more/sign-up");
                }
        } else {
            this.props.history.push("/more/sign-up");
        }


        // if(localStorage.getItem('lang') === 'mm') {
        //     window.BPdata.title = "အကြံပေးခြင်း";
        // } else {
        //     window.BPdata.title = "Feedback";
        // }

        

        // if(localStorage.getItem(window.BPdata.time)) {
        //     if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //       this.setState({ step: "feedback" }); 
        //     }
            
        //   } 

        if(localStorage.getItem('username')) {

                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        time : localStorage.getItem(window.BPdata.time)
                    }
                }))

                // this.setState({ 
                //     form: {
                //       time : localStorage.getItem(window.BPdata.time)
                //     } 
                // }); 
        } 

        // <img src="/img/back-arrow.png" alt="back" />
        
        this.hisUrl('/more/feedback');
    }


    

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    
                }
                //window.BPdata.preurl =  menu[menu.length-1];
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }
    

    render() {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="main-content text-left">
                        <div className="row text-center">
                        
                            <br />
                            <h2 className="color-green" style={{ fontSize: "24px"}}>
                                We would like your feedback to improve our Shwe bet.
                            </h2>
                            <p> 
                                {/*What is your opinion of Shwe Bet*/}
                            </p>
                            <span>{this.state.message}</span>
                        </div>
                        
                        <br />
                        <br />
                        
                        { this.feedbackBlock() }
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default FeedbackPage;