/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView,Grid } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';

var moment = require('moment');

let NUM_ROWS = 100;
let LIMIT_ROWS = 100;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_TITLE_LENGTH = 50;
let MAX_LENGTH = 90;
let FIRST_MAX_LENGTH = 400;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class HomeListPage extends React.Component {
  constructor(props) {
    super(props);



    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      banners: [],
      select_banners: [],
      dataSource: dataSource.cloneWithRows(this.data),
      news      : [],
      slot_games : [],
      isLoading: false,
      advertise: [],
      secondtext : []

    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = false;

    }
  }

  componentDidMount() {

    if(localStorage.getItem('home_advertise')) {

      if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        this.setState({advertise: home_advertise})
      }
    }

    // if(localStorage.getItem('latest_news')) {
    //   if(JSON.parse(localStorage.getItem("latest_news")) != null) {
    //     let latest_news = JSON.parse(localStorage.getItem("latest_news"));
    //     this.setState({news: latest_news})

    //   }
    // }
    if(localStorage.getItem('banners')) {

      if(JSON.parse(localStorage.getItem("banners")) != null) {
        let video_slides = JSON.parse(localStorage.getItem("banners"));
        this.setState({banners: video_slides})
      }
    }

    this.fetchData();

    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // // simulate initial Ajax
    // setTimeout(() => {
    //   this.fetchData();
    // }, 600);

  }
  componentWillUnmount() {
    // clearInterval(this.state)
  }

  changeSubMenu(id) {

      // alert(id)
      let banners = this.state.banners;
      let secondtext = this.state.secondtext;
      let select_banners = [];


      for(let i = 0; i< this.state.banners.length; i++){

          if(banners[i].promotion_id == id) {
              select_banners.push(banners[i])
              console.log(select_banners)
        }

      }

      let items = window.lang.homeicongame;
      items.forEach(function(item){
        if(item.id == id){
          // secondtext+=item.second_text
          if(!secondtext.includes(item.second_text)){
            secondtext.push(item.second_text)
            // console.log("1")
          }
          if(secondtext.length > 1){
            let valueToRemove = secondtext[0];
            let  index = secondtext.indexOf(valueToRemove);
            if (index !== -1) {
              secondtext.splice(index, 1)
              }
          }
          // let myArray = [1, 2, 3, 4, 5];
          // let valueToRemove = secondtext[0];
          // let index = secondtext.indexOf(valueToRemove);
          // if (index !== -1) {
          //   console.log(secondtext.splice(index, 1));
          // }
        }
      })
      // secondtext = ""

      
      
      console.log(secondtext)

      // console.log(id)

      if(id == 0) {
          select_banners = this.state.banners;
          secondtext = this.state.secondtext;
      }
      // console.log(select_banners)
      // let check = Object.keys(banners).forEach(key => banners[key].promotion_id = id);

      //  console.log(check);
      //  return ;
      // let index = banners.findIndex(obj => obj.promotion_id == id);

      // console.log(index);

      this.setState({ promotion_id : id , select_banners : select_banners ,secondtext : secondtext});

  }

  fetchData() {

        //const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';
        const url = window.BPdata.apiUrlCMS+"/news";

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));

          this.slot_games = resp.data.slot_games;
          this.data = resp.data.news.data;
          // console.log("-----------------");

            this.setState({
              news      : this.data,
              slot_games: this.slot_games,
              // dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              // advertise: resp.data.advertise
            });

            let data = resp.data.banners;

            localStorage.setItem("banners", JSON.stringify(data) )

            // console.log(localStorage.getItem("banners")+"-----------");

            if(JSON.parse(localStorage.getItem("banners"))) {
              let video_slides = resp.data.home_icon;
              this.setState({banners: video_slides})
            }

            localStorage.setItem("latest_news", JSON.stringify(resp.data.news) )

            localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )

            this.changeSubMenu(0);


            //NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)

        })
        .catch(function(error) {
          //console.log(error);
        });
  }

  render() {
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );
    // let index = this.data.length - 1;
    // let ui;
    // let adcount = 0;

    return (
      <div className="page" >

            <br />
            <div className="row text-center App-middle-icon-inner">

              <Grid data={window.lang.homeicongame}
                columnNum={5}
                renderItem={dataItem => (

                      (dataItem.id == this.state.promotion_id  )  ?
                      <div id='activebar' style={{color: '#707070', height: '50', marginTop: '0px' }} >
                        <img src={dataItem.icon_off} height="50px" onClick={this.changeSubMenu.bind(this, dataItem.id)} />
                        <p className="sent">{dataItem.text}</p>
                      </div> :
                      <div id='activebar' style={{color: '#707070', height: '50', marginTop: '0px' }} >
                        <img src={dataItem.icon_off} height="50px" onClick={this.changeSubMenu.bind(this, dataItem.id)} />
                        <p className="sent">{dataItem.text}</p>
                      </div>

                )}

              />
            </div>

            <br />
            <br />
            <div className="row text-center homeiconlist">

            </div>

     

      <div className="row text-center homeiconlist game-card mt-10 pb-30">

            <div className='grid-100'>
                  <div className='row' >
                    <div className="grid-10"> &nbsp;</div>
                    <div className='grid-80 text-left mb-30 mt-10' >

                      <img  src="img/list/cat-icon.png" alt="Cat" />

                      <b style={{ color:"white", paddingLeft:'10px' }}>
                        {this.state.secondtext}
                      </b>
                      <br />
                      <br />

                    </div>
                    <div className="grid-10"></div>
                  </div>
            </div>
            {/* <div>

              <div className="grid-40  left" style={{ paddingLeft:"10px" ,width:"10%"}}>
                <img className='left' style={{ marginLeft:"24%" }} src="img/list/Vector.png" alt="Vector img"/>

              </div>
              <div className="grid-40" style={{ paddingLeft:"10px" }}>
              <h2 className='left'>{window.lang.title.game}</h2>

              </div>

            </div> */}



          <div className="grid-10">&nbsp;</div>
          <div className="grid-80">


          <Grid data={this.state.select_banners}
                columnNum={2}
                renderItem={dataItem => (
                  <Link to={dataItem.link_url} className="cont-wrap-link" style={{  width: '95%'}}>
                      <div style={{ color: '#707070', fontSize: '14px', marginTop: '10px',textAlign: 'center',   width: "100%",  marginBottom: '0px',}}>

                        <img src={window.BPdata.apiAssetUrl+'/'+dataItem.slider_link} className="thumbnail-icon img-responsive opona"/>
              

                      </div>
                  </Link>
                )}
              />



          </div>
          <div className="grid-10">&nbsp;</div>
        </div>
        <div className="row">
          <div className="grid-100">
            <br /><br />
          </div>
        </div>


            </div>


    );
  }
}

export default HomeListPage;
