import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import DayPicker from 'react-day-picker';

var moment = require('moment');
require('./../../BPdata.js')


class AccLeagueHistoryPage extends React.Component {
    constructor(props) {
        super(props)

        this.handleDayClick = this.handleDayClick.bind(this);

        this.state = { 
            selectedDays: [new Date()],
            status: 1,
            step: "login", 
            message : "", 
            phone : "",
            league: [],
            contests : 
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            isLoading       : true,
            id              : 0
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);

        if(props.match.params.id) {
            this.state.id = props.match.params.id
        }

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
    }

    fetchData(url,data) {
        
        // let phone = localStorage.getItem(window.BPdata.time)  

        let dataurl = url;
        
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
        //   this.data = resp.data;
        //   console.log("ok2");
        //     console.log(this.data);
            //console.log(this.pageIndex);
            // this.pageIndex++;
            this.setState({
                contests    : resp.data.contests,
                league      : resp.data.league,
                status      : 1,
                isLoading   : false
            }); 

            // if(resp.data.status) {
            //     this.setState({
            //         disabled: 'disabled'
            //     })
            // }

            //console.log(this.state);
        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   

        //console.log(this.state.contests);
 
    }

    
    handleInputChange(event) {
        const target = event.target;
        //let value = target.type === 'radio' ? target.checked : target.value;
        let value = target.type === 'radio' ? target.value : target.value;
        // let rev_value;
        if(parseInt(value)) {
            value     = 1;
          //  rev_value = 0;
        } else {
            value     = 0;
          //  rev_value = 1;
        }
        const name = target.name;
        let str = this.state.league;
        
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            
            //console.log(word)

            let first   = word[0];
            let league  = word[1];
            let id      = word[2];
            // console.log(first)
            // console.log(id)
            if(first === "results") {
                str[league].data[id].results = value;
                //str[id].per_result2 = rev_value;
            }


            if(first === "over_under") {
                str[league].data[id].over_under = value;
                //str[id].over_under = value;
                //str[id].under = rev_value;
            }

            
        }

        this.setState({league:str,phone:  localStorage.getItem(window.BPdata.time) });
    }

    displayBlock( ) {
        let data = this.state.league
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) { 
                let each = data[i].group 
                //console.log(each)
                let childdata = data[i].data
                if(childdata) {
                    if(childdata.length > 0) {
                        ui.push(<div className="league-box" key={i}>
                                <div className="league-list-header">
                                    <img src="/img/badge.jpg" className="list-img-circle" alt="Trending" />
                                        <span>{each.name}</span>
                                </div>
                                {this.childui(i,childdata)}
                        </div>)
                    } 
                }
                
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }


    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
            let status = 3;
        
            if(Array.isArray(data) === false) {
                console.log(data)
                console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        let results = each.results
                        //console.log(per_result2)
                        let over_under = each.over_under
                        
                        //check handicap and change setting
                        
                        if(each.handicap === "") {
                            status = 1;
                        }
                        if(each.over_unders === "") {
                            status = 1;
                        }

                        ui.push(<table className="table  table-border" key={i}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td colSpan="5" className="text-center">
                                    <b>{moment(each.event_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')} (match)</b>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan="5" className="text-center">
                                    <b>{moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')} (posted)</b>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                { ( parseInt(results) !== 2) ? (( parseInt(results) === 1) ?
                                       <input type="radio" name={`results-${id}-${i}`} value="1" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                    :  <input type="radio" name={`results-${id}-${i}`} value="1" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>)
                                    : "-"
                                }
                                </td>
                                <td colSpan="2" className="text-right">
                                    {each.team1}
                                </td>
                                <td className="text-center"> vs </td>
                                <td colSpan="2" className="text-left">
                                    {each.team2}
                                </td>
                                <td className="text-center">
                                    { ( parseInt(results) !== 2) ? ((parseInt(results) === 0) ?
                                    <input type="radio" name={`results-${id}-${i}`} value="0" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/> 
                                    : <input type="radio" name={`results-${id}-${i}`} value="0" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/> )
                                    : "-"
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    { ( parseInt(over_under) !== 2) ? (( parseInt(over_under) === 1) ?
                                        <input type="radio" name={`over_under-${id}-${i}`} value="1" checked="checked"  onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>  
                                    :  
                                        <input type="radio" name={`over_under-${id}-${i}`} value="1"  onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>)
                                        : "-"
                                    }
                                </td>
                                <td colSpan="2" className="text-right">
                                    Over
                                </td>
                                <td className="text-center"> | </td>
                                <td colSpan="2" className="text-left">
                                    Under
                                </td>
                                <td className="text-center">
                                    { ( parseInt(over_under) !== 2) ?  ((parseInt(over_under) === 0) ?
                                        <input type="radio" name={`over_under-${id}-${i}`} value="0" checked="checked" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>
                                    :
                                        <input type="radio" name={`over_under-${id}-${i}`} value="0" onChange={this.handleInputChange} disabled={ (this.state.status===1) ? "disabled" : ""}/>)
                                        : "-"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan="2" className="text-right grid-40">
                                    <b>Handicap</b>
                                </td>
                                <td className="text-center">|</td>
                                <td colSpan="2" className="text-left  grid-40">
                                    <b>Over/Under</b>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan="2" className="text-right">
                                    { (each.h_a === "H")? "(H) " : "" }
                                    <span className="color-blue">{each.handicap}</span>
                                </td>
                                <td className="text-center">|</td>
                                <td colSpan="2" className="text-left">
                                    <span className="color-red">{each.over_unders}</span>
                                    { (each.h_a === "A")? " (A)" : "" }
                                    
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="7" className="text-center">
                                    {(each.h_score) ? ((each.h_score !== '-1' ) ? "Result = "+each.h_score+"/"+each.a_score : "Pending" ) : "" }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="7" className="text-center">
                                    {(each.results_a) ? ((each.results_a !== '0' || each.over_under_a !== '0' ) ? "Handicap Points = "+ each.results_a : "Pending" ) : "" }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="7" className="text-center">
                                     {(each.over_under_a) ? (( each.results_a !== '0' || each.over_under_a !== '0' ) ? "Over/Under Points = "+ each.over_under_a  : "Pending" )  : "" }
                                </td>
                            </tr>
                        </tbody>
                    </table>)
                        
                }

                
                
                if(ui.length === 0) {
                    //this.setState({status : 1});
                        ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
                }
                    return ui
                }
        }

        // console.log(status+"-------")
        // if(status === 1) {
        //     console.log(status+"-------")
        //     this.setState({status : 0});
        // }
        
        return ui;
    }

    datePickerPre() {
        // moment(this.state.selectedDays[0], moment.ISO_8601).add(1, 'day');
         //moment(this.state.selectedDays[0]).format("YYYY-MM-DD")
         let day = moment(this.state.selectedDays[0]).subtract(1, 'day')._d;
         this.setState({
             selectedDays : [day],
             isLoading : true

         })
 
         let stateday = moment(day).format("YYYY-MM-DD");
         //this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
        //  let phone = localStorage.getItem(window.BPdata.time) ;
        //  this.fetchData(window.BPdata.apiUrl+'/user/league-history?phone='+phone+'&filter='+stateday,'livescore');
         this.fetchData(window.BPdata.apiUrl+'/user/'+this.state.id+'/league-history?id='+this.state.id+'&filter='+stateday,'livescore');
     }

     datePickerNex() {
        let day = moment(this.state.selectedDays[0]).add(1, 'day')._d;
        this.setState({
            selectedDays : [day],
            isLoading : true
        })

        let stateday = moment(day).format("YYYY-MM-DD");
        // let phone = localStorage.getItem(window.BPdata.time) ;
        // this.fetchData(window.BPdata.apiUrl+'/user/league-history?phone='+phone+'&filter='+stateday,'livescore');
        //console.log(this.state.selectedDays[0])
        this.fetchData(window.BPdata.apiUrl+'/user/'+this.state.id+'/league-history?id='+this.state.id+'&filter='+stateday,'livescore');
    }


    datePicker(hideResults) {
        let block = []
        //console.log(hideResults)
        if(this.state.hidediv === true) {
            this.setState({hidediv : false })
        } else {
            this.setState({hidediv : true })
        }
        
        return block
    }

  

    handleDayClick(day, { selected }) {
        // console.log('----------------------')
        //console.log(selected)
        // console.log('----------------------')
        this.setState({
            selectedDays : [day]
        })

        this.setState({hidediv : false })
        let stateday = moment(day).format("YYYY-MM-DD");
        //this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');

        // let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/'+this.state.id+'/league-history?id='+this.state.id+'&filter='+stateday,'livescore');
        // this.fetchData(window.BPdata.apiUrl+'/user/league-history?phone='+phone+'&filter='+stateday,'livescore');
      }


    componentDidMount() {
        //console.log(localStorage.getItem(window.BPdata.time))
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ 
                  step: "profile"
                }); 
            }

           
            
          } 

        // if(localStorage.getItem('username')) {
        //         this.setState({ 
        //             form: {
        //                 username : localStorage.getItem('username')
        //             } 
        //         }); 
        // } 

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" }); 
        // }
        
        this.hisUrl('/more/account/'+this.state.id+'/league-history');

        let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        // let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/'+this.state.id+'/league-history?id='+this.state.id+'&filter='+stateday,'livescore');
        //this.fetchData();
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                            <div className="row m-5">
                                <br/>
                                <div className="row live-score-header">
                                    <div className="left">
                                        {/* <div className="live-color-box" className={
                                                this.state.button_flag ? 'live-box active' : 'live-box'
                                            }  onClick={this.handleButtonClick}>
                                            <span> 
                                            {
                                            (this.state.live === 0) ? ("LIVE") : ("LIVE ("+this.state.live+")")
                                            }
                                            </span>
                                        </div> */}
                                    </div> 
                                    <div className="right">
                                        <div className="calendar-color-box">
                                            <span onClick={this.datePickerPre.bind(this)} className="calendar-left-arrow left">  
                                                &lt;
                                            </span>
                                            <span onClick={this.datePicker.bind(this)} className="no-bg calendar-color-box-text">
                                                { moment(this.state.selectedDays[0]).format("DD MMMM") } <img src="/img/calendar.png" alt="calendar"/>
                                            </span>
                                            <span  onClick={this.datePickerNex.bind(this)} className="calendar-right-arrow right">  
                                                &gt;
                                                {/* <img src="/img/calendar.png" alt="calendar"/> */}
                                            </span>
                                        </div>
                                    </div>
                            </div>
                            <br />
                            <div className="row">
                                {this.state.hidediv ?
                                    <DayPicker
                                    selectedDays={this.state.selectedDays}
                                    onDayClick={this.handleDayClick}
                                    month={new Date(this.state.selectedDays)}
                                    />: null 
                                } 
                            </div>
                            
                        </div>
                        <div className="row text-left">
                            <br />
                            <span>{this.state.message}</span>
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                          
                        </div>
                        {/* <div className="row">
                                {(this.state.status)?"":<div className="text-center"><button className="btn btn-success" onClick={this.submitBillContent}>Submit</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>Cancel</button></div> }
                        </div> */}
                        <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}

export default AccLeagueHistoryPage;