
import React from "react";
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
import SideMenu from "../components/sidemenu/SideMenu";
import "./ToolBar.css";
import LoadingSpinner from "./../LoadingSpinner";

class ToolBar extends React.Component {
  constructor(props){
    
    super(props)
    // Set initial state
    this.state = { isitLoading : false }

    // Binding this keyword
    this.updateState = this.updateState.bind(this)
    this.checklogin = this.checklogin.bind(this)
    this.langSwitch = this.langSwitch.bind(this)
  }

  updateState(){
    // Changing state
    this.setState({greeting :
                 'GeeksForGeeks welcomes you !!'})
  }

  checklogin() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                return true;
            }
        }
        return false;
  }

  langSwitch() {
          if(localStorage.getItem('lang') === 'en') {
              localStorage.setItem('lang', 'mm');
              window.lang = window.mmlang;
          } else {
              localStorage.setItem('lang', 'en');
              window.lang = window.enlang;
          }



        window.location.href = "/";


          // console.log(window.lang)
          // this.props.history.push("/");
  }

  pageRefresh = () =>{
    
      this.setState({
        isitLoading : true
      });
       window.location.reload();
  }

  render(){
    return (
      <div>
         <header className="App-header">
        <div className="App-header-inner">
          <div className="row text-center">
            <div className="align-center left grid-25 logo pt-10">
                {(this.checklogin()) ?
                <div className="toggle-btn">
                  <SideMenu click={this.props.drawerToggleClickHandler} />
                </div>
                : <div className="grid-100">
                            {(localStorage.getItem('lang') === 'mm') ? <img src="/img/nation/eng.png" style={{ borderRadius: "100%", height : "32px" ,  }} onClick={this.langSwitch} />: <img src="/img/nation/mm.png" style={{ borderRadius: "100%", height : "32px" ,   }} onClick={this.langSwitch} />}

                    </div> }
            </div>
            <div className="align-center left grid-50 logo">


              <Link to="/" className="color-white">
                <img src="/img/header_logo.png" className="header-logo" alt="Shwe Bet" />
              </Link>
            </div>
            <div className="align-right right grid-25 parent">

              <Link to="/more/announcements" className="pointer mr-20">
                <img src="/img/_noti.png"  alt="profile"/>
              </Link>


              <img src="/img/_switch.png"  alt="profile" onClick={this.pageRefresh} className="pointer pl-10" />


              {/* <div className="download-apk-here">
                  {
                    ((navigator.userAgent.toLowerCase().indexOf("BPdata") > -1 ) ? "" : <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.BPdata.ss" >Download APK Here</a> )
                  }
              </div> */}

            </div>
          </div>
        </div>
      </header>
      {this.state.isitLoading ? <LoadingSpinner /> : ""}
      </div>
     
     
    )
  }
}

export default ToolBar;
