/* eslint no-dupe-keys: 0 */
import React from 'react';

//import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
// import { FacebookProvider, CommentsCount } from 'react-facebook';
import axios from 'axios';
import { ListView,Modal } from 'antd-mobile';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
// import {   positions,useAlert } from "react-alert";
require('./../../BPdata.js')


const alert = Modal.alert;
// const ralert = useAlert;

var moment = require('moment');


// One item component
// selected prop will be passed


function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

// All items component
// Important! add unique key


let NUM_ROWS = 10;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;



class TipstersListPage extends React.Component {

 

  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "CONTEST";
    
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    TOTAL_INDEX = 100;
    this.data = [];
    this.state = {
      message : "",
      selected: 'HIGHLIGHTS',
      leagueMenu: 'HIGHLIGHTS',
      dataSource,
      isLoading: true,
      // dummy slides data
      slides: ['NEWS','HIGHLIGHTS','EPL', 'LALIGA','SERIES A','BUNDESLIGA','BUNDESLIGA','BUNDESLIGA'],
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      virtualData: {
        slides: ['NEWS','HIGHLIGHTS','EPL', 'LALIGA','SERIES A','BUNDESLIGA'],
      },
      id : 0,
      packages:[],
      status : 0,
      alertId: ''
    };

    this.showSuccessAlert = this.showSuccessAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);

    if(props.match.params.id) {
        this.state.id = props.match.params.id
    }
  }


  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
          if(menu[menu.length - 2] === url ){
                console.log("first");
                menu.pop();
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                
            }
            //window.BPdata.preurl =  menu[menu.length-1];
            window.BPdata.preurl =  menu[menu.length-2];
        }
    }
  }

  tipsterpackagesui() {
      let ui = [];
      let arr = this.state.packages;
      let arlength = this.state.packages.length;
      
          if(arlength > 0) {
              
              for (let i = 0; i < arlength; i++) { 
                  let each = arr[i]
                  ui.push(
                          <div className="grid-40  bg-gray package-block" key={each}>
                              <img src="/img/tipster.png" alt="Weekly"/><br/>
                              <b className="color-green">{each.name}</b><br/>
                              <b className="color-red">{each.gem} Gem</b><br/>
                              <br/>
                              <button className="btn btn-success" onClick={() =>
                                  alert('Package', '၀ယ်ယူမလား မ၀ယ်ဘူးလား', [
                                  { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel') },
                                  { text: '၀ယ်မယ်', onPress: () => this.sendTipsterPredictApi(each.id)  },
                                  ])
                              }>{window.lang.packages.buy}</button>
                  </div>)
              }
          }

      return ui;

  }

  sendTipsterPredictApi(package_id) {
      
      var self = this;
      let player_id = this.state.id;
      let salt = localStorage.getItem(window.BPdata.time);
      // var data = {package:packageid, player : playerid, salt : salt }

      // let dataurl = window.BPdata.apiUrl+'/participant/predict/order';
      let dataurl = window.BPdata.apiUrl+'/user/check';

      let data = {
          package_id : package_id,
          time:  salt,
          type : "tipsterpredictorder" ,
          player_id: player_id
      };

      axios({
          method: 'post',
          url: dataurl,
          data: data,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
      })
      .then(function (response) {
          // shortalert.show(response.data.message);
          self.fetchData();
          
          self.setState({  
            message : response.data.message,
            status: response.data.status,
            packages : response.data.packages
          });

          if(response.data.message){
            self.showSuccessAlert(response.data.message )
          }
          

          
      })
      .catch(function (error) {
          // console.log(error);
      });
  }

  showSuccessAlert(message) {
    const alertId = StatusAlertService.showSuccess(message);
    this.setState({ alertId });
  }
  
  removeAlert() {
    StatusAlertService.removeAlert(this.state.alertId);
  }

  componentDidMount() {
    localStorage.removeItem('popup');
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);
    
    if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //localStorage.setItem(window.BPdata.time, true);
        // this.setState({ step: "list" }); 
        setTimeout(() => this.lv.scrollTo(0, 120), 800);

        // simulate initial Ajax
        setTimeout(() => {
          this.fetchData();
        }, 600);

        this.sendTipsterPredictApi(0);
        
      } else {
         this.props.history.push("/more/sign-up");
        }
    } else {
        this.props.history.push("/more/sign-up");
    }

    

    this.hisUrl('/more/tipsters/'+this.state.id+'/tipster')
   
  }

  componentWillUnmount() {
    clearInterval(this.state)
 }

  UNSAFE_componentWillUpdate() {
    
    // localStorage.setItem('route', '/highlights' );
      //console.log(localStorage.getItem('route'))
  }

  fetchData() {
    let time = localStorage.getItem("time");
    const url = window.BPdata.apiUrl+'/tipsters/lists?limit='+NUM_ROWS+'&start=10&tid='+this.state.id+'&time='+time;

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      this.data = resp.data.data;
        
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
          isLoading: false,
          status : resp.data.status
        });

        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);
        //console.log(this.pageIndex)
        
      })
      .catch(function(error) {
        // console.log(error.tostring());
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    

    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
      return (
        <Link to={"/more/tipsters/"+this.state.id+"/detail/"+obj.id}>
        <div key={rowID} style={{ padding: '0 5px' }}>
          <div style={{ display: '-webkit-box', display: 'flex', padding: '5px 0' }}>
            
            <div className="row" style={{ lineHeight: 1 }}>
              
              <div className="row list-footer" style={{ display: 'flex' }}>
                <div className="grids left">
                  <img  className="list-featured-img"   src={window.BPdata.baseUrl+"img/goal-net.jpg"} alt={obj.title}  />
                </div>
                <div className="text-left grid-80 left">
                  <div className="row" style={{ lineHeight: 1 }}>
                    <div className="list-featured-p">
                      <b className="color-green">{obj.title}</b><br/>
                      {moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY')}
                    </div>
                  </div>
                </div>
                <div className="text-right grid-20 right">
                  
                  {/*  <FacebookProvider appId="1967980890125208">
                        <i>
                          <CommentsCount href={window.BPdata.baseUrl+"/highlights/"+obj.id_highlights+"/"+encodeURI(obj.highlights_name)} />
                          <img src="/img/comment.jpg" alt="comment" /> 
                        </i> 
                    </FacebookProvider> */}
                </div>
                   
              </div>
              
            </div>
          </div>
        </div>
        </Link>
      );
    };
    return (
      <div className="page">
          <div className="page-content ">
              <div className="main-content">
        
              {/* <button className="btn btn-success" onClick={() =>
                                  alert('Package', '၀ယ်ယူမလား မ၀ယ်ဘူးလား', [
                                  { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel') },
                                  { text: '၀ယ်မယ်', onPress: () => this.sendTipsterPredictApi(each.id)  },
                                  ])
                              }>{window.lang.packages.buy}</button> */}

              {/* <button
                onClick={() => 
                  ralert.show('Oh look, an alert!')
                }
              >test</button> */}

          <div className="row">
              <div className="grids">
                  <StatusAlert/>
                  {/* <b>{this.state.message}</b> */}
              </div>
          </div>
          <br/>
          {/* <h3>{this.state.result.gem}</h3>               */}
          { (this.state.status === 1 ) ?
          <ListView
            ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            renderHeader={() => <div className="list-header"><Link to="/more/tipsters"><img src="/img/badge.jpg" className="list-img-circle" alt="Trending" /><span>{window.lang.title.tip}</span></Link></div>}
            renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
              {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
            </div>)}
            renderRow={row}
            renderSeparator={separator}
            className="am-list" 
            useBodyScroll
            onScroll={() => { //console.log('scroll');
           }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          />
          :
          <div >
            
            { (this.state.status === 0 ) ? 
              <div>
                <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource}
                  renderHeader={() => <div></div>}
                  renderFooter={() => ("")}
                  renderRow={row}
                  renderSeparator={separator}
                  className="am-list" 
                  useBodyScroll
                  onScroll={() => { //console.log('scroll');
                }}
                  scrollRenderAheadDistance={500}
                  onEndReached={this.onEndReached}
                  onEndReachedThreshold={10}
                />
              <br/>
              <div className="row text-center" >
                { this.tipsterpackagesui() }
              </div> 
              </div> : <div>
                <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource}
                  renderHeader={() => <div className="list-header"><Link to="/more/tipsters"><img src="/img/badge.jpg" className="list-img-circle" alt="Trending" /><span>Order</span></Link></div>}
                  renderFooter={() => ("")}
                  renderRow={row}
                  renderSeparator={separator}
                  className="am-list" 
                  useBodyScroll
                  onScroll={() => { //console.log('scroll');
                }}
                  scrollRenderAheadDistance={500}
                  onEndReached={this.onEndReached}
                  onEndReachedThreshold={10}
                />
              </div> }
          </div>
          }

          <div className="ant-back-footer">
              <div className="ant-back-top">
                  <div className="ant-back-top-content">
                      <Link to={window.BPdata.preurl} className="color-green" >
                  { (JSON.parse(localStorage.getItem("route")) !== null) 
                  ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                  </Link> 
                  </div>
              </div>
          </div>

          </div>
        </div>
      </div>
    );
  }
}

export default TipstersListPage;