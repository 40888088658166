import React from 'react';
import axios from 'axios';
import mmph from 'myanmar-phonenumber';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

//   const alert = Modal.alert;

class MorePage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "MORE";
        this.state = { step: "home", message : "", league : false, sleague : false};
        this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);
        this.cacheClear         = this.cacheClear.bind(this);
    }

    changeSubMenu(step) {
        if(step === 'league') {
            if(this.state.league) {
                this.setState({ league : false});
            } else {
                this.setState({ league : true});
            }
        }
        
        //console.log(step)
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  
              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }


    

    componentDidMount() {

        
        
        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "list" }); 
          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }

        this.setState({ step: "list" }); 

        // if(localStorage.getItem(window.BPdata.time)) {
        //   if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //     //localStorage.setItem(window.BPdata.time, true);
        //     this.setState({ step: "list" }); 
        //   }
          
        // } 

        this.hisUrl('/more');
        clearTimeout(window.BPdata.liveurl);
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    cacheClear() {
        window.localStorage.clear();
        //window.location.reload(true);
        //this.props.history.push("/");
    }

    // cacheClear() {
    //     let data = window.confirm("Are you sure want to cache clear?");
    //     if(data){
    //         window.localStorage.clear();
    //         this.props.history.push("/contest");
    //     }
    // }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length > 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    username : this.state.form.username,
                    phone : mmph.normalizeInput(this.state.form.phone)
                }
                this.setState({  message : "SMS Sending" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : "Invalid phone number" });
            }
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : "Invalid phone number" });
        }
        
    }
    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrl+'/user/register';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "SMS already Sent", step : "verify" });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            //case "register":   return this.registerBlock();
            case "profile": return this.profileBlock();
            case "home": return this.homeBlock();
            case "list": return this.listBlock();
            default:      return "";
          }
    }

    checklogin(){
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                return true;
            }
        }
        return false;
    }

    listBlock() {
        return  <div className="row text-left setting-menu">
                    <ul>
                                                
                        {/* <Link  to="/more/package/store"><li><img src="/img/list/packagestore.png" alt="Package Store" />Package Store <span>&gt;</span></li></Link> */}
                        {(this.checklogin()) ?  <Link to="/more/myaccount"><li><i class="pe-7s-user pe-2x pe-va color-gold"></i> &nbsp; {window.lang.more.my_account}<span>&gt;</span></li></Link> : ""}
                       {/* {(this.checklogin()) ?  <Link to="/more/wallet-store"><li><i class="pe-7s-user pe-2x pe-va color-gold"></i> &nbsp; {window.lang.more.wallet_store}<span>&gt;</span></li></Link> : ""}*/}
                        <Link to="/help-center"><li><i class="pe-7s-user-female pe-2x pe-va color-gold"></i> &nbsp;&nbsp;&nbsp; {window.lang.more.help_center}<span>&gt;</span></li></Link>
                       {/* <Link to="/more/announcements"><li><i class="pe-7s-bell pe-2x pe-va color-gold"></i> &nbsp; {window.lang.more.announcement} <span>&gt;</span></li></Link>*/}
                        {(this.checklogin()) ?  <Link to="/more/feedback"><li><i class="pe-7s-user pe-2x pe-va color-gold"></i> &nbsp; {window.lang.more.feedback} <span>&gt;</span></li></Link> : ""}
                        {/* <button type="button"  onClick={this.cacheClear}>Refresh 2</button> */}
                        {/* <Link to="/more/announcements"><li><img src="/img/list/annoucement.png" alt={window.lang.more.announcement}  onclick="location.reload(true)"/>{window.lang.more.announcement} <span>&gt;</span></li></Link> */}
                       
                    </ul>

                </div>
    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {(localStorage.getItem('username')? localStorage.getItem('username') : "Customer")}</h2>
              <p>Welcome to BPdata</p>
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <br />
            <br />
            <br />
            {/* <h1 className="color-green">Web App</h1> */}
            <img src="/img/kohtoo-big.jpeg" alt="profile" className="medium-logo"/>
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-success grid-50">{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50">{window.lang.form.register}</Link>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    
                    { this.viewHandler() }
                   
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MorePage;