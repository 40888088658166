/* eslint no-dupe-keys: 0 */
import React from 'react';


import {
  Link
} from 'react-router-dom';
import { ListView } from 'antd-mobile';
import axios from 'axios';
import renderHTML from 'react-render-html';
// import AdSense from 'react-adsense';

require('./../../BPdata.js')

let lang = localStorage.getItem('lang');


const NUM_ROWS = 3;
let pageIndex = 0;

// One item component
// selected prop will be passed
// export const Menu = (list,selected,activeMenu) => list.map(el => {
//   const { fullname, url} = el;
//   // console.log(selected)
//   return (
//     <MenuItem
//       activeMenu={selected}
//       text={fullname}
//       key={url}
//       data={el}
//     />
//   );
// });


// // All items component
// // Important! add unique key
// const MenuItem = ({ activeMenu, data, key, selected }) => {
//   return (
    
//       <div
//       className="menu-item"
//       >
//         {data.id === 0 ? (
//           <Link to={`/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
//         ) : (
//           <Link to={`/tip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
//           // <Link to={`/tip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{data.name}</Link>
//         )}
//         {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
//           {/* <span>{text}</span> */}

//         {/* </Link> */}
//       </div>
//   );
// };



// const Arrow = ({ text, className }) => {
//   return (
//     <div
//       className={className}
//     >{text}</div>
//   );
// };


// const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
// const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class ChannelsSchedulePage extends React.Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "TIP&ODDS";
    
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      menu :[
        { id: 0, name: 'All',  shortname: 'All',  mmshortname: 'အားလုံး', url : 'tip-and-odd' },
        { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီမီးယားလိဂ်',  url : 'EPL' },
        { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'SPL' },
        { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'SERIEA' },
        { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'BUNDESLIGA' },
        { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'LIGUE1' },
        { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'CHAMPION' },
        { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'EUROPA' },
        { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'WC' },
        { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'UEFA' },
        { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'OTHERS' }
      ],
      
      selected: 'tip-and-odd',
      dataSource,
      isLoading: false,
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'tip-and-odd',
      league_name: 'tip-and-odd',
      data : [],
      advertise : []
    };

    if(props.match.params.name) {
      this.state.selected = props.match.params.name
    }
    
    //console.log(props.match.params.name)
  }
  onSelect = key => {
    this.setState({ selected: key });
    //console.log(this.state.selected)
    //this.fetchData()
    
  }
  
  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
      if(menu.length > 0) {
        //console.log(menu[menu.length - 1] + "-" + url )
          if(menu[menu.length - 2] === url ){
              console.log("first");
              menu.pop();
              //window.BPdata.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              //localStorage.setItem("route", JSON.stringify(menu) );
          }  
          else if(menu[menu.length-1] !== url ){
              menu.push(url)
              //window.BPdata.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              
          }
          //window.BPdata.preurl =  menu[menu.length-1];
          window.BPdata.preurl =  menu[menu.length-2];
          //console.log(window.BPdata.preurl)
      }
    }
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  componentDidMount() {
    
    // if(localStorage.getItem('latest_tipandodd')) {
    //   if(JSON.parse(localStorage.getItem("latest_tipandodd")) != null) {
    //     let latest_tipandodd = JSON.parse(localStorage.getItem("latest_tipandodd"));
    //     this.setState({data: latest_tipandodd})
        
    //   }
    // } 
    // if(localStorage.getItem('tipandodd_advertise')) {
    //   if(JSON.parse(localStorage.getItem("tipandodd_advertise")) != null) {
    //     let tipandodd_advertise = JSON.parse(localStorage.getItem("tipandodd_advertise"));
    //     this.setState({advertise: tipandodd_advertise})
    //   }
    // } 
    
    this.fetchData();
    this.hisUrl('/tip-and-odd')
    //this.state.isLoading = true;
    clearTimeout(window.BPdata.liveurl);
    //console.log(this.state.league_name)
  }

  componentDidUpdate(prevProps, prevState) {
    //this.setState({ selected: prevProps.match.params.name });
    // if (this.state.selected !== prevState.selected) {
    //   // At this point, we're in the "commit" phase, so it's safe to load the new data.
    //   this.setState({ selected: prevProps.match.params.name });
    //   this.fetchData();
    // }
  }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    // componentWillReceiveProps(nextProps) {
    //   if (nextProps.dataSource !== this.props.dataSource) {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
    //     });
    //   }
    // }
  capitalize(str) {
    if(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return "";
    }
  }

  urlSearch(url) {
    this.state.menu.forEach(function(x) {
      //console.log(x.url == url)
      return x.url === url
     // dict[x.code] = x.name
     })

     return false;
    // return list.filter(
    //     function(list){ return list.url == url }
    // );
  }

  displayBlock() {
    let ui = [];
    let data = this.state.data
    let league_title = ""
    let j = 0;
    let adcount = 0;
    //this.setState.isLoading = false ;
    //this.setState({ isLoading: false });
        //console.log(data)
       // console.log(this.state.data)
        if(this.state.data.length > 0){
          
          // data.forEach(function(each){
            for (let i = 0; i < this.state.data.length; i++) { 
                let row = data[i];
                ui.push(<div className="row league-price-table"  key={i}>
                <br />
                <div class="list-header">
                    <img src="/img/badge.jpg" class="list-img-circle" alt={row.name} /><span>{row.name}</span>
                </div>
                {renderHTML(row.description)}
                </div>);
              
              
          }
        }
    return ui;
  }

  fetchData() {
      let dataurl = window.BPdata.apiUrl+'/user/channels/schedule';

      axios.get(dataurl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        crossDomain: true,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(resp => {
      //   this.data = resp.data;
      //     console.log(this.data);
          //console.log(this.pageIndex);
          // this.pageIndex++;
          this.setState({
           // league_title : resp.data.league_title,
            //data: resp.data.data
            data: resp.data.data,
            isLoading: false 

          });
          //this.setState({ isLoading: false });

        //   localStorage.setItem("latest_tipandodd", JSON.stringify(resp.data.data) )
        //   localStorage.setItem("tipandodd_advertise", JSON.stringify(resp.data.advertise) )

        //  console.log(resp.data);
      })
      .catch(function(error) {
        // console.log(error.tostring());
      });   
      
      
  }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }
    console.log('reach end', event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.rData = { ...this.rData, ...genData(++pageIndex) };
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  }

  
  

  render() {
    
    let { selected } = this.state;
    // let leagueMenu = this.state.leagueMenu;
    // // Create menu from items
    // const menu = Menu(this.state.menu, selected, leagueMenu);

    
    return (
      <div className="page">
        <div className="page-content">
            <div className="main-content">
            {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
            /> */}
            {/* <h2>Test</h2> */}
            { (this.state.isLoading) ?
                (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : "" }

                  {/* <AdSense.Google
                    client='ca-app-pub-7490413962332431/1747021975'
                    slot='5849918519'
                  /> */}
                {this.displayBlock() }

                
                <div className="ant-back-footer">
                    <div className="ant-back-top">
                        <div className="ant-back-top-content">
                            <Link to={window.BPdata.preurl} className="color-green" >
                        { (JSON.parse(localStorage.getItem("route")) !== null) 
                        ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                        </Link> 
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    );
  }
}

export default ChannelsSchedulePage;