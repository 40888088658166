/* eslint no-dupe-keys: 0 */
import React,{Component} from 'react';
import { ListView } from 'antd-mobile';

import ScrollMenu from 'react-horizontal-scrolling-menu';
// import { FacebookProvider, CommentsCount } from 'react-facebook';
// import HighlightsVideoSlider from './HighlightsVideoSlider';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');
require('./../../../BPdata.js')


let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;


function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}



// const list = [
//   { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  mmshortname: 'ဟိုက်လိုက်',  url : 'HIGHLIGHTS' },
//   { id: 3037, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
//   { id: 3232, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
//   { id: 3102, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'ဆီးရီးအေ', url : 'serie-a' },
//   { id: 3062, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
//   { id: 3054, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
//   { id: 2838, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
//   { id: 2840, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'europa-league' },
//   { id: 2882, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
//   { id: 2840, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
//   { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
// ];

const list = window.BPdata.mainmenu;

// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/highlight/league/${data.id}/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};



// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { shortname,url } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={url}
      data={el}
    />
  );
});

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

export default  class HighlightsLeaguePage extends Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "HIGHLIGHTS";
    
    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      selected: 'OTHERS',
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      league_id: 0,
      leagueMenu: 'OTHERS',
      assets : { id : '0', name: 'Shwe Sports',mmshortname: 'Shwe Sports', icon : 'badge.jpg'}
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;

      this.state.isLoading = true;
      
    }

    if(props.match.params.id) {
      this.state.league_id = props.match.params.id
    }

    if(props.match.params.name) {
      this.state.leagueMenu = props.match.params.name
      this.state.selected = props.match.params.name
    }
  }


  onSelect = key => {
    this.setState({ leagueMenu: key });
    this.setState({ selected: key });
    let temp_data = 1;
    list.forEach(function(row) {
      //console.log(row.url)
      if(key === row.url) {
        temp_data = row.id
      }
    })
    

    this.state.league_id = temp_data
    this.fetchData()
    
  }

  stringPurified(data) {
    let title;
    let data1;
    let data2;
    let data3;

    if (Number(data.split("-").length) > 2) {
        title = data.split("-");
        let t1 = title[0];
        let t2 = title[1];
        let t3 = title[2];
        data1 = t1[0].toUpperCase() + t1.slice(1).toLowerCase();
        data2 = t2[0].toUpperCase() + t2.slice(1).toLowerCase();
        data3 = t3[0].toUpperCase() + t3.slice(1).toLowerCase();
        title = data1+" "+data2+" "+data3
        //console.log(data1+" "+data2)
    } else if(Number(data.split("-").length) === 2) {
      title = data.split("-");
      let t1 = title[0];
      let t2 = title[1];
      data1 = t1[0].toUpperCase() + t1.slice(1).toLowerCase();
      data2 = t2[0].toUpperCase() + t2.slice(1).toLowerCase();
      title = data1+" "+data2
      //console.log(data1+" "+data2)
    }  else {
      title = data[0].toUpperCase() + data.slice(1).replace("-", " ").toLowerCase();
    }
    // console.log( data.slice(1))
    // console.log(data[0].toUpperCase() + data.slice(1).replace("-", " ").toLowerCase())
    return title;
    //return data.replace("-", " ");
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
            if(menu[menu.length - 2] === url ){
                //console.log("first");
                menu.pop();
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
            }
            window.BPdata.preurl =  menu[menu.length-2];
        }
    }
  }

  componentDidMount() {
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
    //this.hisUrl('/highlight/league/'+ this.state.league_id +'/'+ this.state.leagueMenu);
  }

  UNSAFE_componentWillUpdate() {
    //this.hisUrl('/highlight/league/'+ this.state.league_id +'/'+ this.state.leagueMenu);
    //localStorage.setItem('route', '/highlight/league/'+ this.state.league_id +'/'+ this.state.selected);
    //console.log(localStorage.getItem('route'))
    
  }

  fetchData() {
        
        let url = window.BPdata.apiUrl+'/highlight/leagues/'+this.state.league_id;

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data.data;
            
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              assets : resp.data.assets
            });
            

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);
            //console.log(this.pageIndex)
            
        })
        .catch(function(error) {
          // console.log(error.tostring());
        });   
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    //console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(list, selected, leagueMenu);

    
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
      return (
        <Link to={"/highlight/"+obj.id_highlights+"/"+encodeURI(obj.highlights_name)}>
        <div key={rowID} style={{ padding: '0 5px' }}>
          {/* <div
            style={{
              lineHeight: '50px',
              color: '#888',
              fontSize: 18,
              borderBottom: '1px solid #F6F6F6',
            }}
          >{obj.title}</div> */}
          <div className="list-item  list-box-shadow">
                <div className="grids left">
                  <img  className="list-featured-img"   src={obj.highlights_image} alt={obj.highlights_name}  />
                </div>
                <div className="text-left grid-80 left">
                  <div className="row" style={{ lineHeight: 1 }}>
                    <div className="list-featured-p">
                      <b className="color-green">{obj.highlights_name}</b><br/>
                      {moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY')}
                    </div>
                  </div>
                </div>
                <div className="text-right grid-20 right">
                  
                  {/*  <FacebookProvider appId="1967980890125208">
                        <i>
                          <CommentsCount href={window.BPdata.baseUrl+"/highlights/"+obj.id_highlights+"/"+encodeURI(obj.highlights_name)} />
                          <img src="/img/comment.jpg" alt="comment" /> 
                        </i> 
                    </FacebookProvider> */}
                </div>
                   
          </div>
        </div>
        </Link>
      );
    };
    return (
      <div className="page">
        <div className="page-content">
          <div className="main-content">
          <ScrollMenu
                  data={menu}
                  arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                  selected={selected}
                  onSelect={this.onSelect}
                  alignCenter={false}
                />

          <ListView
            ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            renderHeader={() => <div className="list-header bg-white"><Link to={`/news/league/${this.state.league_id}/${this.state.leagueMenu}`} ><img src={`/img/league/${this.state.assets.icon}`} className="list-icon-circle" alt={this.state.leagueMenu} /><span>{(window.BPdata.lang === 'mm')? this.state.assets.mmshortname : this.state.assets.name } </span></Link></div>}
            renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
              {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
            </div>)}
            renderRow={row}
            // renderSeparator={separator}
            className="am-list"
            pageSize={4}
            useBodyScroll
            //onScroll={() => { console.log('scroll'); }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          />

            <div className="ant-back-footer">
                <div className="ant-back-top">
                    <div className="ant-back-top-content">
                        <Link to={window.BPdata.preurl} className="color-green" >
                    { (JSON.parse(localStorage.getItem("route")) !== null) 
                    ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                    </Link> 
                    </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}



