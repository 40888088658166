import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

import swal from 'sweetalert';

class TwoGamePage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "ABOUT";
        this.state = { step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' , twogame : [ {id: 0 ,title : '', max_amount : 0, min_amount: 0, cat_type: 0, game_type: 0}] }};

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        this.fetchWalletData();

    }

    fetchWalletData() {

        alert("ok");
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        let url = window.BPdata.apiUrlCMS+'/player/show?provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        // console.log(url)
        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: { 'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
             },
        })
        .then(resp => {

            if(resp.data.data) {
                this.setState(prevState => ({

                  wallet : resp.data.customer_wallet,

                }))




                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

            } else {

                 swal( window.lang.errors.contact_cs );

            }


          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                    <br />
                    <br />
                        <br />
                    <img src="/img/shwesport.png" style={{  maxHeight : '200px' }}/>
                    <br />
                    <br />
                    <p>
                        <ol>

                            {/*{this.state.twogame.map((item) => {
                                <li key={item.id} name={item.title} >repeated 3 times</li>
                            })}*/}
                        </ol>
					</p>
                    <br />
                    </div>
                </div>
            </div>
        );
    }
}

export default TwoGamePage;
