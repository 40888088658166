import React from 'react';
import axios from 'axios';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
//   const alert = Modal.alert;
var moment = require('moment');

class BetListPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "CONTEST";
        this.state = { 
                        isLoading       : true,
                        message: "", 
                        data : {}, 
                        transfer_type : 1,
                        transfer_type2 : 2,
                        betlist : [{
                            id :  1,
                            title  :  "betPlace",
                            guser_id  :   3,
                            amount :  null,
                            chamount :    null,
                            ticket_id : "",
                            trans_id :    "139684",
                            bamt   :  null,
                            wamt   :  null,
                            payout :  "10.2",
                            odds   :  null,
                            game   :  null,
                            status :  0,
                            mathtype  :   null,
                            comm_perc :   null,
                            comm   :  null,
                            credit_deducted:  null,
                            winloss : null,
                        } ],
                        betresultlist : [{
                            id :  1,
                            title  :  "betPlace",
                            guser_id  :   3,
                            amount :  null,
                            chamount :    null,
                            ticket_id :    "",
                            trans_id :    "139684",
                            bamt   :  null,
                            wamt   :  null,
                            payout :  "10.2",
                            odds   :  null,
                            game   :  null,
                            status :  0,
                            mathtype  :   null,
                            comm_perc :   null,
                            comm   :  null,
                            credit_deducted:  null,
                            winloss : null,
                        } ],
                    step: "betlist",  
                    advertise : {advertise_image:""}, 
                    check: 0,
                    alertId: '', 
                };
        //this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
    }


    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }
    

    fetchData(transfer_type) {

        // let phone = localStorage.getItem(window.BPdata.time)  

        var self = this;
        let dataurl = window.BPdata.apiUrl+'/sportsbook/myticket?place_type='+transfer_type;
        let data = this.state.data;
        
        
        axios({
            method: 'post',
            url: dataurl,
            data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {

            // this.last10trans = response.data.last10trans;

            // console.log(resp.data.last10trans)

            self.setState({
                betlist           : resp.data.data,
                betresultlist     : resp.data.data,
                // withdraw          : resp.data.withdraw,
                isLoading         : false,
            }); 

            // console.log("2-----------------")
            // console.log(this.state.withdraw)

        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   

        // console.log("-----------------")
        // console.log(this.state.withdraw)
        //console.log(this.state.contests);
 
    }


    changeSubMenu(step) {

        let transfer_type = 1;

        if(step == "betlist") {

            transfer_type = 1;
            this.setState({ step : step, transfer_type : 1 });

        } else {

            transfer_type = 2;
            this.setState({ step : step, transfer_type : 2 });

        }
        

        this.fetchData(transfer_type);
        // if(step === 'league') {
        //     if(this.state.league) {
        //         this.setState({ league : false});
        //     } else {
        //         this.setState({ league : true});
        //     }
        // }
        
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  
              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }

    

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "betlist" }); 
          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }

        if(localStorage.getItem('contest_advertise')) {
        
            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        } 

        this.hisUrl('/contest');

        this.fetchData(1);
        // this.apifetchurl();
        
        // this.fetchRamAdsData();
        clearTimeout(window.BPdata.liveurl);
        
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {

        console.log(this.state.step);
        
        switch(this.state.step) {
            case "betlist": return this.displayBlock1();
            case "betresultlist": return this.displayBlock2();
            default:      return "";
          }
    }

   displayBlock1( ) {
        let data = this.state.betlist
        //console.log(data.length)
        if(data.length > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="row">
                            <table className="table  table-border league-table" >
                                <thead>
                                    <tr>
                                        <td>Id</td>
                                        <td>Status</td>
                                        <td>Bet</td>
                                        <td>Odds</td>
                                        <td>Game</td>
                                        <td colSpan="2" >Time</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.childui(0,data)}
                                </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }

    displayBlock2( ) {
        let data = this.state.betresultlist
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="row">
                            <table className="table  table-border league-table" >
                                <thead>
                                    <tr>
                                        <td>Id</td>
                                        <td>Status</td>
                                        <td>Bet</td>
                                        <td>Odds</td>
                                        <td>Game</td>
                                        <td colSpan="2" >Time</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.childui(0,data)}
                                    </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }

    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        
                        //check handicap and change setting

                        ui.push(<tr key={id+""+i}>
                                {/*<td><b>{i+1}</b></td>*/}
                                <td><Link to={"/more/bet-list/"+each.ticket_id} className="color-gold" >{ each.trans_id } </Link></td>
                                <td>{ each.title } </td>
                                <td>{ each.bamt } </td>
                                <td>{ each.odds } </td>
                                <td>{ each.game } </td>
                                <td colSpan="2" className="text-center">
                                    { moment(each.created_at).format("DD-MM HH:mm") }

                                </td>
                                
                            </tr>) 
                        
                }

                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }


    render() {
        return (
            
            <div className="page">
                <div className="page-content text-center">
                    <div className="page-contest text-center">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                        
                        <div class="row">
                            <br /><br />
                            <div class={ (this.state.step == "betlist") ? 'grid-50 left text-center tab-holder active' : 'grid-50 left text-center tab-holder'} onClick={this.changeSubMenu.bind(this,"betlist")} >{window.lang.title.betlist}</div>
                            <div class={ (this.state.step == "betresultlist") ? 'grid-50 right text-center tab-holder active' : 'grid-50 right text-center tab-holder'}  onClick={this.changeSubMenu.bind(this,"betresultlist")}>{window.lang.title.betresultlist}</div>
                        </div>

                        <div className="row text-left">
                        
                            

                            <div className="grid-100 text-center">
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.viewHandler() }
                            <br />

                                
                            </div>
                            

                        </div>

                        
                    </div>
                
                    <br />
                   
                 {/*   <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default BetListPage;