import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';

require('./../../BPdata.js')

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "service";
        this.state = { step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        window.scrollTo(0, 0);

    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">

                <div className="page-content text-center">
                    <div className="main-content">

                        <div className="card row">
                            <div className="el-card__body">
                                <br />
                                <br />
                                <span>
                                    {window.lang.messages.title } <span className="color-theme-gold">ShweBet</span> <br /> {window.lang.messages.title_one}
                                </span>
                                <br />
                                <br />
                                <span>
                                {window.lang.messages.time} <br /> {window.lang.messages.time_one}
                                </span>
                                <br />
                                <br />
                                
                                <div className="adm-card row grid-90">

                                    <div className="adm-card-body grid-100">
                                            <div className="grid-20 left">
                                                <img src="/img/list/viber_white.png" height="30px"/>
                                            </div>
                                            <div className="grid-80 right text-left">
                                            {window.lang.messages.connect}&nbsp;&nbsp;{window.lang.messages.viber}

                                            </div>

                                    </div>
                                    <div className="adm-card-body row">

                                        <div className="grid-90  pl-20 pt-4 pb-4">
                                          <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                        </div>
                                    </div>

                                    <div className="adm-card-body grid-100 pt-4 pb-4">

                                            <div className="grid-100 left text-left pl-20">
                                                {window.lang.messages.connect_one}
                                              <br /><br /><br />
                                            </div>

                                    </div>

                                    <div className="adm-card-body row">
                                            <div className="grid-50 left text-left pl-20">
                                                <a href="viber://contact?number=%2B959881155566" style={{ color: "#fff", fontSize: "15px"}}>
                                                    {/* +95977xxxxx */}
                                                    +959881155566
                                                
                                                </a>
                                            </div>
                                            <div className="grid-40 right color-blue">
                                                <img src="/img/list/viber_group.png" alt="" height="30px"/>

                                            </div>

                                    </div>
                                    <div className="adm-card-body row">
                                            <div className="grid-50 left text-left  pl-20">
                                                <a href="viber://contact?number=%2B959881155577" style={{ color: "#fff", fontSize: "15px"}}>
                                                    {/* +95977xxxxx */}
                                                    +959881155577
                                                </a>
                                            </div>
                                            <div className="grid-40 right color-blue">
                                                <img src="/img/list/viber_group.png" alt="" height="30px"/>

                                            </div>

                                    </div>
                                </div>

                                <div className="grid-100 text-center">
                                    <br />
                                    <a href="https://www.youtube.com/watch?v=CkR34HIjkGo" target="_blank"><span className="color-green">* အကောင့်ဖွင့်နည်း ကြည့်ရန် နှိပ်ပါ။</span> </a> <br />
                                    <a href="https://www.youtube.com/watch?v=SvmCpBQ7n5s" target="_blank"><span className="color-green">* ငွေဖြည့်နည်း ကြည့်ရန် နှိပ်ပါ။</span> </a> <br />
                                    <a href="https://www.youtube.com/watch?v=nhXk-ASi0QM" target="_blank">&nbsp;<span className="color-green">* ငွေထုတ်နည်း ကြည့်ရန် နှိပ်ပါ။</span> </a> <br />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            

            </div>
        );
    }
}

export default ContactUsPage;
