import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import renderHTML from 'react-render-html';
require('./../../../BPdata.js')
var moment = require('moment');


class BillHistoryPage extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            status: 1,
            step: "login", 
            message : "", 
            phone : "",
            contests : 
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            advertise       : {},
            isLoading       : true
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchData() {
        let phone = localStorage.getItem(window.BPdata.time)  

        let dataurl = window.BPdata.apiUrl+'/user/bill-history?phone='+phone;
        
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                contests: resp.data.contests,
                data    : resp.data.data,
                status  : resp.data.status,
                isLoading   : false
            });

        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   

        //console.log(this.state.contests);
 
    }

    
    
    handleInputChange(event) {
        console.log(event.target.value)
        const target = event.target;
        //let value = target.type === 'radio' ? target.checked : target.value;
        let value =  target.value ;
        // let rev_value;
        
        const name = target.name;
        let str = this.state.data;
        
        // // console.log(name)
        // // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            // console.log(first)
            // console.log(id)
            if(first === "team1_value") {
                str[id].team1_value = value;
                //str[id].per_result2 = rev_value;
            }


            if(first === "team2_value") {
                str[id].team2_value = value;
                //str[id].under = rev_value;
            }

            
        }

        this.setState({data:str,phone:  localStorage.getItem(window.BPdata.time) });
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        //console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.contests
        //console.log(data)
        //console.log(data.length)
        //if(data.length  > 0){
            let ui = []
                if(data) {
                    ui.push(<div className="league-box" key={0}>
                            <h2>{data.event_name}</h2>
                            <p>{renderHTML(data.rule_regulation)}</p>
                            <span>Start Time: {moment(data.start_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A')}</span><br/>
                            <span>End Date: {moment(data.expire_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A')}</span><br/>
                            { (this.state.data.length > 0) ? ((this.state.data[0].created_at != null) ? "Post Date: "+moment(this.state.data[0].created_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A') : "")  : ""}
                            {this.childui(this.state.data)}
                    </div>)
                } else {
                    ui.push(<div className="league-box text-center" key={0}><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
                }
                    
                //} 
                
        //    }
        //    if(ui.length === 0) {
        //         ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
        //    }
            return ui
        //}
    }

    numberdropdown(num) {
        const elements = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

        const items = []
        //let num = parseInt(num);
        //console.log(parseInt(num))
        items.push(<option value="-1" disabled>---</option>)
        for (const [index, value] of elements.entries()) {

            if( value === parseInt(num) ) {
                    items.push(<option key={index} value={value} selected="">{value}</option>)
                
            } else {
               
                    items.push(<option key={index} value={value} >{value}</option>)
                
            }
            
        }

        return items;
    }

    leagueSearch(id) {
        let data = "";
        let leagues = window.BPdata.leagues;

        leagues.forEach(function(x) {
            //console.log(x.url == url)
            //return x.id === id
            if(x.id === id) {
                //console.log(x.name)
                return data = x.name;
            } 
           // dict[x.code] = x.name
        })
      
        return data;
    }

    childui(data) {
        let ui = []
            //console.log("-------------"+data)
            
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        ui.push(<table className="table  table-border responsive-table" key={i}>
                        <tbody>
                            <tr>
                                <td colSpan="5" className="text-center">
                                    {moment(each.event_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}<br/>
                                    { this.leagueSearch(each.league_id)}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center grid-5">
                                    <select name={`team1_value-${i}`} onChange={this.handleInputChange}  key={"l"+i} value={each.team1_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team1_value) }
                                    </select>
                                </td>
                                <td  className="text-right grid-35">
                                    {each.team1}
                                </td>
                                <td className="text-center grid-5"> vs </td>
                                <td  className="text-left grid-35">
                                    {each.team2}
                                </td>
                                <td className="text-center grid-5">
                                    <select name={`team2_value-${i}`} onChange={this.handleInputChange} defaultValue={'0'} value={each.team2_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team2_value) }
                                    </select>
                                    
                                </td>
                            </tr>
                            {/* <tr>
                                <td colSpan="3" className="text-right">
                                    <select name={`team1_value-${i}`} onChange={this.handleInputChange}  key={"l"+i} value={each.team1_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team1_value) }
                                    </select>
                                </td>
                                <td className="text-center"></td>
                                <td colSpan="3" className="text-left">
                                    <select name={`team2_value-${i}`} onChange={this.handleInputChange} defaultValue={'0'} value={each.team2_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team2_value) }
                                    </select>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>)
                        
                }
                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }



    //childui


    componentDidMount() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ 
                  step: "profile"
                }); 
            }
        } 

        this.hisUrl('/more/bill-history');
        this.fetchData();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        <div className="row text-left">
                            <br />
                            <span>{this.state.message}</span>
                    
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                            
                        </div>
                        
                    </div>
                    <div className="ant-back-footer">
                        <div class="ant-back-top">
                            <div class="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}

export default BillHistoryPage;