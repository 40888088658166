import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

class WWBETLoginPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "ABOUT";
        this.state = { step: "desktop", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        
        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            // this.setState({
            //     step : "desktop"
            // });

          } else {
             this.props.history.push("/more/login");
             return ;
            }
        } else {
            this.props.history.push("/more/login");
            return ;
        }


        if(localStorage.getItem("appview")) {
            let appview = "";
            if(localStorage.getItem("appview") == "0") {

                window.open(window.BPdata.apiUrl+"/game/wss/login?im=0&token="+localStorage.getItem(window.BPdata.time));

                this.props.history.push("/");
                return ;
                 var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                if (isMobile) {

                    // window.open(window.BPdata.apiUrl+"/game/sportsbook/login?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time));
                    window.open(window.BPdata.apiUrl+"/game/wss/login?im=0&token="+localStorage.getItem(window.BPdata.time));

                    this.props.history.push("/");

                } else {
                    this.setState({
                        step : "desktop"
                    });

                }

            } else {


                // this.setState({
                //     step : "mobile"
                // });

                // window.open(window.BPdata.apiUrl+"/game/sportsbook/login?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time));
                window.open(window.BPdata.apiUrl+"/game/wss/login?im=0&token="+localStorage.getItem(window.BPdata.time));

                this.props.history.push("/");
            }

        } else {
            localStorage.setItem("appview", 0 );
        }
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    // inputChangeHandler(e) {
    //     let form = {...this.state.form};
    //     form[e.target.name] = e.target.value;
    //     this.setState({
    //         form
    //     });
    // }

    desktopBlock() {
        let ui = []

        ui.push(<iframe src={window.BPdata.apiUrl+"/game/wss/login?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth,border:"none"}}  title="" className="wwbetdesktop"></iframe>)

        return ui;
    }

    mobileBlock() {

        let ui = []

        ui.push(<iframe src={window.BPdata.apiUrl+"/game/wss/login?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth,border:"none"}}  title="" className="wwbetmobile"></iframe>)

        // ui.push(
        // <div className="page-content text-center">
        //     <div className="main-content">
        //         <iframe src={window.BPdata.apiUrl+"/game/sportsbook/login?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth-18}} title=""></iframe>
        //     </div>
        // </div>)

        return ui;

    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "desktop":   return this.desktopBlock();
                case "mobile":    return this.mobileBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">
                { this.viewHandler() }
                {/*<iframe src={window.BPdata.apiUrl+"/game/sportsbook/login"}  style={{height:window.innerHeight+10,width:window.innerWidth-18}}  title="Iframe Example"></iframe>*/}
              {/*  <div className="page-content text-center">
                    <div className="main-content">
                    <iframe src="http://shwebet.com/sportsbookapi-mobile.php"  style={{height:window.innerHeight,width:window.innerWidth}}  title="Iframe Example"></iframe>
                    </div>
                </div>*/}
            </div>
        );
    }
}

export default WWBETLoginPage;
