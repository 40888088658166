import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';


const alert = Modal.alert;
var moment = require('moment');
// require('./../../../avator.js')
require("./../../../../src/avator.js");

class MyEditAccPage extends React.Component {

    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "myaccount";
        this.state = { step: "form", message : "", error: [],
            form : { salt : localStorage.getItem(window.BPdata.time), first_name: '', phone: '',email: '', division: 0,profile_photo : '' },
            id:"",date_of_birth:"",username:"",profile_photo :"",phone:"",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:"", editform : false
        };
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
        this.versionSwitch      = this.versionSwitch.bind(this);
        // this.avatorListBlock    = this.avatorListBlock.bind(this);

        this.avatorChange       = this.avatorChange.bind(this);
        this.avatorOnOff        = this.avatorOnOff.bind(this);

        this.sendPlayerEditApi  = this.sendPlayerEditApi.bind(this);
        this.sendPlayerNewEditApi = this.sendPlayerNewEditApi.bind(this);

    }

    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }

    avatorOnOff(){
        let avator_status = 0;
        let step = "form";

        if(this.state.avator_status == 1) {
            avator_status = 0;
            step = "avatorList";

        } else {
            avator_status = 1;
            step = "form";
        }

        this.setState(prevState => ({
            form : {
                ...prevState.form
            },
            avator_status : avator_status,
            step : step
        }));
        // console.log(avator_status)
    }


    avatorChange(profile_photo) {
      // if(step === 'betlist') {
        let form = this.setState(prevState => ({

            form : {
                ...prevState.form,
                profile_photo : profile_photo
            },

        }));
        localStorage.setItem("profile_photo",profile_photo)
        this.sendPlayerNewEditApi(profile_photo);
        this.avatorOnOff();

        // console.log(profile_photo);

    }

    userNameEdit() {
        this.setState({editform : true});
    }
    
    
    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

      fetchUserData() {

            let self = this;
            //localStorage.getItem(window.BPdata.time)
            const url = window.BPdata.apiUrlCMS+'/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
            //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

            ///api/customer?transfer_type=2

            axios({
              method: 'post',
              url: url,
              data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
              headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound,
                    "BP-Time"         : localStorage.getItem(window.BPdata.time)
                },
            })
            .then(resp => {

                  this.setState(prevState => ({
                      form : {
                          ...prevState.form,
                          division: resp.data.data.division,
                          first_name : resp.data.data.first_name,
                          phone : resp.data.data.phone,
                          email : resp.data.data.email,
                          profile_photo : resp.data.data.profile_photo,

                          
                      },
                  }))


              })
              .catch(function(error) {
                // console.log(error);
              });



        }


    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                    this.fetchUserData();
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        // this.fetchUserData();


        this.hisUrl('/more/myaccount');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    // inputChangeHandler(e) {
    //     // this.state.username
    //     // let form = {...this.state.form};
    //     // form[e.target.name] = e.target.value;
    //     this.setState({
    //         username : e.target.value
    //     });
    // }

    versionSwitch() {
        if(localStorage.getItem('appview') === '0') {
            localStorage.setItem('appview', '1');
        } else {
            localStorage.setItem('appview', '0');
        }

        this.props.history.push("/more/myaccount");
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }

        // console.log(window.lang)
        this.props.history.push("/more/myaccount");
    }

    userLogout() {

        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" });
                    this.props.history.push("/");
                }

            },
        ])
    }



    sendPlayerEditApi() {

        let formdata = this.state.form;
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/edit';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            if(response.data.status == 403) {
                self.setState({  message : response.data.message, error : response.data.error, status : response.data.status  });
            } else if(response.data.status == 200) {
                self.setState({  message : "Successfully Submited",  status : response.data.status  });
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" , status : response.data.data.status  });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });

    }

    sendPlayerNewEditApi(form) {

        let formdata = { 'profile_photo' : form };
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/avator/edit';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            if(response.data.status == 403) {
                self.setState({  message : response.data.message, error : response.data.error, status : response.data.status  });
            } else if(response.data.status == 200) {
                self.setState({  message : "Successfully Submited",  status : response.data.status  });
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" , status : response.data.data.status  });
            }
            //console.log(response.data.success);
          
        })
        .catch(function (error) {
            // console.log(error);
        });

    }



    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "form":    return this.formBlock();
                case "avatorList": return this.avatorListBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }

    formBlock() {

        return <div className="row">
        <div className = "edit">
         {(this.state.message) ? this.state.message : ""}
         <div className="row text-left">
           <div className="col-md-12 grid-90 grid-s-90 left material-form">
             <input id="first_name" type="text"  name="first_name" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.first_name} required autoFocus   />
             <span className="highlight"></span>
             <span className="bar"></span>
             <label className="color-gray">Name</label>
           </div>
       </div>



         <div className="form-group row text-left">
             <div className="col-md-12 grid-90 grid-s-90 left material-form">

                 <input id="phone" type="text" className="" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} required autoFocus />
                 <span className="highlight"></span>
                 <span className="bar"></span>
                 <label className="color-gray">Phone</label>
             </div>
         </div>
         <div className="form-group row text-left">
             <div className="col-md-12 grid-90 grid-s-90 left material-form">
                 <input id="email" type="email" className="" name="email" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.email} required autoFocus />
                 <span className="highlight"></span>
                 <span className="bar"></span>
                 <label  className="col-md-4 control-label text-logo-color text-left">Email </label>

             </div>
         </div>

         <div className="form-group row text-left">
             <div className="col-md-12 grid-90 grid-s-90 left">
                 <label  className="col-md-4 control-label text-logo-color text-left">Choose State </label>
                 <select className="form-control" required="required" name="division" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.division}
                 ><option value="0">ပြည်နယ် ရွေးချယ်ရန်</option><option value="1">ရန်ကုန်</option><option value="2">မန္တလေး</option><option value="3">ဧရာဝတီ</option><option value="4">ပဲခူး</option>
                 <option value="5">ချင်း</option><option value="6">ကချင်</option><option value="7">ကယား</option>
                 <option value="8">ကရင်</option><option value="9">မကွေး</option><option value="10">မွန်</option>
                 <option value="11">ရခိုင်</option><option value="12">စစ်ကိုင်း</option>
                 <option value="13">ရှမ်း</option><option value="14">တနသာင်္ရီ</option>
                 </select>
             </div>
         </div>

         <div className="form-group row text-left">
             <div className="col-md-12 grid-100 grid-s-100 left">
                 <br />
                   <a class="btn btn-lg btn-success  grid-90  pointer"  onClick={this.sendPlayerEditApi}>{window.lang.form.submit}</a>
             </div>
         </div>
         </div>
        </div>
    }

    avatorListBlock() {
      return <div>
          <div className="row">
                {
                    window.avator.data.map((item, i) => (
                        <span class="grid center avator-height">
                            <img src={"/img/avator/"+item.profile_photo}  width="50%:" className="pointer"
                            onClick={(e) => this.avatorChange.call(this, item.profile_photo)}
                            />
                        </span>
                    : "" ))
                }
          </div>
      </div>
   }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Web App</p>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }


    render() {
        let profile_photo = localStorage.getItem('profile_photo')
        return (
            <div className="page">
                <div className="page-content" id="repo" >
                <div className="page-contest text-left mt-10 " style={{ paddingLeft: "10px" ,  paddingTop: "30px"}}>
                        <div className="row">
                          <div className="grid-20 left" style={{ marginTop:'20px' }}>

                            
                            { (profile_photo) ? 
                                <img src={"/img/avator/"+profile_photo} height="60px" alt="" onClick={this.avatorOnOff} className="pointer" /> : 
                                <img src={"/img/avator/"+this.state.form.profile_photo}  height="100pt" onClick={this.avatorOnOff} className="pointer"/>
                            } 
                              
                              <img src="/img/avator/edit_icon.png" style={{  }} height="30pt" onClick={this.avatorOnOff}  className="pointer editbtn" />


                              <br /><br />

                          </div>
                          <div className="grid-70 right pl-4">

                          </div>
                        </div>

                      <br /><br /><br /><br />
                      <br />
                </div>
                {this.viewHandler()}


                </div>


            </div>
        );
    }
}

export default MyEditAccPage;


// <div className="form-group row text-left">
//     <div className="col-md-12 grid-90 grid-s-90 left material-group">
//         <label  className="col-md-4 control-label text-logo-color text-left">Name </label>
//         <br />
//         <input id="first_name" type="text" className="css-mnn31" name="first_name" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.first_name} required autoFocus />
//     </div>
// </div>
