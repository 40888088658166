import Countdown from 'react-count-down'
import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { Modal}  from 'antd-mobile';
require('./../../BPdata.js')
var moment = require('moment');

const cb = () => {
    console.log('expired callback')
  }
const alert = Modal.alert;

class BillContestPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            status: 1,
            step: "login", 
            message : "", 
            phone : "",
            contests : 
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            advertise       : {},
            isLoading       : true
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cancelBillContent = this.cancelBillContent.bind(this);
        this.submitBillContent = this.submitBillContent.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
    }

    fetchData() {
        let phone = localStorage.getItem(window.BPdata.time)  

        let dataurl = window.BPdata.apiUrl+'/user/gbill-contest?phone='+phone;
        
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            //console.log(this.pageIndex);
            // this.pageIndex++;
            this.setState({
                contests: resp.data.contests,
                data    : resp.data.data,
                status  : resp.data.status,
                advertise : resp.data.advertise,
                isLoading   : false
            });

        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   

        //console.log(this.state.contests);
        window.BPdata.liveurl =  setTimeout(() => this.fetchData(), 900000);
    }

    sendBillContestApi() {
        
        if(localStorage.getItem(window.BPdata.time) !== 0) {
            this.setState({
                phone:  localStorage.getItem(window.BPdata.time)  
            })
            var self = this;
            let dataurl = window.BPdata.apiUrl+'/user/billcontest';
            let contests = this.state;
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(function (response) {
                if(response.data.success) {
                    self.setState({  
                        message : "Successfully Submited", 
                        status  : 1
                    });
                    //self.setState({  message : "Successfully updated" });
                } else {
                    self.setState({  
                        message : "Something was wrong",
                     });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
        
    }
    
    handleInputChange(event) {
        // console.log(event.target.value)
        const target = event.target;
        //let value = target.type === 'radio' ? target.checked : target.value;
        let value =  target.value ;
        // let rev_value;
        
        const name = target.name;
        let str = this.state.data;
        
        // // console.log(name)
        // // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            // console.log(first)
            // console.log(id)
            if(first === "team1_value") {
                str[id].team1_value = value;
                //str[id].per_result2 = rev_value;
            }


            if(first === "team2_value") {
                str[id].team2_value = value;
                //str[id].under = rev_value;
            }

            
        }

        this.setState({data:str,phone:  localStorage.getItem(window.BPdata.time) });
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        //console.log(this.state.contests)
    }

    displayBlock( ) {
        
        let data = this.state.contests
        //console.log(data)
        //console.log(data.length)
        //if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
         //   for (let i = 0; i < data.length; i++) { 
//                let each = data[i]
                //let each = data
                //console.log(each+"---------head")
                //if(each.data.length > 0) {
                //console.log(this.state.data[0]+"--------------")
                if(data) {
                    // const OPTIONS = {
                    //     endDate: '03/01/2020 10:55 AM','03/01/2020 10:55 AM'
                    //     prefix: '',
                    //     cb
                    //   }2019-07-10 23:30:00
                    let expiredate = moment(data.expire_date, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY hh:mm A');
                    const OPTIONS = {
                        endDate: expiredate,
                        prefix: '',
                        cb
                      }

                    ui.push(<div className="league-box" key={0}>
                            <h1>{data.event_name}</h1>
                            <p>{renderHTML(data.rule_regulation)}</p>
                            <span>Start Time: {moment(data.start_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A')}</span><br/>
                            <span>End Date: {moment(data.expire_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A')}</span><br/>
                            { (this.state.data.length > 0) ? ((this.state.data[0].created_at == null) ? <div className="countdown-block" >
                                <Countdown options={OPTIONS} /></div>
                            : "")  : ""}
                            
                            
                            { (this.state.data.length > 0) ? ((this.state.data[0].created_at != null) ? "Post Date: "+moment(this.state.data[0].created_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY, hh:mm A') : "")  : ""}
                            {this.childui(this.state.data)}
                    </div>)
                } else {
                    ui.push(<div className="league-box text-center" key={0}><h2>No Event Found</h2></div>)
                }
                    
                //} 
                
        //    }
        //    if(ui.length === 0) {
        //         ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
        //    }
            return ui
        //}
    }

    numberdropdown(num) {
        const elements = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

        const items = []
        //let num = parseInt(num);
        //console.log(parseInt(num))
        items.push(<option value="-1" disabled key="-1">---</option>)
        for (const [index, value] of elements.entries()) {

            if( value === parseInt(num) ) {
                    items.push(<option key={index} value={value} selected="">{value}</option>)
                
            } else {
               
                    items.push(<option key={index} value={value} >{value}</option>)
                
            }
            
        }

        return items;
    }

    leagueSearch(id) {
        let data = "";
        let leagues = window.BPdata.leagues;

        leagues.forEach(function(x) {
            //console.log(x.url == url)
            //return x.id === id
            if(x.id === id) {
                //console.log(x.name)
                return data = x.name;
            } 
           // dict[x.code] = x.name
        })
      
        return data;

        // let result = leagues.filter(function(x) {
        //     //console.log(x.url == url)
        //     //return x.url === url;
        //     if(x.id === id) {
        //         return x.name;
        //     } 
            
        // })
        // return result;
    }

    childui(data) {
        let ui = []
            //console.log("-------------"+data)
            
        
            if(Array.isArray(data) === false) {
                console.log(data)
                console.log(this.state.contests)
            } else {

                let arlength = data.length;
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        ui.push(<table className="table  table-border responsive-table" key={i}>
                        <tbody>
                            <tr>
                                <td colSpan="5" className="text-center">
                                    <b>{moment(each.event_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}</b><br/>
                                    { each.name }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center grid-5">
                                    <select name={`team1_value-${i}`} onChange={this.handleInputChange}  key={"l"+i} value={each.team1_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team1_value) }
                                    </select>
                                </td>
                                <td  className="text-right grid-35">
                                    {each.team1}
                                </td>
                                <td className="text-center grid-5"> vs </td>
                                <td  className="text-left grid-35">
                                    {each.team2}
                                </td>
                                <td className="text-center grid-5">
                                    <select name={`team2_value-${i}`} onChange={this.handleInputChange} defaultValue={'0'} value={each.team2_value} disabled={ (this.state.status) ? "disabled" : ""}>
                                        { this.numberdropdown(each.team2_value) }
                                    </select>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>)
                        
                }
                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }

    cancelBillContent() {
        this.fetchData();
    }

    submitBillContent() {
        //console.log(this.state.data)
        let data = window.confirm("Are you sure want to submit this data ?");
        if(data){
            this.sendBillContestApi();
        }
        
    }
    //childui


    componentDidMount() {
        clearTimeout(window.BPdata.liveurl)
        
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                this.setState({ 
                    step: "profile"
                }); 
            } else {
                this.props.history.push("/more/sign-up");
                }
        } else {
            this.props.history.push("/more/sign-up");
        }
        
        this.hisUrl('/more/bill-contest');
        this.fetchData();
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
        
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        <div className="row text-left">
                            <br/>
                                <div className="list-header">
                                    <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.title.bill_contest}       /><span>{window.lang.title.bill_contest}    </span>
                                </div>   
                            <br />
                            <span>{this.state.message}</span>
                    
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                            {
                                (this.state.advertise) ? <div className="row">
                                <div className="grid">
                                    <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.data.news_name}/><br/><br/>
                                </div>
                                </div>: ""
                            }
                        </div>
                        <div className="row">
                                {(this.state.status)?"":
                                <div className="text-center"><button className="btn btn-success" onClick={() =>
                                    alert('Submit', window.lang.messages.que_submit, [
                                    { text: window.lang.form.cancel, onPress: () => console.log('cancel') },
                                    { text: window.lang.form.submit, onPress: () => this.sendBillContestApi()  },
                                    ])
                                }>{window.lang.form.submit}</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>{window.lang.form.cancel}</button></div>
                                }
                                {/* {(this.state.status)?"":<div className="text-center"><button className="btn btn-success" onClick={this.submitBillContent}>Submit</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>Cancel</button></div> } */}
                        </div>
                    </div>
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}

export default BillContestPage;