/* eslint no-dupe-keys: 0 */
import React from 'react';
// import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import { Grid } from 'antd-mobile';
import AppHeader from './../AppHeader';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}



class ChangePasswordPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = { step: "feedback", message : "", form : {time: '', old_password: '', new_password : '', re_new_password: '' }};
        //this.state = { step: "register", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
    }

    validatePhone() {
        let data
        //console.log(this.state.form.phone.length)
        if( this.state.form.title !== "" &&  this.state.form.description !== "") {
            data = {
                old_password    : this.state.form.old_password,
                new_password    : this.state.form.new_password,
                re_new_password : this.state.form.re_new_password,
                time            : this.state.form.time
            }
            this.setState({  message : "Sending Data" });
            this.sendFeedbackApi(data);

        } else {
            //console.log(this.state.message)
            this.setState({  message : "Please fill up required field." });
        }

    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    checklogin (){
          if(localStorage.getItem(window.BPdata.time)) {
              if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                  return true;
              }
          }
          return false;
    }

    sendFeedbackApi(formdata) {
        var self = this;
        const dataurl = window.BPdata.apiUrlCMS+"/player/changepassword";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data.data.status == 403) {

                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        old_password: '',
                        new_password: '',
                        re_new_password : '',
                        // bank_type : 1
                    },
                    message : resp.data.data.message
                }))

                // console.log(this.state.form)

                // self.setState({  form : {title: '', description: '' } , message : "အကြံပြုချက် အတွက်ကျေးဇူးတင်ပါသည်။"});
            } else if(resp.data.data.status == 200) {


                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        old_password: '',
                        new_password: '',
                        re_new_password : '',
                        // bank_type : 1
                    },
                    message : "အောင်မြင်သွားပါပြီ။"
                }))


                // localStorage.setItem(window.BPdata.time, response.data.data.token );
                // localStorage.setItem('username', response.data.data.username );
                // localStorage.setItem('reset', 1);
                // self.props.history.push("/");
            } else {

                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        old_password: '',
                        new_password: '',
                        re_new_password : '',
                        // bank_type : 1
                    },
                    message : "တစ်ခုခု မှားယွင်းနေပါသည်။"
                }))

            }

         })
          .catch(function(error) {
            // console.log(error);
          });
    }


    feedbackBlock() {
        return <div className="row">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">

                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left text-right">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-left"  value={this.state.form.old_password} name="old_password" onChange={(e) => this.inputChangeHandler.call(this, e)}  placeholder={window.lang.form.old_password} />

                            <br />
                            <input type="text" className="form-control text-left"  value={this.state.form.new_password} name="new_password" onChange={(e) => this.inputChangeHandler.call(this, e)}  placeholder={window.lang.form.new_password}  />

                            <br />

                            <input type="text" className="form-control text-left"  value={this.state.form.re_new_password} name="re_new_password" onChange={(e) => this.inputChangeHandler.call(this, e)} placeholder={window.lang.form.re_new_password}  />

                            <br />

                            <br/>
                            </div>
                            <input type="submit" className="btn btn-yellow grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>
        </div>
    }




    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                //localStorage.setItem(window.BPdata.time, true);
                console.log("ok");
            } else {
                this.props.history.push("/more/sign-up");
                }
        } else {
            this.props.history.push("/more/sign-up");
        }


        // if(localStorage.getItem('lang') === 'mm') {
        //     window.BPdata.title = "အကြံပေးခြင်း";
        // } else {
        //     window.BPdata.title = "Feedback";
        // }



        // if(localStorage.getItem(window.BPdata.time)) {
        //     if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //       this.setState({ step: "feedback" });
        //     }

        //   }

        if(localStorage.getItem('username')) {

                this.setState(prevState => ({
                    form : {
                        ...prevState.form,
                        time : localStorage.getItem(window.BPdata.time)
                    }
                }))

                // this.setState({
                //     form: {
                //       time : localStorage.getItem(window.BPdata.time)
                //     }
                // });
        }

        // <img src="/img/back-arrow.png" alt="back" />

        this.hisUrl('/more/feedback');
    }




    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );

                }
                //window.BPdata.preurl =  menu[menu.length-1];
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }


    render() {
        return (
            <div className="page">
                <div className="page-content">

                    <div className="main-content text-left  page-contest">
                        <div className="row text-center ">
                            <div className="grid-100">
                            <br />
                            <h2 className="color-theme-gold" style={{ fontSize: "24px"}}>
                                { window.lang.more.changepassword }
                            </h2>
                            <p>
                            </p>
                            <span>{this.state.message}</span>
                            </div>
                        </div>

                        <br />
                        <br />

                        { this.feedbackBlock() }

                    </div>
                </div>


            </div>
        );
    }
}

export default ChangePasswordPage;
