import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
import {
    Link
  } from 'react-router-dom';
//import { FacebookProvider, Comments } from 'react-facebook';
import axios from 'axios';
import renderHTML from 'react-render-html';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import CommentList from "./../../parts/CommentList.jsx";
import CommentForm from "./../../parts/CommentForm";

var moment = require('moment');

let NUM_ROWS = 20;
  



class BetListDetailPage extends Component {
    constructor(props) {
      super(props);
  
      window.BPdata.footerMenu = "NEWS";
      this.state = {
        data : {
            title : '',
            body : '',
            featured_img: ''
        },
        latest_data : [],
        isLoading: true,
        post_id : 1,
        leagueMenu: 'NEWS',
        menu : window.BPdata.mainmenu,
        comments: [],
        comment : null,
        advertise: {},
        loading: true,
        liked : 0,
        fetch : 0,
        order_items:[{
            "id":1,
            "contest_id":0,
            "orders_id":1,
            "product_id":"1",
            "sku":"00","product_type":"1","provider_type":"1","name":"00","coupon_code":null,"weight":"0","qty_ordered":"1","discount_percent":null,"price":"300.00","discount_amount":null,"total":"300.00","item_data":null,"message":"","payment_transaction_id":null,"package_id":null,"provider_transaction_id":null,"tracking_id":null,"tracking_status":null,"informed":0,"commission":0,"created_at":"2021-12-14T08:12:59.000000Z","updated_at":"2021-12-14T08:12:59.000000Z"
          }],
        detail : {
            id      :"OU1269962895796",
            sDate   :"2022-03-22T00:00:00",
            tDate   :"2022-03-22T17:18:19.55",
            kDate   :"2022-03-22T16:30:00",
            aDate   :"2022-03-22T17:19:10.747",
            pDate   :"2022-03-22T18:32:38.883",
            user    :"AAYSB3",
            sport   :"Football",
            league  :"Australia Cup Qualifiers",
            home    :"Kingston City",
            away    :"Pascoe Vale",
            sport_en    :"Football",
            league_en   :"Australia Cup Qualifiers",
            home_en :"Kingston City",
            away_en :"Pascoe Vale",
            live    :"True",
            homeScore   :"0",
            awayScore   :"0",
            status  :"0",
            ft  :"f",
            oddsType    :"MO",
            game    :"OU",
            info    :"2 (-87)",
            odds    :"0.90",
            side    :"u",
            side_en :null,
            othersgame  :null,
            othersgame_en   :null,
            bamt    :"200.0000",
            wamt    :"180.0000",
            tresult :"1",
            fhscore :"0-0",
            ftscore :"1-0",
            fg  :"h",
            lg  :"h",
            ip  :"45.125.4.76-MM",
            isMobile    : 0,
            commission  : "0.0000",

        }
      };

      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

      // this.addComment = this.addComment.bind(this);

    }

    // addComment(comment) {

    //   //this.props.history.push("/forum/15");
    //   this.setState({
    //     loading: false,
    //     // comments: [ comment, ...this.state.comments],
    //     fetch : 1
    //     //comments: [...this.state.comments,comment],
    //     //comments: [this.state.comments],
    //     // comment : comment
    //   });
    //   this.fetchCommentData();
    // }
  
    UNSAFE_componentWillMount() {
      window.BPdata.lang = localStorage.getItem("lang");
    }

    letterFormat(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            // this.setState({ step: "form" }); 
            
          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }
        
        this.fetchData();
        // this.fetchLatestData();
        
        let self = this;
        
        //console.log(localStorage.getItem('route'))
        // loading
        this.setState({ loading: true });

        this.hisUrl('/more/bet-list/'+this.state.post_id)
        clearTimeout(window.BPdata.liveurl);
    }

 

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {
      
    }

    fetchData() {
        
        let self = this;

   

          let dataurl = window.BPdata.apiUrl+'/sportsbook/ticket-detail/'+this.state.post_id;
          // let dataurl = window.BPdata.apiUrl+'/service/bet-list/detail/'+this.state.post_id;
          // let dataurl = window.BPdata.apiUrl+'/news/detail/'+this.state.post_id;

          axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(resp => {

              if(resp.data.data) {
                    self.setState({
                        detail           : resp.data.data[0],
                        // withdraw          : resp.data.withdraw,
                        isLoading         : false,
                    }); 
                } else {
                    self.setState({
                        isLoading         : true,
                    }); 
                }

                // console.log(this.state.detail.id)
          })
          .catch(function(error) {
            //console.log(error);
            //console.log(error.tostring());
          });      
 
    }

    // fetchLatestData() {
        
    //   if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //     let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //     this.setState({
    //       latest_data: video_slides
    //     });
    //   }
    // }

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.data  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                        <br />
                        <h2 className="color-red">{this.state.detail.id}</h2><br />
                        <small>{moment.utc(this.state.data.created_at).local().format('DD MMM YYYY, hh:mm A') } </small><br/>
                        <br />
                        
                        <table className="table  table-border league-table1" >
                            <thead>
                                <tr>
                                    <th className="text-left"><b>Title</b></th>
                                    <th className="text-left"><b>Information</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                
{/*
                                id      :"OU1269962895796",
                                sDate   :"2022-03-22T00:00:00",
                                tDate   :"2022-03-22T17:18:19.55",
                                kDate   :"2022-03-22T16:30:00",
                                aDate   :"2022-03-22T17:19:10.747",
                                pDate   :"2022-03-22T18:32:38.883",
                                sport   :"Football",
                                league  :"Australia Cup Qualifiers",
                                home    :"Kingston City",
                                away    :"Pascoe Vale",
                                live    :"True",
                                homeScore   :"0",
                                awayScore   :"0",
                                status  :"0",
                                ft  :"f",
                                oddsType    :"MO",
                                game    :"OU",
                                info    :"2 (-87)",
                                odds    :"0.90",
                                side    :"u",
                                othersgame  :null,
                                bamt    :"200.0000",
                                wamt    :"180.0000",
                                tresult :"1",
                                fhscore :"0-0",
                                ftscore :"1-0",
                                fg  :"h",
                                lg  :"h",
                                commission  : "0.0000",*/}
                                {this.childui(0,this.state.detail)}

                             {/*   <tr>
                                    <td  className={"text-center pointer "}  > 
                                        { this.state.detail.id } 
                                    </td>
                                    <td colSpan="1" className="text-right">

                                        { this.state.detail.sport } 

                                    </td>
                                    
                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-right">Commission Total (?)</td>
                                    <td className="text-right">{this.state.data.commission}</td>
                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-right">Total</td>
                                    <td className="text-right">{this.state.data.grand_total}</td>
                                    <td></td>
                                </tr>*/}

                            </tbody>
                        </table>
                    </div> 
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }

    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
                for (var each in this.state.detail) {
                    ui.push(<tr key={""+each}>
                        <td  className={"text-left pointer "}  > 
                            { this.letterFormat(each) } 
                        </td>
                        <td colSpan="1" className="text-left">

                            { this.state.detail[each] } 

                        </td>
                        
                    </tr>) 
                    // console.log(each,":",this.state.detail[each]);
                }

            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    let i = 0;

                    for (var each in this.state.detail) {
                        ui.push(<tr key={id+""+i}>
                            <td  className={"text-center pointer "}  > 
                                { each } 
                            </td>
                            <td colSpan="1" className="text-left">

                                { this.state.detail[each] } 

                            </td>
                            
                        </tr>) 
                        // console.log(each,":",this.state.detail[each]);
                    }

                    // this.state.detail.forEach(function(each){
                    // // for (let i = 0; i < arlength; i++) { 
                    // //     let each = data[i]
                    //     i++;
                    //     //check handicap and change setting

                    //     ui.push(<tr key={id+""+i}>
                    //                 <td  className={"text-center pointer "}  > 
                    //                     { each.id } 
                    //                 </td>
                    //                 <td colSpan="1" className="text-right">

                    //                     { each } 

                    //                 </td>
                                    
                    //             </tr>) 
                        
                    // })

                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }
  
    
    render() {
        const { selected } = this.state;
        let leagueMenu = this.state.leagueMenu;
        // Create menu from items
        // const menu = Menu(this.state.menu, selected, leagueMenu);
        // const loadingSpin = this.state.loading ? "App-logo Spin" : "App-logo";

        return (
            <div className="page">
                <div className="page-content">
                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                    
                       
                          

                   {/*     <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-dark-red" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>*/}
                        
                </div>
            </div>
            );
        }
      }                                                                                         
      
export default BetListDetailPage;
