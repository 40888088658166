import React from 'react';

import './Message.css';

import ReactEmoji from 'react-emoji';



const Message = ({ parentCallback, sendBackData, message: { text, user, action }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if(user === trimmedName) {
    isSentByCurrentUser = true;
  }

  sendBackData = (text) => {
    parentCallback(text);
  }
  
  // const sendMessage = (event) => {
  //   event.preventDefault();
  //   let message = "hello";
  //   if(message) {
  //     socket.emit('sendMessage', message, () => setMessage(''));
  //   }
  // }
  

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <p className="sentText pr-10">{trimmedName}</p>
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{ReactEmoji.emojify(text)}</p>
            {/* <b>{action}</b> */}
          </div>
        </div>
        )
        : (
          <div className="messageContainer justifyStart">
            
              {(action) ? <div className="messageBox backgroundButton"><button className="messageText colorDark btn-small"  value={text} onClick={ event => sendBackData(text)}>{ReactEmoji.emojify(text)}</button> </div>: <div className="messageBox backgroundLight"><p className="messageText colorDark">{ReactEmoji.emojify(text)}</p></div> }            
              {/* <b>{action}</b> */}
            
            <p className="sentText pl-10 ">{user}</p>
          </div>
        )
  );
}

export default Message;