let rand_one = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let rand_two = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let salt = "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc";
// hlvixpdbur27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zcdzivymys
let xtoken = window.btoa(encodeURIComponent( rand_one + salt  + rand_two ));
let rand_compound = window.btoa(encodeURIComponent( rand_one + "!" + rand_two ));
window.BPdata =
    {
        time : "sb-time",
        rand_compound:rand_compound,

        data :
            {
                path: '/',
                component: 'Home',
            },
        footerMenu : "News",
        baseUrl : 'https://shwebet.trident-crm.com/',
        provider_id : "5",
        //https://shwebet.trident-crm.com/
        //baseUrl : 'https://shwebet.trident-crm.com/BPdata/',
        // apiUrl  : 'http://http://d agon-crm.local/api/ver1',
        // apiUrl  : 'http://localhost/tipster-app/dashboard/api/ver1',
        apiUrl  : 'https://shwebet.trident-crm.com/api',
        apiUrlCMS  : 'https://shwebet.trident-crm.com/api/v1/cms',
        token : "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc",
        xtoken:xtoken,
        rand_one : rand_one,
        rand_two : rand_two,
        dashboardUrl : 'https://shwebet.trident-crm.com/',
        apiAssetUrl : 'https://shwebet.trident-crm.com/uploads',
        popup   : true,
        leagues : [{"id":"2882","country":"Asia","name":"World Cup - Qualification - Third Stage"},{"id":"3031","country":"England","name":"England: Fa Cup"},{"id":"3037","country":"England","name":"England: Premier League"},{"id":"3038","country":"England","name":"England: Championship"},{"id":"3368","country":"World","name":"World: Friendly International"},{"id":"3232","country":"Spain","name":"Spain: Laliga"},{"id":"3230","country":"Spain","name":"Spain: Copa Del Rey"},{"id":"2840","country":"Europe","name":"Europe: Europa League - Play Offs"},{"id":"2838","country":"Europe","name":"Europe: Champions League"},{"id":"3364","country":"Europe","name":"Europe: Euro - Qualification"},{"id":"3054","country":"France","name":"France: Ligue 1"},{"id":"3050","country":"France","name":"France: Ligue 1 - Relegation"},{"id":"3102","country":"Italy","name":"Itlaian Serie A"},{"id":"3062","country":"German","name":"German Bundesliga"},{"id":"2889","country":"Europe","name":"World: World Cup"},{"id":"3631","country":"World","name":"World: World Cup U20"},{"id":"3729","country":"World","name":"World: World Cup Women"},{"id":"2872","country":"southamerica","name":"South America: Copa Am\u00e9rica"}],
        route : "[\"/\"]",
        preurl: "/",
        liveurl: "",
        mainmenu :[
            { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
            { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
            { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'serie-a' },
            { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
            { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
            { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
            { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ယူရိုပါလိဂ်', url : 'europa-league' },
            { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
            { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
            { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
          ],
        lang : "mm"
    }
;


window.enlang =
{
    more : {
        my_account          : 'My Account',
        live_channel1       : 'Live Channel 1',
        live_channel2       : 'Live Channel 2',
        live_channel3       : 'Live Channel 3',
        channelsschedule    : 'Channels Schedule',
        gem_store           : 'Gem Store',
        term_and_conditions : 'Terms & Conditions',
        announcement        : 'Announcement',
        feedback            : 'Feedback',
        faq                 : 'FAQ',
        video               : 'Skill Videos',
        help_center         : 'Help Center',
        wallet_store        : 'Wallet',
        share_app           : 'Share App',
        download_app        : 'Download App',
        term_condition      : "Terms & Conditions",
        promotion           : 'Promotion',
        contact_us          : 'Contact Us',
        statement           : 'Statement',
        joker               : 'Joker',
        Haba               : 'Haba',
        WSS               : 'WSS',
        SBO               : 'SBO',
        SEXY               : 'SEXY',
        version_check       : 'Version Update Check',
        changepassword      : 'Change Password',
    },
    title : {
        latest_euro_news      : 'LATEST EURO NEWS',
        latest_news           : 'LATEST NEWS',
        latest_highlights     : 'LATEST HIGHLIGHTS',
        league_contest  : 'League Contest',
        bill_contest    : 'Bill Contest',
        tipsters        : 'Tipsters',
        tip             : 'Tips',
        league_history  : 'League History',
        bill_history    : 'Bill History',
        transaction     : 'Transaction',
        contest         : 'Contest',
        leaderboard     : 'Leaderboard',
        history         : 'History',
        euro_news       : 'Euro News',
        euro_standing   : 'Euro Standing',
        euro_team       : 'Euro Team',
        payment         : 'Payment',
        order           : 'Order',
        forum           : 'Forum',
        inbox           : 'Inbox',
        transfer        : 'transfer',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        betlist         : 'Bet List',
        betresultlist   : 'Bet Result List',
        top_up          : 'Top Up',
        payment_aval    : 'Payment Method',
        game            : 'Game Services',
        slot_game       : 'Slot Game Services',
        withdrawal_history      : 'Withdraw',
        top_up_history         : 'Top Up',
        main_wallet     : 'Main Wallet',
        game_wallet     : 'Game Wallet',
        commission_wallet     : 'Commission Wallet',
    },
    form : {
        user            : 'User',
        id              : 'ID',
        gems            : 'Gems',
        point           : 'Point',
        hightest_point  : 'Highest Point',
        rank            : 'Rank',
        date_of_birth   : 'Date of Birth',
        join_date       : 'Join Date',
        language        : 'Language',
        appview         : 'App View',
        gameview        : 'Game View',
        all             : "All",
        sports          : "sports",
        casino          : "casino",
        Slot            : "Slot",
        Fishing         : "Fishing",
        login           : 'User Login',
        already_have_account : 'Already have a account',
        register        : 'User Register',
        password_reset  : 'Password Reset',
        verify_code     : 'Verify Code',
        logout          : 'Logout',
        title           : 'Title',
        description     : 'Description',
        name            : 'Name',
        phone           : 'Phone',
        email           : 'email',
        password        : 'Password',
        forgot_password : 'Forgot email?',
        submit          : 'Submit',
        next            : 'Next',
        accept          : 'Accept',
        cancel          : 'Cancel',
        new_post        : 'New Post',
        comment         : 'Comment',
        que             : {
            confirm     : 'Confirm Box',
            delete      : 'Are you sure to delete?'
        },
        footer_login_check : 'My Profile',
        footer_login_check_lang :'Login / Register'
    },
    packages : {
        oneday          : 'One Day',
        oneweek         : 'One Week',
        onemonth        : 'One Month',
        buy             : 'Buy',
    },
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'Home', check: 'home', url : "/" },
            {icon: '/img/footer/pwallet.png',icon_off: '/img/footer/pwallet.png',text: 'My Wallet', check: 'wallet', url : "/more/wallet-store" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'Promotions', check: 'promotions', url : "/more/announcements" },
            // {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'Services', check: 'service', url : "/contact-us" },
            // {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'My Profile', check: 'myaccount', url : "/more/myaccount" },
          ],
    homeicongame : [{ id: 0, icon: '/img/game_cat_1.png',icon_off: '/img/game_cat_1.png',text: 'All', second_text: 'All Game', check: 'All', url : "/more/announcements"},
                    { id: 1, icon: '/img/game_cat_2.png',icon_off: '/img/game_cat_2.png',text: 'Sports', second_text: 'Sport Game', check: 'Sports', url : "/more/wallet-store" },
                    { id: 2,icon: '/img/game_cat_3.png',icon_off: '/img/game_cat_3.png',text: 'Casino', second_text: 'Casino Game', check: 'Casino', url : "/contact-us" },
                    { id: 3,icon: '/img/game_cat_4.png',icon_off: '/img/game_cat_4.png',text: 'Slot', second_text: 'Slot Game', check: 'Slot', url : "/more" },
                    { id: 4,icon: '/img/game_cat_5.png',icon_off: '/img/game_cat_5.png',text: 'Fishing', second_text: 'Fishing Game', check: 'Fishing', url : "/more" },
        ],
    homeiconmiddle : [{icon: '/img/bank/‌aya_logo.png',icon_off: '/img/bank/‌aya_logo.png',text: 'AYA', check: 'Football', url : "/more/announcements"},
            {icon: '/img/bank/cb_logo.png',icon_off: '/img/bank/cb_logo.png',text: 'CB', check: 'Ball', url : "/more/wallet-store" },
            {icon: '/img/bank/kbz_logo.png',icon_off: '/img/bank/kbz_logo.png',text: 'KBZ', check: 'Basket Ball', url : "/contact-us" },
            {icon: '/img/bank/kpay_logo.png',icon_off: '/img/bank/kpay_logo.png',text: 'K Pay', check: 'Tennis', url : "/more" },
            {icon: '/img/bank/wave_logo.png',icon_off: '/img/bank/wave_logo.png',text: 'Wave Money', check: 'Tennis', url : "/more" },
              ],
      homeiconlist : [{icon: '/img/homeicon/sport1.png',icon_off: '/img/homeicon/sport1.png',text: 'WWBET', check: 'NEWS', url : "/wwbet-login"},
            {icon: '/img/homeicon/sport2.png',icon_off: '/img/homeicon/sport2.png',text: 'Sexy Gaming', check: 'TIP&ODDS', url : "/ae-login" },
            {icon: '/img/homeicon/sport3.png',icon_off: '/img/homeicon/sport3.png',text: 'HABANERO', check: '/habanero-login', url : "/haba-login" },
            {icon: '/img/homeicon/sport4.png',icon_off: '/img/homeicon/sport4.png',text: 'Joker', check: '/joker-login', url : "/joker-login" }
            // {icon: '/img/homeicon/sport2.png',icon_off: '/img/homeicon/sport2.png',text: 'Profile', check: 'Profile', url : "/about"},
            // {icon: '/img/homeicon/home-block-cockfight.jpg',icon_off: '/img/homeicon/home-block-cockfight.jpg',text: 'ကစားပွဲများ', check: 'ABOUT', url : "/about" },

              ],
    messages : {
        title : "The Following phone numbers are",
        title_one : "company's service phone numbers",
        company : "ShweBet",
        viber: "Viber",
        connect : "Connect with",
        connect_one : "Deposit and Withdrawal / Service Phone Number",
        time: "(7:30 AM to 12:00 PM ) Every Day",
        time_one : "without any holidays",
        que_submit      : "Are you sure want to submit?",
        invalid_phone   : "Invalid phone number",
        welcome_message : "Welcome to Shwebet",
        sending         : "Sending",
        somethingwrong  : "Something was wrong",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
        amount          : 'Amount',
        view_all        : 'Topup / Withdraw Record',
        recent_transaction : 'Recent Transaction',
        top_up              : 'Top Up',
        withdrawal          : 'Withdrawal',
    },
    errors : {
        contact_cs      : "The session has expired!!!",
        check_bank_acc  : "Please check your Bank Account",
        w_request_processing : "Your withdrawal is being processed. Please wait.",
        check_amount    : "Please check your Amount",
    },
    moneyamountlist :  [
        { text: '5,000', num: '5000'},
        { text: '10,000',  num: '10000'},
        { text: '50,000',  num: '50000'},
        { text: '100,000',  num: '100000'},
        { text: '200,000',  num: '200000'},
        { text: '500,000',  num: '500000'},
    ],
    promotionlist :  [
        { id: 0, text: 'All', num: 'All'},
        { id: 1, text: 'Daily',  num: 'Daily'},
        { id: 2, text: 'Weekly',  num: 'Weekly'},
        { id: 3, text: 'Monthly',  num: 'Monthly'},
        { id: 4, text: 'New Member',  num: 'New Member'},
        { id: 5, text: 'Friend Refer',  num: 'Friend Refer'},
    ],
};

window.mmlang =
{
    more : {
        my_account          : 'ကိုယ်ပိုင်အကောင့်',
        live_channel1       : 'တိုက်ရိုက်ချန်နယ် ၁',
        live_channel2       : 'တိုက်ရိုက်ချန်နယ် ၂',
        live_channel3       : 'တိုက်ရိုက်ချန်နယ် ၃',
        channelsschedule    : 'ချန်နယ်အချိန်ဇယား',
        gem_store           : 'Gem ဆိုင်',
        term_and_conditions : 'စည်းကမ်းချက်များ',
        announcement        : 'အသိပေးကြေညာချက်',
        feedback            : 'အကြံပေးခြင်း ',
        video               : 'ဗွီဒီယိုများ',
        faq                 : 'FAQ',
        wallet_store        : 'ပိုက်ဆံအိတ်',
        share_app           : 'မျှဝေမည်။',
        download_app        : 'ဒေါင်းလုဒ်လုပ်ပါ။',
        term_condition      : "စည်းမျဉ်းစည်းကမ်းချက်",
        promotion           : 'ပရိုမိုးရှင်း',
        contact_us          : 'ဆက်သွယ်ရန်',
        statement           : 'Statement',
        version_check       : 'Version Update Check',
        changepassword      : 'လျှို့ဝှက် နံပတ်ပြောင်းရန်',
    },
    title : {
        latest_euro_news        : 'နောက်ဆုံးရ ယူရိုသတင်းများ',
        latest_news             : 'နောက်ဆုံးရသတင်းများ',
        latest_highlights       : 'နောက်ဆုံးရဟိုက်လိုက်များ',
        league_contest  : 'League ပြိုင်ပွဲ',
        bill_contest    : 'ဖုန်းဘေလ်ပြိုင်ပွဲ',
        transaction     : 'ငွေသွင်းငွေထုတ်',
        tipsters        : 'ခန့်မှန်းပေးသူများ',
        tip             : 'ခန့်မှန်းချက်',
        league_history  : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        bill_history    : 'ဖုန်းဘေလ်ပြိုင်ပွဲသမိုင်း',
        contest         : 'ပြိုင်ပွဲ',
        leaderboard     : 'ရပ်တည်မှုဇယား',
        history         : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        euro_news       : 'ယူရို သတင်း',
        euro_standing   : 'ယူရို ရပ်တည်မှုဇယား',
        euro_team       : 'ယူရို အသင်းများ',
        payment         : 'ငွေပေးချေမှု',
        order           : 'မှာယူခြင်း',
        forum           : 'ဖိုရမ်',
        inbox           : 'စာတိုက်ပုံး',
        transfer        : 'transfer',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        betlist         : 'ရိုက်ချက်များ',
        betresultlist   : 'ရိုက်ချက်အဖြေများ',
        top_up          : 'Top Up',
        payment_aval    : 'ငွေပေးချေနိုင်သည့် ဝန်ဆောင်မူများ',
        game            : 'Game ဝန်ဆောင်မူများ',
        slot_game       : 'Slot Game ဝန်ဆောင်မူများ',
        withdrawal_history      : 'ငွေထုတ် မှတ်တမ်း',
        top_up_history         : 'ငွေဖြည့် မှတ်တမ်း',
        main_wallet     : 'Main Wallet',
        game_wallet     : 'Game Wallet',
        commission_wallet     : 'Commission Wallet',
    },
    form : {
        user            : 'အသုံးပြုသူ နာမည်',
        id              : 'မှတ်ပုံတင်အမှတ်',
        gems            : 'Gems',
        point           : 'ရမှတ်',
        hightest_point  : 'အမြင့်ဆုံးရမှတ်',
        rank            : 'အဆင့်',
        date_of_birth   : 'မွေးနေ့',
        join_date       : 'အသင်းဝင်ရက်စွဲ',
        language        : 'ဘာသာစကား',
        appview         : 'App View',
        gameview        : 'Game View',
        login           : 'အကောင့်၀င်ရန်',
        already_have_account : 'Already have a account',
        register        : 'အကောင့်မှတ်ပုံတင်ရန်',
        logout          : 'ထွက်မည်',
        password_reset  : 'စကားဝှက်ကို ပြန်လည်သတ်မှတ်မည်',
        verify_code     : 'ကုဒ်အတည်ပြုပါ',
        title           : 'ခေါင်းစဉ်',
        description     : 'ဖော်ပြချက်',
        name            : 'နာမည်',
        phone           : 'ဖုန်းနံပါတ်',
        email           : 'အီးမေလ်',
        password        : 'စကားဝှက်',
        forgot_password : 'စကားဝှက် မေ့နေပါသလား။',
        submit          : 'လုပ်ဆောင်မည်',
        next            : 'Next',
        accept          : 'Accept',
        cancel          : 'မလုပ်ဆောင်ပါ',
        new_post        : 'အသစ်တင်မည်',
        comment         : 'မှတ်ချက်',
        que             : {
            confirm     : 'အတည်ပြုပါ',
            delete      : 'သင်ဖျက်ဖို့သေချာလား။'
        },
        footer_login_check : 'ကိုယ်ပိုင်အကောင့်',
        footer_login_check_lang :'ဝင်ရောက်ရန်'
    },
    packages : {
        oneday          : 'တစ်ရက်စာ',
        oneweek         : 'တစ်ပတ်စာ',
        onemonth        : 'တစ်လစာ',
        buy             : '၀ယ်မယ်',
    },
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'မူလနေရာ', check: 'home', url : "/" },
            {icon: '/img/footer/pwallet.png',icon_off: '/img/footer/pwallet.png',text: 'ပိုက်ဆံအိတ်', check: 'wallet', url : "/more/wallet-store" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'ပရိုမိုးရှင်း', check: 'promotions', url : "/more/announcements" },
            // {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'ဝန်ဆောင်မှုဖုန်း', check: 'service', url : "/contact-us" },
            // {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'ကိုယ်ပိုင်အကောင့်', check: 'myaccount', url : "/more/myaccount" },
          ],
    homeicongame : [{ id: 0, icon: '/img/game_cat_1.png',icon_off: '/img/game_cat_1.png',text: 'အားလုံး', second_text: 'Game အားလုံး', check: 'All', url : "/more/announcements"},
                         { id: 1, icon: '/img/game_cat_2.png',icon_off: '/img/game_cat_2.png',text: 'အားကစားပွဲများ', second_text: 'အားကစား Game ဝန်ဆောင်မူများ', check: 'Sports', url : "/more/wallet-store" },
                         { id: 2,icon: '/img/game_cat_3.png',icon_off: '/img/game_cat_3.png',text: 'ကာစီနို', second_text: 'ကာစီနို Game ဝန်ဆောင်မူများ', check: 'Casino', url : "/contact-us" },
                         { id: 3,icon: '/img/game_cat_4.png',icon_off: '/img/game_cat_4.png',text: 'စလော့', second_text: 'စလော့ Game ဝန်ဆောင်မူများ', check: 'Slot', url : "/more" },
                         { id: 4,icon: '/img/game_cat_5.png',icon_off: '/img/game_cat_5.png',text: 'ငါးပစ်ဂိမ်း', second_text: 'ငါးပစ်ဂိမ်း Game ဝန်ဆောင်မူများ', check: 'Fishing', url : "/more" },
                   ],
    homeiconmiddle : [{icon: '/img/bank/‌aya_logo.png',icon_off: '/img/bank/‌aya_logo.png',text: 'AYA', check: 'Football', url : "/more/announcements"},
            {icon: '/img/bank/cb_logo.png',icon_off: '/img/bank/cb_logo.png',text: 'CB', check: 'Ball', url : "/more/wallet-store" },
            {icon: '/img/bank/kbz_logo.png',icon_off: '/img/bank/kbz_logo.png',text: 'KBZ', check: 'Basket Ball', url : "/contact-us" },
            {icon: '/img/bank/kpay_logo.png',icon_off: '/img/bank/kpay_logo.png',text: 'K Pay', check: 'Tennis', url : "/more" },
            {icon: '/img/bank/wave_logo.png',icon_off: '/img/bank/wave_logo.png',text: 'Wave Money', check: 'Tennis', url : "/more" },
              ],
    homeiconlist : [{icon: '/img/homeicon/sport1.png',icon_off: '/img/homeicon/sport1.png',text: 'WWBET', check: 'NEWS', url : "/wwbet-login"},
            {icon: '/img/homeicon/sport2.png',icon_off: '/img/homeicon/sport2.png',text: 'Sexy Gaming', check: 'TIP&ODDS', url : "/ae-login" },
            {icon: '/img/homeicon/sport3.png',icon_off: '/img/homeicon/sport3.png',text: 'HABANERO', check: '/habanero-login', url : "/haba-login" },
            {icon: '/img/homeicon/sport4.png',icon_off: '/img/homeicon/sport4.png',text: 'Joker', check: '/joker-login', url : "/joker-login" },
            // {icon: '/img/homeicon/sport2.png',icon_off: '/img/homeicon/sport2.png',text: 'Profile', check: 'Profile', url : "/about"},
            // {icon: '/img/homeicon/home-block-cockfight.jpg',icon_off: '/img/homeicon/home-block-cockfight.jpg',text: 'ကစားပွဲများ', check: 'ABOUT', url : "/about" },

              ],
    messages : {
        title : "အောက်ပါ ဖုန်းနံပါတ်များသည်",
        title_one : "ကုမ္ပဏီ၏ ဖုန်းနံပါတ်များ ဖြစ်ပါသည်။",
        company: "ShweBet",
        viber:"ဖြင့်ဆက်သွယ် ပေးပါ",
        connect : "Viber",
        connect_one : "ငွေသွင်း ၊ ငွေထုတ်  /  ဝန်ဆောင်မှု ဖုန်းနံပါတ်",
        time: "နေ့စဉ် ( နံနက် 7:30 နာရီ မှ ည 12:00 နာရီ ) ထိ ၊",
        time_one : "ပိတ်ရက်မရှိ ဝန်ဆောင်မှု ပေးနေပါသည်။",
        que_submit      : "ပေးပို့ချင်တာသေချာပါသလား",
        invalid_phone   : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။",
        welcome_message : "Shwebet မှကြိုဆိုပါသည်",
        sending         : "လုပ်ဆောင်နေပါသည်",
        somethingwrong  : "အချက်အလက်များ မှားယွင်းနေပါသည်။",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
        amount          : 'ငွေလက်ကျန်ပမာဏ',
        view_all        : 'ငွေသွင်း၊ ငွေထုတ် မှတ်တမ်း',
        recent_transaction : 'ငွေသွင်း / ငွေထုတ်',
        top_up : 'ငွေသွင်း',
        withdrawal : 'ငွေထုတ်',
    },
    moneyamountlist :  [
        { text: '5,000', num: '5000'},
        { text: '10,000',  num: '10000'},
        { text: '50,000',  num: '50000'},
        { text: '100,000',  num: '100000'},
        { text: '200,000',  num: '200000'},
        { text: '500,000',  num: '500000'},
    ],
    promotionlist :  [
        { id: 0, text: 'All', num: 'All'},
        { id: 1, text: 'Daily',  num: 'Daily'},
        { id: 2, text: 'Weekly',  num: 'Weekly'},
        { id: 3, text: 'Monthly',  num: 'Monthly'},
        { id: 4, text: 'New Member',  num: 'New Member'},
        { id: 5, text: 'Friend Refer',  num: 'Friend Refer'},
    ],
};


window.lang = window.mmlang;
