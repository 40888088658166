import React from 'react';
import {
    Link
  } from 'react-router-dom';
  let checklogin = () => {
    if(localStorage.getItem(window.BPdata.time)) {
        if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            return true;
        }
    }
    return false;
}
export default () => (
  <div className="page">
      <div className="page-content">
          <div className="main-content text-center">
                    <br />
                    <br />
                    <span>APP ‌ဖုန်းထဲထည့်သွင်းပြီးပါက <br /> Browser မှဝင်စရာမလိုပဲ တိုက်ရိုက်အသုံးပြုနိုင်သည်။</span>
                    <br />
                    <br />
                    <img src="/img/logo.png" className="medium-logo" />
                    <br />
                    <br />
                    <Link to="/download/ios" className="btn btn-success grid-50">IOS &nbsp;&nbsp;  ဒေါင်းလုတ်လုပ်ရန်နှိပ်ပါ</Link><br /><br />
                    
                    <Link to="/download/android" className="btn btn-yellow grid-50">Android &nbsp;&nbsp;  ဒေါင်းလုတ်လုပ်ရန်နှိပ်ပါ</Link><br /><br />

                    
          </div>
        </div>
  </div>
);
