import React from 'react';
import axios from 'axios';

import {
    Link
  } from 'react-router-dom';
import { ListView,Grid,Modal } from 'antd-mobile';

import swal from 'sweetalert';


import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

const alert = Modal.alert;
var moment = require('moment');

const options = [
    { value: 'flavor', label: 'flavor' },
    { value: 'yummy', label: 'yummy' },
    { value: 'red', label: 'red' },
    { value: 'green', label: 'green' },
    { value: 'yellow', label: 'yellow' },
];


class WalletWithdrawalStore extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = {
                        viber_ph_1 : "",
                        viber_ph_2 : "",
                        bullet : 0,
                        message: "",
                        wallet : [
                            {
                                wallet_id: 1,
                                credit : 0
                            },
                            {
                                wallet_id: 2,
                                credit : 0
                            }
                        ],
                        data : {},
                        step: "list",
                        league : false,
                        advertise : {advertise_image:""},
                        isLoading: false,
                        check: 0,
                        payment: [
                            { id : 1, name : "K Pay", image : "/img/kbz_color.jpg", non_image : "/img/kbz_black.jpg"},
                            { id : 2, name : "Wave Pay", image : "/img/wavepay_color.jpg", non_image : "/img/wavepay_black.jpg"},
                        ],
                        form :   {

                                "description":"",
                                "salt":localStorage.getItem(window.BPdata.time),
                                "to_user_id":1,
                                "fromgame_id":1,
                                "togame_id":23,
                                "transfer_type":2,
                                "customerbank":{
                                    "bank_acc_name":"",
                                    "bank_type":"",
                                    "bank_num":"",
                                    "id": 0
                                },
                                "dagonbank": {
                                    "id":0,
                                    "holdername":"",
                                    "bank_type":0,
                                    "cardnum":""
                                },
                                transaction_date: moment().format("YYYY-MM-DD hh:mm A"),
                                "amount":0,
                                "bank_transaction_id":""
                        },
                         permit : {
                            transfer_types : [
                                { id : 0, name : "-"},
                                { id : 1, name : "Deposit"},
                                { id : 2, name : "Withdrawal"},
                                { id : 3, name : "Transfer"}
                            ],
                            bank_types : [
                                { id : 1, shortcode : "AYA"},
                                { id : 2, shortcode : "CB"},
                                { id : 3, shortcode : "KBZ"},
                                { id : 4, shortcode : "K Pay"},
                                { id : 5, shortcode : "Wave Moneny"}
                            ],
                            bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            specfic_bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            customer_banks : [
                                 { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            specfic_cus_bank_accounts : [
                                  { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            from_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            to_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            last10trans : [{
                                id  : 44,
                                player_id :  802,
                                to_player_id  :  802,
                                from_total : 0,
                                percentage : 0,
                                bonus_type : 0,
                                amount : 330,
                                transfer_type : 1,
                                description : "",
                                staff_id   : 6,
                                cusbank_bank_acc_name :  "sdfd",
                                cusbank_bank_num  :  "32232323",
                                cusbank_bank_type  : 1,
                                dagonbank_id  :  1,
                                fromgame_id : "",
                                togame_id   : "",
                                transaction_date  : "2021-09-08 10:21:00",
                                approve_status : 0,
                                provider_id : 3,
                                bank_transaction_id : "2333232"
                            } ],
                            approve_statuses : [
                                { id : 0, name : "New"},
                                { id : 1, name : "Pending"},
                                { id : 2, name : "Confirm"},
                                { id : 3, name : "Cancel"},
                            ],
                        },
                        hints : {
                            array : [],
                            string : [],
                        },
                        bankaccount : { },
                        alertId: ''
                    };
        this.setAmount      = this.setAmount.bind(this);

        this.changeBankType = this.changeBankType.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);


        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
        this.sendTransactionApi      = this.sendTransactionApi.bind(this);
        this.showMessage = this.showMessage.bind(this);
    }


    // onDagonBankTypeChange(event) {
    //     // console.log("ok")
    //     let bank_accounts = [];

    //     let dagonbank_id = this.form.dagonbank.id;

    //     for(let i = 0; i< this.permit.specfic_bank_accounts.length; i++){

    //         // console.log(dagonbank_id + this.permit.specfic_bank_accounts[i].id)
    //         if(dagonbank_id == this.permit.specfic_bank_accounts[i].id) {

    //             this.form.dagonbank.id = this.permit.specfic_bank_accounts[i].id;
    //             this.form.dagonbank.holdername = this.permit.specfic_bank_accounts[i].holdername;
    //             this.form.dagonbank.bank_type = this.permit.specfic_bank_accounts[i].bank_type;
    //             this.form.dagonbank.cardnum = this.permit.specfic_bank_accounts[i].cardnum;

    //             // console.log(this.form.dagonbank.id + "------")

    //         }

    //     }


    //     event.preventDefault();

    // }

    onCusBankChange(e) {
        // console.log("ok")

        // let bank_type = e.target.value;

        let bank_accounts = [];

        let selectcustomerbank = {};

        let customerbank_id = e.target.value;

        for(let i = 0; i< this.state.permit.customer_banks.length; i++){

            // console.log(dagonbank_id + this.permit.specfic_bank_accounts[i].id)
            if(customerbank_id == this.state.permit.customer_banks[i].id) {


                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;
            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
            }
        }))


        e.preventDefault();
    }

    onBankTypeChange(e) {
        let bank_type;
        if(e === undefined){
            bank_type = 1;
        } else {
            bank_type = e.target.value;
        }


        // console.log(e)

        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")
        for(let i = 0; i<this.state.permit.customer_banks.length; i++){

            let selectcustomerbank = {};

            if(bank_type == this.state.permit.customer_banks[i].bank_type) {

                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;

                specfic_cus_bank_accounts.push(selectcustomerbank)

                find = 1;

                // console.log( this.state.permit.customer_banks[i].bank_type)
                // console.log("find ------")
                // console.log(specfic_cus_bank_accounts)

            }

            // console.log(this.state.permit.bank_accounts[i].shortcode+"--------")
        }

        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



        if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }

        if(bank_accounts.length > 0){
                dagonbank.id = bank_accounts[0].id;
                dagonbank.holdername = bank_accounts[0].holdername;
                dagonbank.bank_type = bank_accounts[0].bank_type;
                dagonbank.cardnum = bank_accounts[0].cardnum;
        }



        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = "";
            selectcustomerbank.bank_acc_name = "";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }



        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }
        {/*
        console.log(this.state.permit.specfic_cus_bank_accounts)*/}
      {/*  console.log(this.state.form.customerbank)

        return */}
    }

    showMessage() {


        // swal("Please check bank transaction id");

        swal("Request Withdrawal", {
            title: "Request Withdrawal",
            text: this.state.message,
          buttons: {
            // cancel: "Run away!",
            ok: {

              value: "catch",
            }
          },
        })
        .then((value) => {
          switch (value) {

            case "catch":
                this.props.history.push("/more/wallet-store");
              // swal("Gotcha!", "Pikachu was caught!", "success");
              break;

            default:
              swal("Got away safely!");
          }
        });

    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    setAmount(text){

        let bank_type = this.state.form.bank_type;

        this.setState({
                form :  {
                    bank_type : bank_type,
                    amount : text
                }
            });
    }

    inputNumberChangeHandler(e) {
        let form = {...this.state.form};
        if(Number(e.target.value)) {
            form[e.target.name] = Number(e.target.value);
            this.setState({
                form
            });
        }

    }

    inputChangeHandler(e) {

        let form = {...this.state.form};

        // if(e.target.name == "bank_type" ) {
        //     customerbank.bank_type
        // }

        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendTransactionApi() {
        var self = this;
        let formdata = this.state.form;
        // let dataurl = window.BPdata.apiUrl+'/user/login';
        const dataurl = window.BPdata.apiUrlCMS+"/transaction/create";

        if(this.state.bullet == 1) {

            swal("သင့်ရဲ့ ငွေထုတ်မှု့အားလုပ်ဆောင်နေပါသည် ခေတ္တ စောင့်ဆိုင်းပေးပါရှင့်");
            return;
        }

        if(this.state.bullet == 0) {

            this.setState({
                    bullet : 1,
            });
        }

        if(this.state.form.customerbank.id) {
            console.log("")
           {/* alert(this.state.form.dagonbank.bank_type)*/}
           {/* alert(this.state.form.customerbank.id)*/}
        } else {
            swal("Please check your Bank Account")
            // this.showErrorAlert("Please check your Bank Account", 'single');
            // alert("Please check your Bank Account")
            return;
        }

        if(this.state.form.amount <= 0) {
            swal("Please check your Amount")
            // this.showErrorAlert("Please check your Amount", 'single');
            // alert("Please check your Amount")
            return;
        }


        axios({
            method: 'post',
            url: dataurl,
            data: { body : formdata },
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token
            },
        })
        .then(function (response) {

            // console.log(response.data.data.message)

            if(response.data.status_code == 200) {

                // alert("200");

                self.setState({
                    message : response.data.message ,
                    // step : "profile",
                });
                // localStorage.setItem(window.BPdata.time, response.data.data.token );
                // localStorage.setItem('username', response.data.data.username );
                // localStorage.setItem('reset', 1);
                // self.props.history.push("/");
                self.showMessage();

            }

            if(response.data.status_code == 404) {
                if(response.data.message.length > 0){
                     swal(response.data.message);
                     self.setState({
                             bullet : 0,
                     });
                } else {
                    swal( window.lang.errors.contact_cs );
                }

            }

            //console.log(localStorage.getItem(window.BPdata.time))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    fetchData() {

          let self = this;
          //localStorage.getItem(window.BPdata.time)
          const url = window.BPdata.apiUrlCMS+'/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
          //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

          ///api/customer?transfer_type=2

          axios({
            method: 'post',
            url: url,
            data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
            headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  "X-Trident-Token" : window.BPdata.token,
                  "BP-Agent"         : window.BPdata.rand_compound,
                  "BP-Time"         : localStorage.getItem(window.BPdata.time)
              },
          })
          .then(resp => {

                this.setState(prevState => ({
                  wallet : resp.data.customer_wallet,
                  permit : {
                    ...prevState.permit,
                    customer_banks : resp.data.data.customer_banks,
                    specfic_bank_accounts : resp.data.bank_accounts,
                    bank_accounts : resp.data.bank_accounts,
                  }
                }))

                self.onBankTypeChange();


                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )


            })
            .catch(function(error) {
              // console.log(error);
            });



      }



    changeBankType(step) {

        this.setState({
                form :  {
                    bank_type : step
                }
            });

        return;

    }

    changeSubMenu(step) {

        // this.setState({ step : step});

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );

              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }


    componentDidMount() {

        // this.showMessage();
        window.scrollTo(0, 0);
        
        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "list" });
          } else {
             this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem('contest_advertise')) {

            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        }

        this.hisUrl('/contest');

        this.fetchData();
        // this.apifetchurl();

        clearTimeout(window.BPdata.liveurl);

    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {


        switch(this.state.step) {
            case "list": return this.listBlock();
            // case "banklist": return this.banklistBlock();
            default:      return "";
        }
    }

    listBlock() {
        return  <div className="row text-left">

                    <div className="grid-100 text-center">
                        <br />
                        { window.lang.messages.amount }
                        <br />
                        { this.state.wallet[1].credit } Ks
                        <br />

                        <hr />
                        <br />
                    </div>

                    <div className="grid-100 text-left">
                        <div class="form-group text-left">
                            <div class="col-md-8">
                                <div class="row">
                                    <div className="grid-60 left">
                                        <h4>Transfer Bank Info</h4>
                                    </div>

                                    <div className="grid-40 right text-right">
                                        <Link to="/more/wallet-store/add-bank" className="text-primary btn btn-success">Add Account</Link>
                                    </div>

                                </div>

                                <div class="form-group row m-10">
                                    <label class="control-label">Bank</label>
                                    <select class="form-control" name="bank_type" value={this.state.form.customerbank.bank_type} onChange={(e) => this.onBankTypeChange.call(this, e)}>
                                        {this.state.permit.bank_types.map(({ id, shortcode }, index) => <option value={id} >{shortcode}</option>)}
                                    </select>
                                    <label class="control-label">Name</label>
                                    <input required="required" type="text" name="bank_num" placeholder="Account Name" disabled="disabled" class="form-control" value={this.state.form.customerbank.bank_acc_name} />

                                    <label class="control-label">Account Number / Phone Number</label>
                                    <select class="form-control" value={this.state.form.customerbank.id} onChange={(e) => this.onCusBankChange.call(this, e)}>
                                        {this.state.permit.specfic_cus_bank_accounts.map(({ id, bank_num }, index) => <option value={id} >{bank_num}</option>)}
                                      {/*<option value="3624">23432432234324324</option>*/}
                                    </select>
                                </div>
                                    <div class="form-group">
                                        <label class="control-label">ပမာဏ</label>
                                        <input name="amount" type="text" value="0" class="form-control"  onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.amount} />
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">Comment</label>
                                        <textarea name="description" type="text" class="form-control"  onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.description} ></textarea>
                                    </div>

                                    <div>
                                    <br/>
                                </div>
                                        </div>
                        </div>
                            <div class="form-group text-center">
                            <a href="viber://chat?number=959773777xxx" className="color-white">If any trobule, Please Contact to our viber </a>
                            </div>
                            <br />
                            <input className="btn btn-success grid-100" value={window.lang.form.submit} onClick={this.sendTransactionApi}/>
                    </div>
                </div>

    }

    render() {
        return (

            <div className="page">
                <div className="page-content text-center">
                    <div className="page-contest text-center">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}

                        { this.viewHandler() }


                    </div>

                    <br />

                </div>
            </div>
        );
    }
}

export default WalletWithdrawalStore;
