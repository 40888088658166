import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';


const alert = Modal.alert;
var moment = require('moment');

class MyAccPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "my-profile";
        this.state = { step: "profile", message : "", form : {username: '', phone: '',verify_code: '', password: '' },companyuserid:"", id:"",date_of_birth:"",username:"",phone:"",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:"", editform : false , profile_photo : 'profile_blank.png'};
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
        this.versionSwitch      = this.versionSwitch.bind(this);
        this.userNameEdit       = this.userNameEdit.bind(this);
        this.userNameSubmit     = this.userNameSubmit.bind(this);
        this.emptyCache         = this.emptyCache.bind(this);

    }

    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }



    userNameEdit() {
        this.setState({editform : true});
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

      fetchUserData() {

            let self = this;
            //localStorage.getItem(window.BPdata.time)
            const url = window.BPdata.apiUrlCMS+'/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
            //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

            ///api/customer?transfer_type=2

            axios({
              method: 'post',
              url: url,
              data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
              headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound,
                    "BP-Time"         : localStorage.getItem(window.BPdata.time)
                },
            })
            .then(resp => {

                  this.setState({
                      companyuserid : resp.data.data.companyuserid,
                    id : resp.data.data.id,
                    username : resp.data.data.first_name,
                    date_of_birth : resp.data.data.date_of_birth,
                    phone : resp.data.data.phone,
                    created_at : resp.data.data.created_at,
                    profile_photo : resp.data.data.profile_photo,
                    // gem       : resp.data.gem,
                    // league_points : resp.data.league_points,
                    // max_league_points : resp.data.max_league_points,
                    // rank : resp.data.rank
                  });


              })
              .catch(function(error) {
                // console.log(error);
              });



        }



    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                    this.fetchUserData();
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        // this.fetchUserData();


        this.hisUrl('/more/myaccount');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        // this.state.username
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        this.setState({
            username : e.target.value
        });
    }

    versionSwitch() {
        if(localStorage.getItem('appview') === '0') {
            localStorage.setItem('appview', '1');
        } else {
            localStorage.setItem('appview', '0');
        }

        this.props.history.push("/more/myaccount");
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }

        // console.log(window.lang)
        this.props.history.push("/more/myaccount");
    }

    userLogout() {

        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" });
                    this.props.history.push("/");
                }

            },
        ])
    }



    userNameSubmit() {
        var self = this;
        let dataurl = window.BPdata.apiUrl+'/user/name/edit';
        axios({
            method: 'post',
            url: dataurl,
            data: {salt : localStorage.getItem(window.BPdata.time), username : this.state.username},
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({
                    message : "Successfully Login",
                    editform : false
                });
                // localStorage.setItem(window.BPdata.time, self.state.form.phone );
                // localStorage.setItem('username', response.data.username );
                // self.props.history.push("/contest");
            } else {
                self.setState({  message : "Something was wrong", editform : true });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }



    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Web App</p>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }

    emptyCache(){

        // alert("ok");

        if('caches' in window){

            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
    }

    render() {
        let profile_photo = localStorage.getItem('profile_photo')
        return (
            <div className="page">


                <div className="page-content text-left mt-10" >
                    <div className="page-contest">
                        <div className="row">
                            <div className="grid-20 left" style={{ marginTop:'20px' }}>
                              { (profile_photo) ? 
                                    <img src={"/img/avator/"+profile_photo} height="100pt" alt="" onClick={this.avatorOnOff} className="pointer" /> : 
                                    <img src={"/img/avator/"+this.state.profile_photo}  height="100pt" onClick={this.avatorOnOff} className="pointer"/>
                                } 
                            </div>
                            <div className="grid-70 right pl-4">
                                {this.state.username} <br />
                                {"****"+this.state.phone.substring(4)}<br /><br />
                                <Link to="/more/myaccount/edit-user" className="btn btn-small grid-9 p-1" style={{ border:'1px solid seagreen' }}><img src="/img/footer/_edit.png" className='color-white' height="12pt"/> <span className="color-white">Edit</span></Link>
                                <br /><br />

                            </div>
                        </div>
                        <br /><br /><br /><br />
                        <br />
                        <div className="row">
                            <div className="grid-100">
                                <span>{window.lang.form.id}</span>
                                <br />
                                {this.state.companyuserid} - {this.state.id}
                                <hr />
                            </div>
                        </div>
                        <div className="row form-group text-left">
                            <div className="col-md-12 grid-90 grid-s-90 left material-form">
                            <input id="text" type="text" className="" name="text" onChange={(e) => this.inputChangeHandler.call(this, e)} value=  {this.state.phone} Disable  />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.phone} </label>
                            </div>
                        </div>
                        <div className="row form-group text-left">
                            <div className="col-md-12 grid-90 grid-s-90 left material-form">
                            <input id="text" type="text" className="" name="text" onChange={(e) => this.inputChangeHandler.call(this, e)} value=  {(this.state.email) ?  this.state.email : '-'} Disable  />
                            <span className="highlight"></span>

                              <span className="bar"></span>
                            <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.email} </label>
                            </div>

                        </div>



                        <div className="row form-group text-left">
                            <div className="col-md-12 grid-90 grid-s-90 left material-form">
                            <input id="text" type="text" className="text-left" name="text" onChange={(e) => this.inputChangeHandler.call(this, e)} value={moment(this.state.date_of_birth, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')} Disable  />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.date_of_birth} </label>
                            </div>
                        </div>
                        <div className="row form-group text-left">
                            <div className="col-md-12 grid-90 grid-s-90 left material-form">
                            <input id="text" type="text" className="text-left" name="text" onChange={(e) => this.inputChangeHandler.call(this, e)} value={moment(this.state.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')} Disable  />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.join_date} </label>
                            </div>
                        </div>



                        <div className="row">
                            <div className="grid-100">
                                <span>{ window.lang.form.language }</span>
                                <br />


                            </div>
                            <div className="grid-50 left">
                                ({(localStorage.getItem('lang') === 'mm') ? 'Myanmar': 'English'})
                            </div>
                            <div className="grid-50 right text-center">
                                <label className="switch">
                                    <input type="checkbox"   onChange={this.langSwitch}/>
                                    <span className="sslider round"></span>
                                </label>
                            </div>
                            <hr />
                        </div>

                        <div className="row">
                            <div className="grid-100">
                                <span></span>
                                <br />


                            </div>
                            <div className="grid-50 left">
                                Version
                            </div>
                            <div className="grid-50 right text-center">
                                <a  className="btn btn-success  btn-small color-white p-4" onClick={this.emptyCache}>
                                    <span height="12px"> &nbsp; Update
                                    </span>
                                </a>
                                <br /><br />
                            </div>
                            <hr />
                        </div>



                        <br />
                        <a class="btn btn-success grid-90 pointer"  onClick={this.userLogout}>{window.lang.form.logout}</a>
                        <br />
                        <br />
                        <br />
                    </div>

                    {/*
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null)
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link>
                            </div>
                        </div>
                    </div>
                     */}

                </div>

            </div>
        );
    }
}

export default MyAccPage;
