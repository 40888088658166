import React from 'react';

export default () => (
  <div className="page">
      <div className="page-content">
          <div className="main-content text-center">
                    <br />
                    <br />
                    <h2 className="color-theme-gold" style={{ "fontSize": "24px"}}>Shwe Bet App ကို IOS သမားများအတွက် ဖုန်းထဲကို အက်ပလီကေးရှင်းထည့်နည်း</h2>
                    <br /><br />
                    <span>
                    ✅ပထမဦးဆုံး safari browser ထဲကိုဝင်ပြီး http://shwebet.com ကိုရိုက်ရှာကာ ဝက်ဆိုဒ်ထဲသို့ဝင်ပါ။<br />
                    ၁။ safari browser ရဲ့ share button ကိုနှိပ်ပါ <br />
                    ၂။ Add to Home Screen ကိုနှိပ်ပါ <br />
                    ၃။ လူကြီးမင်းရဲ့ဖုန်းထဲသို့ Shwe Bet icon လေးရောက်ရှိနေတာ တွေ့ပါလိမ့်မယ် <br /></span>
                    <br />
                    <br />
                    <img src="/img/guide/iosss1.jpg" className="img-responsive" /><br />
                    <br />
                    <img src="/img/guide/iosss2.jpg" className="img-responsive" /><br />
                    <br />
                    <img src="/img/guide/iosss3.jpg" className="img-responsive" /><br />
                    <br />
                    
          </div>
        </div>
  </div>
);
