import React from "react";
import "./SideDrawer.css";
import {
    Link
  } from 'react-router-dom';

import { Modal }  from 'antd-mobile';
import axios from 'axios';

const alert = Modal.alert;

  // let profile_photo = "";
 let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

  let profile_photo = null;
  let fetchData = () => {

        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrlCMS+'/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        ///api/customer?transfer_type=2

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {


                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

                if(resp.data.customer_wallet[1].credit) {
                  document.getElementById("balance").innerHTML = resp.data.customer_wallet[1].credit+" Ks";
                } else {
                  document.getElementById("balance").innerHTML = 0+" Ks";
                }

                profile_photo = resp.data.data.profile_photo;

                // console.log(resp.data.customer_wallet)

                localStorage.setItem("username", resp.data.first_name)

                // console.log(resp.data.first_name + "----")
                //
                // console.log('ok--')

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
                this.setState({login: 1})

            } else {
                this.setState({login: 0})
            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }






const sideDrawer = props => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }

let Statement = () => {
  console.log('hello')
} 


  return (

      <nav className={drawerClasses.join(" ")}>
              <div className="row drawerTop" >
                  <div className="grid-100" style={{marginRight:"16px" , paddingTop:"16px" , paddingBottom:'16px'}}>
                      <div style={{margin:'10px',marginLeft:'35%' }}>
                      <img src="/img/header_logo.png" style={{ width:'73px',height:'42px' }} alt="Logo"/>
                      </div>
                      
                      {/* <img src="/img/footer/_profile_human.png" style={{ marginLeft:"10px", height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/> */}
                      {/* {{ profile_ }} */}
                      { (localStorage.getItem("profile_photo")) ? 
                     <img src={"/img/avator/"+localStorage.getItem("profile_photo")} style={{ marginLeft:"10px", height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>: 
                     <img src="/img/avator/profile_1.png" style={{ marginLeft:"10px", height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/> 
                  } 
                      {/* <img src={"/img/avator/"+localStorage.getItem("profile_photo")} style={{ marginLeft:"10px", height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/> */}
                      <span className="username">{localStorage.getItem('username')}</span>
                      <hr style={{border:"1px solid rgb(255, 255, 255)",width : "90%"}}/>
                      <img src="/img/footer/_wallet.png" style={{marginLeft:"10px", height: "30px", verticalAlign: "middle", marginRight:"16px"}} alt="Wallet"/>
                       <span className="color-white balance" id="balance">{ (localStorage.getItem("wallet")) ? ((JSON.parse(localStorage.getItem("wallet")).length > 0) ? JSON.parse(localStorage.getItem("wallet"))[1].credit : "0" ) : ""} Ks</span> <a className="pointer color-white"  onClick={fetchData} style={{ color:"#fff"}}>
                       </a>
                    </div>
                    {/* <h4>Hello world</h4> */}
                </div>


                <ul>

                  {(checklogin()) ?  <Link to="/more/myaccount"><li><img src="/img/list/profile_menu.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/> &nbsp; {window.lang.more.my_account}</li></Link> : ""}

                  {/* DropDown */}

                  {(checklogin()) ?  <Link to="/more/choosestatement"><li><img src="/img/list/bet_app.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/> &nbsp; {window.lang.more.statement}</li></Link> : ""}
               

                  {(checklogin()) ?  <Link to="/more/feedback"><li><img src="/img/list/suggestion.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.feedback} </li></Link> : ""}
                  <Link to="/faq"><li><img src="/img/list/faq.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.faq}</li></Link>

                  <Link to="/promotion"><li><img src="/img/list/promotion.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.promotion}</li></Link>
                  <Link to="/share-app"><li><img src="/img/list/share_app.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.share_app}</li></Link>
                  <Link to="/download"><li><img src="/img/list/app_download.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.download_app}</li></Link>
                  <Link to="/more/term-condition"><li><img src="/img/list/promotion.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.term_condition}</li></Link>

                  {(checklogin()) ?  <Link to="/more/changepassword"><li><img src="/img/list/promotion.png" style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>  &nbsp; {window.lang.more.changepassword} </li></Link> : ""}

                  {(checklogin()) ?
                    <div className="row mt-10" >
                      &nbsp;&nbsp; <a style={{ background:'linear-gradient(90.51deg, #E4CF4F 0.4%, #DBA048 99.54%)'}} className="btn  grid-80 pointer color-white"  onClick={userLogout}>{window.lang.form.logout}</a>
                  </div>
                  : ""}

                </ul>






      </nav>
  );
};

let userLogout = () => {

      alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
          { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
          {
          text: 'ထွက်မည်',
          onPress: () => {
                  localStorage.setItem(window.BPdata.time, 0);
                  localStorage.setItem('username', "");
                  // this.setState({ step: "login" , message : "Successfully Log Out" });
                  // sideDrawer({ show : "close"})
                  window.location.reload();
              }

          },
      ])
  }

export default sideDrawer;


// https://codesandbox.io/s/b2dfd?file=/src/components/sidemenu/SideDrawer.js
