import React from 'react';
import axios from 'axios';
import 'react-day-picker/lib/style.css';
//import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    Link
  } from 'react-router-dom';
import DayPicker from 'react-day-picker';
var moment = require('moment');

// import FacebookProvider, { CommentsCount,Comments,Like } from 'react-facebook';
// var DatePickerBlock = React.createClass({
//     render: function() {
//       return (
//       <div id="results" className="search-results">
//         Some Results
//       </div>);
//      }
//   });
let adcount = 0;

class LiveScorePage extends React.Component {
    constructor(props) {
      super(props);
      window.BPdata.footerMenu = "LIVE SCORES";
      this.handleDayClick = this.handleDayClick.bind(this);
      this.handleButtonClick = this.handleButtonClick.bind(this);
  
      this.state = {
        //selectedDays: [new Date("2018-12-10T05:30:00.000Z")],
        selectedDays: [new Date()],
        data : [],
        advertise : [],
        live : 0,
        isLoading: true,
        post_id : 1,
        hidediv: false,
        button_flag: false,
        fixtures: [],
        recent_results: []
      };

      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }
    }

    datePickerPre() {
       // moment(this.state.selectedDays[0], moment.ISO_8601).add(1, 'day');
        //moment(this.state.selectedDays[0]).format("YYYY-MM-DD")
        let day = moment(this.state.selectedDays[0]).subtract(1, 'day')._d;
        this.setState({
            selectedDays : [day],
            data : [],
            isLoading: true
        })

        let stateday = moment(day).format("YYYY-MM-DD");
        this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
    }


    datePickerNex() {
        let day = moment(this.state.selectedDays[0]).add(1, 'day')._d;
        this.setState({
            selectedDays : [day],
            data : [],
            isLoading: true
        })

        let stateday = moment(day).format("YYYY-MM-DD");
        this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
        //console.log(this.state.selectedDays[0])
    }


    datePicker(hideResults) {
        let block = []
        //console.log(hideResults)
        if(this.state.hidediv === true) {
            this.setState({hidediv : false })
        } else {
            this.setState({hidediv : true })
        }
        
        return block
    }

    handleButtonClick(data) {
        let today = moment(new Date()).format("YYYY-MM-DD");

        if(this.state.button_flag === false) {
            this.setState({
                button_flag: true
            });
            this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/live','livescore');
        } else {
            this.setState({
                button_flag: false
            });
            this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+today,'livescore');
            
        }
        
        //console.log(this.state.button_flag)
    }

    handleDayClick(day, { selected }) {
        // console.log('----------------------')
        //console.log(selected)
        // console.log('----------------------')
        this.setState({
            selectedDays : [day],
            data : [],
            isLoading: true
        })

        this.setState({hidediv : false })
        let stateday = moment(day).format("YYYY-MM-DD");
        this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
      }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    
                }
                window.BPdata.preurl =  menu[menu.length-2];
                // console.log(window.BPdata.preurl)
            }
        }
      }

    componentDidMount() {
        localStorage.removeItem('androidpopup');

        if(localStorage.getItem('live')) {
            if(JSON.parse(localStorage.getItem("live")) != null) {
              let data = JSON.parse(localStorage.getItem("live"));
              let live_advertise = JSON.parse(localStorage.getItem("live_advertise"));
              //this.setState({advertise: live})

                this.setState({
                    data: data.league,
                    live: data.live,
                    live_start: data.live_start,
                    live_end: data.live_end,
                    advertise: live_advertise,
                    isLoading: false
                });

            }
        } 

        // if(localStorage.getItem('home_advertise')) {
        
        //     if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        //       let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        //       this.setState({advertise: home_advertise})
        //     }
        //   } 


        let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        this.fetchData(window.BPdata.apiUrl+'/dbsoccer/livescore/'+stateday,'livescore');
        this.hisUrl('/live-score')
    }

    componentWillUnmount() {
       // clearInterval(this.state)
       //setTimeout(() => this.lv.scrollTo(0, 120), 800);
    }

    UNSAFE_componentWillUpdate() {
        // this.hisUrl('/live-score')
        //localStorage.setItem('route', '/live-score' );
        //console.log(localStorage.getItem('route'))
      }

    fetchData(url,data) {
        
        let dataurl = url;

        let today = moment(new Date()).format("YYYY-MM-DD");

        let todayurl = window.BPdata.apiUrl+'/dbsoccer/livescore/'+today
        let liveurl  = window.BPdata.apiUrl+'/dbsoccer/livescore/live'

        if(url === todayurl || url === liveurl) {
            clearTimeout(window.BPdata.liveurl)
            //27000 miliseconds
            //myVar =  setTimeout(() => this.fetchData(url,'livescore'), 18000);27000
            window.BPdata.liveurl =  setTimeout(() => this.fetchData(url,'livescore'), 30000);
        } else {
            clearTimeout(window.BPdata.liveurl)
        }

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            //console.log(data)
            this.setState({
                data: resp.data.league,
                live: resp.data.live,
                live_start: resp.data.live_start,
                live_end: resp.data.live_end,
                isLoading: false
            });
            //this.setState({ isLoading: false });

            localStorage.setItem("live", JSON.stringify(resp.data) )
            localStorage.setItem("live_advertise", JSON.stringify(resp.data.advertise) )
            //console.log(resp.data);
        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   
        
    }

    displayBlock( ) {
        //console.log(this.state.advertise)

        adcount = 0;
        let data = this.state.data
        let y;
        
        //console.log(data.length)
        if(this.state.data.length  > 0){
            let ui = []
            let adlength = this.state.advertise.length;
            //let gplength = data.length;
            // data.forEach(function(each){
            for (y = 0; y < this.state.data.length; y++) { 
                let each = data[y]
                if(each.match.length > 0) {
                    console.log("key ----------- "+ y)
                    ui.push(<div className="row" key={y}>
                        <div className="league-box box-shadow mt-20 " >
                                <div className="league-list-header">
                                    <img src={"/img/league/"+each.icon} className="list-img-circle1" alt="Trending" />
                                        <span>{each.name}</span>
                                </div>
                                {this.childui(each.match)}
                                {this.advertiseui(adlength,1,0)}
                        </div>
                        
                    </div>)
                } 

                //adcount++;
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }


    advertiseui(adlength,check,other) {
        //adcount
        let ads = []
        // console.log("count"+adcount)
        // console.log("ok"+this.state.advertise[0].advertise_goto+"---------------------")
        if(check) {
            if(adlength>adcount) {
                //console.log("ok"+this.state.advertise+"---------------------")
                ads.push(<div className="row"><a href={this.state.advertise[adcount].advertise_goto} target="_blank" key={adcount} rel="noopener noreferrer">
                <div key={adcount} style={{ padding: '0px' }}>
                <div style={{  display: 'inline-block', padding: '5px 0' }}><img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise[adcount].advertise_image} alt=""   alt={this.state.advertise[adcount].advertise_title} style={{ width: '100%' }} /></div></div>
                </a></div>)
                adcount++;
            }
        } else {
            //let other  = []
            for (let i = 0; i < other.length; i++) { 
                let index = other[i]
                ads.push(<div className="row"><a href={this.state.advertise[index].advertise_goto} target="_blank" key={adcount} rel="noopener noreferrer">
                <div key={adcount} style={{ padding: '0 5px' }}>
                <div style={{  display: 'inline-block', padding: '5px 0' }}><img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise[adcount].advertise_image} alt=""   alt={this.state.advertise[adcount].advertise_title} style={{ width: '100%' }} /></div></div>
                </a></div>)
            }
        }
        
        
        return ads;
    }

    checkLive(created_at) {
        let live_start = Number(new Date(this.state.live_start).getTime().toString().substring(0,10));
        let created_at_string = Number(new Date(created_at).getTime().toString().substring(0,10));
        let live_end = Number(new Date(this.state.live_end).getTime().toString().substring(0,10));
        return ( live_start < created_at_string && created_at_string <  live_end);
    }
    childui(arr) {
        let ui = []

        if(Array.isArray(arr) === false) {
            let each = arr
            ui.push(
                <div className="league-box-paragraph" key='0'>
                    <div className="row">
                    
                    {
                        (each.status === 'FT' || each.status === 'HT' || each.status === 'Pen.' || each.status === 'Cancl.' || each.status === 'AET' || each.status === 'Postp.' ) ? (<Link to={"/live/match-detail/"+each.id} className="cont-wrap-link">
                            <div className="grid grid-20">
                                    <span className="color-green">
                                        {each.status}
                                    </span>
                            </div>
                            <div className="grid grid-30">
                                <span>{each.home.name}</span>
                            </div>
                            <div className="grid grid-20">
                                <b>{(each.home.goals ) ? ((each.home.goals !== "?" ) ? each.home.goals : "?")  : ((this.checkLive(each.created_at)) ? "0" : "?")  } - {(each.away.goals) ? ((each.away.goals !== "?" ) ? each.away.goals : "?") : ((this.checkLive(each.created_at)) ? "0" : "?")   }</b>
                            </div>
                            <div className="grid grid-30">
                                <span>{each.away.name}</span>
                            </div>

                        </Link>): 
                        (<div>
                                <div className="grid grid-20">
                                    <span className={ (this.checkLive(each.created_at)) ? "color-red" : "color-green"}>
                                        {this.burmeseTimezone(each.time)}
                                    </span>
                                </div>
                                <div className="grid grid-30">
                                    <span>{each.home.name}</span>
                                </div>
                                <div className="grid grid-20">
                                
                                    <b>{(each.home.goals ) ? ((each.home.goals !== "?" ) ? each.home.goals : "?")  : ((this.checkLive(each.created_at)) ? "0" : "?")  } - {(each.away.goals) ? ((each.away.goals !== "?" ) ? each.away.goals : "?") : ((this.checkLive(each.created_at)) ? "0" : "?")   }</b>
                                </div>
                                <div className="grid grid-30">
                                    <span>{each.away.name}</span>
                                </div>
                            </div>)
                    } 
                    </div>
                </div>)
        } else {
            let arlength = arr.length;
        
            if(arlength > 0) {
                
                for (let i = 0; i < arlength; i++) { 
                    let each = arr[i]
                    ui.push(
                                <div className="league-box-paragraph" key={i}>
                                    <div className="row">
                                         {/* <a href={"/live/match-detail/"+each.id} >  */}
                                        {
                                            (each.status === 'FT' || each.status === 'HT' || each.status === 'Pen.' || each.status === 'Cancl.' || each.status === 'AET' || each.status === 'Postp.' ) ? (
                                            <div> <Link to={"/live/match-detail/"+each.id} className="cont-wrap-link">
                                                <div className="grid grid-20">
                                                        <span className="color-green">
                                                            {each.status}
                                                        </span>
                                                </div>
                                                <div className="grid grid-30">
                                                    <span>{each.home.name}</span>
                                                </div>
                                                <div className="grid grid-20">
                                                    <b>{(each.home.goals ) ? ((each.home.goals !== "?" ) ? each.home.goals : "?")  : ((this.checkLive(each.created_at)) ? "0" : "?")  } - {(each.away.goals) ? ((each.away.goals !== "?" ) ? each.away.goals : "?") : ((this.checkLive(each.created_at)) ? "0" : "?")   }</b>
                                                </div>
                                                <div className="grid grid-30">
                                                    <span>{each.away.name}</span>
                                                </div>

                                            </Link>
                                            {(each.live_url !== "" && each.status === 'HT' ) ? 
                                                        <div className="row text-center">
                                                            <div className="grids">
                                                                <a  href={each.live_url} className="btn btn-yellow" target="_blank" rel="noopener noreferrer">Go to Live</a>
                                                            </div>
                                                        </div>
                                                         :
                                                         ""
                                            } 
                                            </div>): 
                                            (<div>
                                                    <div className="grid grid-20">
                                                        <span className={ (this.checkLive(each.created_at)) ? "color-red" : "color-green"}>
                                                            {this.burmeseTimezone(each.time)}
                                                        </span>
                                                    </div>
                                                    <div className="grid grid-30">
                                                        <span>{each.home.name}</span>
                                                    </div>
                                                    <div className="grid grid-20">
                                                        <b>{(each.home.goals ) ? ((each.home.goals !== "?" ) ? each.home.goals : "?")  : ((this.checkLive(each.created_at)) ? "0" : "?")  } - {(each.away.goals) ? ((each.away.goals !== "?" ) ? each.away.goals : "?") : ((this.checkLive(each.created_at)) ? "0" : "?")   }</b>
                                                    </div>
                                                    <div className="grid grid-30">
                                                        <span>{each.away.name}</span>
                                                    </div>
                                                    {(each.live_url === "" ) ? 
                                                        ""
                                                         :
                                                         <div className="row text-center">
                                                            <div className="grids">
                                                                <a  href={each.live_url} className="btn btn-yellow" target="_blank" rel="noopener noreferrer">Go to Live</a>
                                                            </div>
                                                        </div>
                                                      } 
                                                    
                                             </div>)
                                        } 
                                            {/* <div className="grid grid-10">
                                                <img src="/img/star.jpg" alt="star" />
                                            </div> */}
                                         {/* </a>  */}
                                        {/* { (each.status === 'FT' || each.status === 'HT') ? '</a> ': "" }  */}
                                    </div>
                                </div>)
                }
            }
        }
        
        return ui;
    }

    numberRange (start, end) {
        return new Array(end - start).fill().map((d, i) => i + start);
    }

    pad2(number) {
        return (number < 10 ? '0' : '') + number
    }

    burmeseTimezone(time) {

        if(time === "FT") {
            return time
        }
        if(time === "HT") {
            return time
        }
        if(time === "Pen.") {
            return time
        }
        if(time === "Cancl.") {
            return time
        }
        let times = time.split(":");
        let minute = parseInt(times[0])*60 + parseInt(times[1]);
        //return minute
        //6:30 = 390 minute
        minute = 390 + minute
        if(minute>1440){
            minute = minute - 1440  
        }
	    
        let o_hour  = Math.floor(minute/60)
        let o_minute = (minute%60)
        if(this.pad2(o_hour) === '24') {
            o_hour = '00';
        } else {
            o_hour = this.pad2(o_hour);
        }

        let output_hour =  o_hour + ":" + this.pad2(o_minute)
        return output_hour
        //parseInt(time)
    }

    render() {

        return (
            <div className="page">
                
                <div className="page-content">
                    <div className="main-content">
                        <div className="row m-5">
                            {/* <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <span>Back<img src="/img/back-arrow.png" alt="back" /></span> : ""  ) : "" }
                            </Link>  */}
                            
                            <br/>
                            <div className="row live-score-header">
                                <div className="left">
                                    <div className="live-color-box" className={
                                            this.state.button_flag ? 'live-box active' : 'live-box'
                                        }  onClick={this.handleButtonClick}>
                                        <span> 
                                        {
                                           (this.state.live === 0) ? ("LIVE") : ("LIVE ("+this.state.live+")")
                                        }
                                        </span>
                                    </div>
                                </div> 
                                <div className="right">
                                    <div className="calendar-color-box">
                                        <span onClick={this.datePickerPre.bind(this)} className="calendar-left-arrow left">  
                                             &lt;
                                        </span>
                                        <span onClick={this.datePicker.bind(this)} className="no-bg calendar-color-box-text">
                                            { moment(this.state.selectedDays[0]).format("DD MMMM") } <img src="/img/calendar.png" alt="calendar"/>
                                        </span>
                                        <span  onClick={this.datePickerNex.bind(this)} className="calendar-right-arrow right">  
                                            &gt;
                                            {/* <img src="/img/calendar.png" alt="calendar"/> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                {this.state.hidediv ?
                                    <DayPicker
                                    selectedDays={this.state.selectedDays}
                                    onDayClick={this.handleDayClick}
                                    month={new Date(this.state.selectedDays)}
                                    />: null 
                                } 
                            </div>
                            
                        </div>
                        
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : "" }
                            {this.displayBlock()}
                            {(this.state.advertise.length>adcount) ? (this.advertiseui(this.state.advertise.length,1,0)) : "" }
                       
                        {/* <div class="ant-back-top"><div class="ant-back-top-content">Back<div class="ant-back-top-icon"></div></div></div> */}
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            );
        }
      }
      
export default LiveScorePage;
 