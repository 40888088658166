import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import mmph from 'myanmar-phonenumber';
import SimpleReactValidator from "simple-react-validator";

class LoginYgnPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: ''   }, 'provider_id': 1};
        this.validatePhone      = this.validatePhone.bind(this);
        this.userLogout         = this.userLogout.bind(this);
        this.redirectLink       = this.redirectLink.bind(this);

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: "This is a required field.",
                email: "Please enter a valid email.",
                regex: "Please enter a phone number in this format: 09123456789",
            },
        });
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        console.log(window.BPdata.time);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }

        if(localStorage.getItem('username')) {
                this.setState({
                    form: {
                        username : localStorage.getItem('username')
                    }
                });
        }

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/login');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    userLogout() {
        //console.log(this.state.step);
        let data = window.confirm("Are you sure want to logout?");
        if(data){
            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");
            this.setState({ step: "login" , message : "Successfully Log Out" });
            //this.profileBlock()
            this.viewHandler();
        }
    }

    redirectLink(to) {
        this.props.history.push(to);
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)

        if (this.validator.fieldValid("phone") && this.validator.fieldValid("password")) {

            if(mmph.isValidMMPhoneNumber(form.phone)){

                data = {
                    phone : this.state.form.phone,
                    password : this.state.form.password,
                    agent_provider_id : this.state.provider_id,
                }
                //console.log(data)
                this.setState({  message : window.lang.messages.sending });
                this.sendLoginApi(data);
            } else {
                this.setState({  message : window.lang.messages.invalid_phone });
            }

        } else {
            // console.log("validation failed");
            this.validator.showMessages();
        }

    }

    sendLoginApi(formdata) {
        var self = this;
        // let dataurl = window.BPdata.apiUrl+'/user/login';
        const dataurl = window.BPdata.apiUrlCMS+"/player/login";

        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8', "X-Trident-Token" : window.BPdata.token ,"BP-Agent" :window.BPdata.rand_compound },
        })
        .then(function (response) {

            // console.log(response.data.data.message)

            if(response.data.data.status != 403) {
                self.setState({
                    message : "Successfully Login",
                    step : "profile",
                    form : {
                        username : response.data.username
                    }
                });
                localStorage.setItem(window.BPdata.time, response.data.data.token );
                localStorage.setItem('username', response.data.data.username );
                localStorage.setItem('reset', 1);

                //console.log(JSON.stringify(response.data.data.customer_wallet))

                localStorage.setItem("wallet", JSON.stringify(response.data.data.customer_wallet) )
                self.props.history.push("/");
            } else {
                self.setState({  message : response.data.data.message });
            }

            //console.log(localStorage.getItem(window.BPdata.time))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }

    loginBlock() {
        return <div className="form-box">

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.phone} </label>
                        {this.validator.message("phone", this.state.form.phone, [
                                    "required",
                                    { regex: ["^09[1-9]"] },
                                    { min: ["8"] },
                                ])}
                        <br />

                        <input type="text" className="form-control" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.password}</label>
                        {this.validator.message("password", this.state.form.password, "required")}
                        <br />
                        <input type="password" className="form-control"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br />
                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-100 left">
                        <br />
                        <input type="submit" className="btn btn-success grid-100" value={window.lang.form.submit} onClick={this.validatePhone} /><br />

                    </div>
                </div>





        </div>
    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username )? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Web App</p>
              <br/>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

   homeBlock() {
        return <div>
            <br />
            <br />
            <br />

            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-success grid-50">{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50">{window.lang.form.register}</Link>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                    <br />
                    <br />
                    <h1 className="color-green">{window.lang.form.login}</h1>
                    <br />
                    <span>{this.state.message}</span>

                    { this.viewHandler() }

                    <div className="row">
                      <div className="grid-100">
                        <br /><br />
                      </div>
                    </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default LoginYgnPage;
