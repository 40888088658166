import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
import {
    Link
  } from 'react-router-dom';
//import { FacebookProvider, Comments } from 'react-facebook';
import axios from 'axios';
import renderHTML from 'react-render-html';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import CommentList from "./../../parts/CommentList.jsx";
import CommentForm from "./../../parts/CommentForm";

//import Comment from './Comment';
// import {
//   FacebookIcon,
//   TwitterIcon,
//   WhatsappIcon,
//   ViberIcon
// } from 'react-share';
//require('./../../BPdata.js')
var moment = require('moment');

// let lang = localStorage.getItem('lang');
// import FacebookProvider, { CommentsCount,Comments,Like } from 'react-facebook';

// const list = [
//     { id: 0, name: 'NEWS', shortname: 'NEWS', url : 'NEWS' },
//     { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  url : 'HIGHLIGHTS' },
//     { id: 1, name: 'English Premier League',  shortname: 'EPL',  url : 'epl' },
//     { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', url : 'laliga' },
//     { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', url : 'serie-a' },
//     { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA',  url : 'bundesliga' },
//     { id: 5, name: 'France Ligue 1',shortname: 'LIGUE 1',  url : 'ligue-one' },
//     { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', url : 'champion' },
//     { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', url : 'europa' },
//     { id: 8, name: 'World Cup', shortname: 'WORLDCUP', url : 'worldcup' },
//     { id: 9, name: 'Euro', shortname: 'EURO', url : 'euro' },
//     { id: 10, name: 'Others', shortname: 'OTHERS', url : 'others' }
//   ];
  

  
  // One item component
  // selected prop will be passed
  const MenuItem = ({ activeMenu, data, text, selected }) => {
    return (
      
        <div
        className="menu-item"
        >
          {data.id === '0' ? (
            <Link to={`/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
          ) : (
            <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
          )}
          {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
           
            {/* <span>{text}</span> */}
  
          {/* </Link> */}
        </div>
    );
  };
  
  
  // All items component
  // Important! add unique key
  export const Menu = (list,selected,activeMenu) => list.map(el => {
    const { name,shortname } = el;
    // console.log(selected)
    return (
      <MenuItem
        activeMenu={activeMenu}
        text={shortname}
        key={name}
        data={el}
        wheel={true}
        //scrollToSelected={true}
      />
    );
  });
  
  
  const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
  };
  
  
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
let MAX_LENGTH = 90;

class ListDetailPage extends Component {
    constructor(props) {
      super(props);
  
      window.BPdata.footerMenu = "NEWS";
      this.state = {
        data : {
            title : '',
            body : '',
            featured_img: ''
        },
        latest_data : [],
        isLoading: true,
        post_id : 1,
        leagueMenu: 'NEWS',
        menu : window.BPdata.mainmenu,
        comments: [],
        comment : null,
        advertise: {},
        loading: true,
        liked : 0,
        fetch : 0,
      };

      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

      this.addComment = this.addComment.bind(this);

    }

    addComment(comment) {

      //this.props.history.push("/forum/15");
      this.setState({
        loading: false,
        // comments: [ comment, ...this.state.comments],
        fetch : 1
        //comments: [...this.state.comments,comment],
        //comments: [this.state.comments],
        // comment : comment
      });
      this.fetchCommentData();
    }
  
    UNSAFE_componentWillMount() {
      window.BPdata.lang = localStorage.getItem("lang");
    }

    componentDidMount() {
        this.fetchData();
        this.fetchLatestData();
        
        let self = this;
        
        //console.log(localStorage.getItem('route'))
        // loading
        this.setState({ loading: true });

        

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            // let opts = { phone : localStorage.getItem(window.BPdata.time) , post_id : this.state.post_id }
            // fetch(window.BPdata.apiUrl+'/user/news/liked', {
            //   method: 'post',
            //   body: JSON.stringify(opts)
            // }).then(function(response) {
            //   return response.json();
              
            // }).then(function(data) {
            //   // console.log(data)
            //   // console.log(data.liked)
            //   if(data.liked) {
            //     self.setState({
            //       liked: data.liked,
            //       loading: false
            //     });
            //   }
            //   // ChromeSamples.log('Created Gist:', data.html_url);
            // });
            // console.log(this.state.data.liked)
            this.fetchCommentData();
          }
        }

        this.hisUrl('/news/'+this.state.post_id)
        clearTimeout(window.BPdata.liveurl);
    }

    fetchCommentData() {
      fetch(window.BPdata.apiUrl+'/user/comment/'+this.state.post_id+"/1")
      .then(res => res.json())
      .then(res => {
        // console.log(res)
        if(res){
          this.setState({
            comments: res,
            loading: false
          });
        }
        
      })
      .catch(err => {
        this.setState({ loading: false });
      });
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {
      
    }

    fetchData() {
        

        if(JSON.parse(localStorage.getItem("wapp_latest_news"))) {
          let latest_news = JSON.parse(localStorage.getItem("wapp_latest_news"));
          let post_id = this.state.post_id;
          var result = latest_news.filter(function(obj, index){
            return obj.id_news===post_id;
          })

          if(result.length>0) {
            this.setState({
              data: result[0],
              isLoading: false
            })

          
          }
          //console.log(result)
        } 


          const dataurl = window.BPdata.apiUrlCMS+"/news/detail/"+this.state.post_id;
          // let dataurl = window.BPdata.apiUrl+'/news/detail/'+this.state.post_id;

          axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(resp => {
          //   this.data = resp.data;
          //   console.log("ok2");
              //console.log(this.data);
              //console.log(this.pageIndex);
              // this.pageIndex++;
              this.setState({
                  data: resp.data.news,
                  // leagueMenu : resp.data.data.category_short,
                  // menu : resp.data.menu,
                  advertise : resp.data.advertise
              });
              this.setState({ isLoading: false });
              // console.log(this.state.data);
          })
          .catch(function(error) {
            //console.log(error);
            //console.log(error.tostring());
          });      
 
    }

    fetchLatestData() {
        
      if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
        let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
        this.setState({
          latest_data: video_slides
        });
      }
    }

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.data  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                        <br />
                        <h2 className="color-green">{this.state.data.title}</h2><br />
                        <small>{moment(this.state.data.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}</small><br/>
                        <br />
                        
                        { renderHTML(this.state.data.body) }
                        <br />
                        <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.data.featured_img} alt=""  width="100%" alt={this.state.data.news_title}/>
                        <br />
                        {
                          (this.state.advertise) ? <div className="row">
                          <div className="grid">
                            <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                              <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                              </a><br/><br/>
                          </div>
                        </div>: ""
                        }
                        
                        
                        
                    </div> 
                    
                    <div className="row text-right">
                         {/* <div className="fb-share-button" 
                          data-href={'https://shwe-sports.com/news/'+this.state.post_id}
                          data-layout="button_count">
                        </div> */}
                        {/* <div className="fb-share-button" 
                          data-href={window.BPdata.baseUrl+'news/'+this.state.post_id}
                          data-layout="button_count">
                        </div> */}
                        {/* <a href={'https://www.facebook.com/sharer/sharer.php?u='+window.BPdata.baseUrl+'news/'+this.state.post_id+'/'+this.state.data.title} className="fb-xfbml-parse-ignore">
                          <FacebookIcon size={32} round={true} url={window.BPdata.baseUrl+'news/'+this.state.post_id+'/'+this.state.data.title}/>
                        </a> */}
                        {/* <TwitterIcon size={32} round={true} url={window.BPdata.baseUrl+'highlights/'+this.state.post_id+'/'+this.state.data.title}/>
                        <WhatsappIcon size={32} round={true} url={window.BPdata.baseUrl+'highlights/'+this.state.post_id+'/'+this.state.data.title}/>
                        <ViberIcon size={32} round={true} url={window.BPdata.baseUrl+'highlights/'+this.state.post_id+'/'+this.state.data.title}/> */}
                        {/* <FacebookProvider appId="1967980890125208">
                            <Comments href={window.BPdata.baseUrl+'news/'+this.state.post_id+'/'+this.state.data.title}   width="100%"/>
                        </FacebookProvider> */}
                         {/* <div class="fb-comments" data-href={window.BPdata.baseUrl+'news/'+this.state.post_id+'/'+this.state.data.title} data-numposts="5"  width="100%"></div> */}
                    </div>
                   
                   
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }

    latestNews() {
        let obj
        let rowID
        let data = this.state.latest_data  
        let main = []

        if(data.length  > 0){
            
            // Outer loop to create parent
            for (let i = 0; i < data.length; i++) {
              obj = data[i];
              rowID = i;
              //let children = []
                //Inner loop to create children
                //children.push(<td key='0'>{`${i+1}`}</td>)
                main.push(<a href={"/news/"+obj.id_news} key={rowID}>
                <div  style={{ padding: '0 5px' }}>
                  <div className="list-item  list-box-shadow">
                      <div className="grids text-left" style={{ paddingTop: '10px' }}>
                        <img src={window.BPdata.apiAssetUrl+"/images/thumbnail_"+obj.featured_img} alt=""  className="list-featured-img"  alt={obj.news_title}/>
                        <br/><i style={{fontSize: "13px" }}>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</i>
                      </div>
                      <div className="grid-80">
                        <div className="row" style={{ lineHeight: 1 }}>
                            <div className="list-featured-p">
                              <b className="color-green">{obj.title}</b><br/>
                              <span>{`${obj.body.replace(/(<([^>]+)>)/ig,"").substring(0, MAX_LENGTH)}...`}</span>
                            </div>
                            <div className="row list-footer">
                              <div className="text-left grid-90 left">
                                {/* {moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY')} */}
                              </div>
                              <div className="text-right grid-10 right">
                                
                                {/* <FacebookProvider appId="1967980890125208">
                                    <i>
                                      <CommentsCount href={window.BPdata.baseUrl+"/news/"+obj.id_news} />
                                      <img src="/img/comment.jpg" alt="comment" /> 
                                    </i> 
                                </FacebookProvider> */}
                              </div>
                              
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </a>)
                //main.push(<div style={{backgroundColor: "rgb(245, 245, 249)", height: "8px", borderTop: "1px solid rgb(236, 236, 237)", borderBottom: "1px solid rgb(236, 236, 237)"}} key={i+200}></div>)
                
                //Create the parent and add the children
                //main.push({children})
            }
            //main.push(<div key={0} width="100%">{tempArray}</div>)
            return main
        }
    }

    handleLikeClick() {
        var self = this;
        let formdata = {"phone":localStorage.getItem(window.BPdata.time),"key_id":this.state.post_id};
        let dataurl = window.BPdata.apiUrl+'/user/news/like';
        let liked = this.state.data.liked;
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            //console.log(Number(self.state.data.liked)+1)
            if(response.data.success) {
                liked = Number(self.state.data.liked)+1;
                let data1 = self.state.data;
                self.setState({liked : 1});
                // liked = Number(self.state.data.liked)+1;
                let data2 =  { liked : liked };
                
                let merged = {...data1, ...data2}

                self.setState({
                        data : merged
                });
            }

        })
        .catch(function (error) {
            //console.log(error);
        });

        
        //console.log(self.state.data)
    }
    
    render() {
        const { selected } = this.state;
        let leagueMenu = this.state.leagueMenu;
        // Create menu from items
        const menu = Menu(this.state.menu, selected, leagueMenu);
        // const loadingSpin = this.state.loading ? "App-logo Spin" : "App-logo";

        return (
            <div className="page">
                <div className="page-content">

                      
                    
                    {/*  <ScrollMenu
                        data={menu}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                        selected={selected}
                        onSelect={this.onSelect}
                        alignCenter={false}
                        wheel={true}
                        inertiaScrolling={true}
                        // scrollBy="10"
                        /> */}
                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                          

                        {/* 
                        
                        <div className="main-content" key="1">
                          <div className="row">
                            <div className="am-list-header" key="0"><div className="list-header bg-white"><img src="/img/newsicon.png" className="list-icon-circle" alt={window.lang.title.latest_news}  /><span>{window.lang.title.latest_news} </span></div></div>
                            {this.latestNews()}
                          </div>
                        </div>

                        */}


                        {/* <h2>{window.lang.title.latest_news}</h2> */}

                        
                        {/* <FacebookProvider appId="1750258531872776">
                            <Comments href="http://localhost" />
                            <CommentsCount href="http://localhost" /> Comments
                        </FacebookProvider> */}
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                        
                </div>
            </div>
            );
        }
      }                                                                                         
      
export default ListDetailPage;
 