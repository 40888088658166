import React, { Component } from 'react';
import axios from 'axios';
import {
  Link
} from 'react-router-dom';
import renderHTML from 'react-render-html';


import { Player } from 'video-react';
import ReactHLS from 'react-hls-player';
import HLSSource from "./../../component/HLSSource";
import 'video-react/dist/video-react.css';
// import 'react-hls/src/style.css'; // need to import basic styles
// import 'react-hls/src/icons.css'; // need to import basic icons
let useragentcheck = (navigator.userAgent.toLowerCase().indexOf("mac") > -1 );


class Channel1AppDetailPage extends Component {
  state = {
    video: {
        //src: "https://live.BPdata.com/live.m3u8",
        // livesrc: "https://api.new.livestream.com/accounts/22774234/events/6897936/live.m3u8",
        //src: "http://ott.artmott.com/ArenaSport1MW/tracks-v1a1/mono.m3u8",
        name: "Channel 1",
        description : "",
        src: "https://stream.BPdata.com:9200/hls/BPdata.m3u8",
        // src: "http://driptvs.com:8080/live/P40q8guhw1/J6l132ERRq/46658.m3u8",
        poster: "http://www.example.com/path/to/video_poster.jpg",
        advertise: {},
    },
    isloading : true
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
            if(menu[menu.length - 2] === url ){
                //console.log("first");
                menu.pop();
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
            }
            window.BPdata.preurl =  menu[menu.length-2];
        }
    }
  }

  getfetchApi() {
    var self = this;
    let dataurl = window.BPdata.apiUrl+'/user/channels/info?cid=6';
    let data = this.state.data;
    axios({
        method: 'get',
        url: dataurl,
        data: data,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(function (response) {
        
        if(response.data.data) {

            self.setState({  
              video     : response.data.data[0], 
              advertise : response.data.advertise,
              isloading : false
            });
            //self.setState({  message : "Successfully updated" });
            //console.log(response.data.data)
        } else {
            self.setState({  message : "Something was wrong" });
        }
        //console.log(response.data.success);
    })
    .catch(function (error) {
        //console.log(error);
    });
}

  componentDidMount() {
    
    
    this.hisUrl('/channel1');
    this.getfetchApi();
}

 render() {
 
   return (
    <div className="page">
        <div className="page-content">
          
          <div className="main-content">
              <div className="row grid p-5">
                <div className="row text-left">
                        <br />
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.more.live_channel1} /><span>{window.lang.more.live_channel1}</span>
                        </div>
                        <br/>
                        <br/>
                </div>
            </div>
          </div>
                {/* {this.state.video.src} */}
                
                { (this.state.isloading) ? (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> )  :  
                 ((useragentcheck) ? 
                 <div className="row text-center">
                    <Player>
                      <HLSSource
                        isVideoChild
                        src={this.state.video.src} 
                      />
                    </Player>
                  </div> 
                :  <ReactHLS url={this.state.video.src}  autoplay="true"/> ) } 
                <br />
                <div className="main-content">
                  <div className="list-header">
                      <img src="/img/badge.jpg" className="list-img-circle" alt={this.state.video.name} /><span>{this.state.video.name}</span>
                  </div>
                  <br/>
                  {
                    (this.state.advertise) ? <div className="row">
                      <div className="grid">
                        <a href={this.state.advertise.advertise_goto} target="_blank"  rel="noopener noreferrer">
                          <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                          </a><br/>
                      </div>
                    </div>: ""
                  }

                  <div className="league-price-table row">
                    {renderHTML(this.state.video.description)}
                  </div>
                </div>
                <div className="page-content text-left setting-menu">
                    <ul>
                      <Link  to={"/forum"}><li>ဖိုရမ်ကို လည်ပတ်ပြီးပြီလား။ <span>&gt;</span></li></Link>
                    </ul>
                </div>
                {/* https://BPdata.com/forum */}

                {/* { (this.state.isloading) ? (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> )  : <ReactHLS url={this.state.video.src}  autoplay="true" /> } */}
                
                {/* { (useragentcheck) ? <ReactHLS url={this.state.video1.livesrc} /> :  <ReactHLS url={this.state.video1.src} /> } */}
           
                        
                   
                    {/* <div class="ant-back-top"><div class="ant-back-top-content">Back<div class="ant-back-top-icon"></div></div></div> */}
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
            
        </div>
      </div>
   );
 }
}
 
export default Channel1AppDetailPage;